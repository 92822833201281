import React, { useState } from "react";
import { ContactForm } from "./ContactForm";
import * as remoteClient from "../../../../dataServices/remoteClient";
import { toast } from "react-toastify";

export const ContactComponent = () => {
  const [contactDto, setContactDto] = useState({});
  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);

  function handleChange(event) {
    const { name, value } = event.target;
    setContactDto((prevsignup) => ({
      ...prevsignup,
      [name]: value,
    }));
  }

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function formIsValid() {
    const { email, message } = contactDto;
    const errors = {};

    if (!isValidEmail(email)) errors.email = "Email is required";
    if (!message) errors.message = "Message is empty";

    setErrors(errors);
    return Object.keys(errors).length === 0;
  }

  function resetForm() {
    setContactDto({
      email: "",
      message: "",
      firstName: "",
      lastName: "",
      company: "",
      position: "",
      // Reset other fields as needed
    });
  }

  function handleSave(event) {
    event.preventDefault();
    if (!formIsValid()) return;
    setSaving(true);
    remoteClient
      .post(`/api/sitecontacts`, contactDto)
      .then((data) => {
        setSaving(false);
        resetForm();
        toast.success(
          `Thanks for your message ${data.firstName}.  We will respond within 1 business day.`
        );
      })
      .catch((error) => {
        toast.error("Failed to send message.  Please try again");
        setSaving(false);
      });
  }

  return (
    <section class="mb-5">
      <div class="rounded-3 bg-faded-success py-5 px-sm-5 px-4">
        <div class="mx-auto py-md-4 text-center">
          <h2>Get in touch</h2>
          <p class="mb-4 pb-2 fs-lg">
            We <i class="fi-heart"></i> hearing from companies and candidates
            whether positive or negative. We will respond in a timely manner to
            assist you and will consider how any feedback can improve our
            services.
          </p>
          <ContactForm
            dto={contactDto}
            onChange={handleChange}
            onSave={handleSave}
            saving={saving}
            errors={errors}
          />{" "}
          <div class="form-text mt-5">
            * By communicating through the site you are agreeing to our{" "}
            <a href="/privacy" class="text-decoration-none">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
