import React, { useState } from "react";
import { PrivacyTab } from "./PrivacyTab";
import { CookiesTab } from "./CookiesTab";
import { RetentionTab } from "./RetentionTab";
import { ContactComponent } from "../contact/ContactComponent";

export const PrivacyPage = () => {
  const [showBody, setShowBody] = useState("privacy");

  return (
    <>
      <section className="py-5">
        <div className="container pt-5 pb-lg-5">
          <div className="row gy-4">
            <div className="col-lg-4 col-md-5 mb-md-0 mb-2">
              <div className="card border-0 bg-faded-danger p-md-3 p-1">
                <div className="card-body">
                  <h3 className="mb-4">TL;DR</h3>
                  <div className="mb-md-5 mb-4 fs-sm">
                    <p>
                      This site and service has been developed with privacy at
                      the front of our mind.
                    </p>
                    <p>
                      Our goal is to provide job seekers with a private space to
                      identify highly relevant career opportunities.
                    </p>
                    <p>In simple terms our privacy policy is;</p>
                    <ul>
                      <li>
                        Privacy by default. Your profile will be private unless
                        you choose to change your privacy settings.
                      </li>
                      <li>We will NOT sell your data.</li>
                      <li>We will use up to date security measures.</li>
                      <li>
                        We will store your data securely using leading cloud
                        services (currently Microsoft Azure).
                      </li>
                      <li>We will delete your data if requested.</li>
                    </ul>
                    <p>
                      If you have any queries, contact our Data Protection
                      Officer, Peter Hutchinson, peter@sourcers.co.
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-md-block d-sm-none  mt-2">
                <ContactComponent />
              </div>
            </div>
            <div className="col-md-7 offset-lg-1">
              <h2 className="h4 mb-4 pb-md-2">Privacy Policy</h2>
              <div className="mb-md-4 mb-3 fs-sm">
                <p>
                  Your privacy is important to us. This Privacy Policy is where
                  we explain to you what personal information is collected when
                  you visit our site, how it is collected, how we may use that
                  information, where we store it, how long we store it for, and
                  how we protect it. We will also explain your rights in
                  relation to your personal information.
                </p>
                <p>
                  Please read the following carefully to understand our views
                  and practices regarding your personal information and how we
                  may collect it and treat it.
                </p>
                <p>
                  For convenience, we have divided our data protection policies
                  into three separate pages:
                </p>
                <ol>
                  <li>
                    <p>Privacy Policy</p>
                    <p>
                      This policy explains generally how we collect, use, store,
                      and protect your personal information when you visit our
                      site.
                    </p>
                  </li>
                  <li>
                    <p>Cookie Policy</p>
                    <p>
                      Our Cookie Policy is available [HERE] and explains more
                      about how we may collect personal information about you
                      via cookies (it also explains what cookies are).
                    </p>
                  </li>
                  <li>
                    <p>Retention Policy</p>
                    <p>
                      You can find out how long we may hold onto your personal
                      information here
                    </p>
                  </li>
                </ol>
                <p>
                  If you have any questions please do not hesitate to contact us
                  at info@clydecoastcontracts.co.uk or, if you prefer to call or
                  write to us, then you can find our contact details at the
                  bottom of this page.
                </p>
              </div>
              <div className="mb-md-4 mb-3 fs-sm">
                <h6 className="mb-md-3 mb-2 fs-base">Who we are</h6>
                <p>
                  We are sourcers.co trading as BuSoCa Limited. We are
                  registered in Scotland as a limited liability company. Our
                  registered number is SC523984 and our registered office is at
                  1, 8 Johnstone Drive, Glasgow, G72 2PS.
                </p>
              </div>
              <hr className="mb-3" />

              <ul className="nav nav-pills flex-column flex-md-row pt-3 pt-md-0 pb-md-4 border-bottom-md">
                {showBody === "extended" ? (
                  <li className="nav-item mb-md-0 me-md-2 pe-md-1">
                    <button className="nav-link active">
                      <i className="fi-lock mt-n1 me-2 fs-base"></i>Privacy
                    </button>
                  </li>
                ) : (
                  <li className="nav-item mb-md-0 me-md-2 pe-md-1">
                    <button
                      className="nav-link"
                      onClick={() => setShowBody("privacy")}
                    >
                      <i className="fi-lock mt-n1 me-2 fs-base"></i>Privacy
                    </button>
                  </li>
                )}
                {showBody === "experience" ? (
                  <li className="nav-item mb-md-0 me-md-2 pe-md-1">
                    <button className="nav-link active">
                      <i className="fi-file mt-n1 me-2 fs-base"></i>Cookies
                    </button>
                  </li>
                ) : (
                  <li className="nav-item mb-md-0 me-md-2 pe-md-1">
                    <button
                      className="nav-link"
                      onClick={() => setShowBody("cookies")}
                    >
                      <i className="fi-file mt-n1 me-2 fs-base"></i>Cookies
                    </button>
                  </li>
                )}

                {showBody === "education" ? (
                  <li className="nav-item mb-md-0 me-md-2 pe-md-1">
                    <button className="nav-link active">
                      <i className="fi-education mt-n1 me-2 fs-base"></i>
                      Retention
                    </button>
                  </li>
                ) : (
                  <li className="nav-item mb-md-0 me-md-2 pe-md-1">
                    <button
                      className="nav-link"
                      onClick={() => setShowBody("retention")}
                    >
                      <i className="fi-education mt-n1 me-2 fs-base"></i>
                      Retention
                    </button>
                  </li>
                )}
              </ul>

              {showBody === "privacy" && <PrivacyTab />}

              {showBody === "cookies" && <CookiesTab />}

              {showBody === "retention" && <RetentionTab />}
            </div>

            <div className="d-block d-md-none  mt-2">
              <ContactComponent />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPage;
