import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  selectPostIds,
  selectPostById,
  fetchPosts,
} from "../../../../features/posts/postsSlice";

import { Spinner } from "../../../common/Spinner";
import { TimeAgo } from "../../../common/TimeAgo";
import { PostCard } from "../../../common/PostCard";

export const RecruiterPostsList = () => {
  const dispatch = useDispatch();
  const orderedPostIds = useSelector(selectPostIds);

  const postStatus = useSelector((state) => state.posts.status);
  const error = useSelector((state) => state.posts.error);

  useEffect(() => {
    if (postStatus === "idle") {
      dispatch(fetchPosts());
    }
  }, [postStatus, dispatch]);

  // let content;

  // if (postStatus === "loading") {
  //   content = <Spinner text="Loading posts..." />;
  // } else if (postStatus === "succeeded") {
  //   content = orderedPostIds.map((postId) => (
  //     <PostCard key={postId} postId={postId} />
  //   ));
  // } else if (postStatus === "failed") {
  //   content = <div>{error}</div>;
  // }

  return (
    <>
      {postStatus === "loading" && <Spinner text="Loading posts..." />}
      {postStatus === "succeeded" ? (
        <section className="posts-list">
          {orderedPostIds.map((postId) => (
            <PostCard key={postId} postId={postId} />
          ))}
        </section>
      ) : (
        <div>{error}</div>
      )}
    </>
  );
};
