import { StaticTagsPage } from "../recruiter/OnboardStaticTagsPage";

export const UpdateRecruiterContractsPageWrapper = (props) => {
  return (
    <StaticTagsPage
      tagType={"Type"}
      profileType={"recruiter"}
      tagColor={"dark"}
      text={"What contract types do you hire for?"}
      {...props}
      update={true}
    />
  );
};
