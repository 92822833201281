import React from "react";
import { useHistory, Link } from "react-router-dom";

export const PrevNextBtnLgRound = ({
  prevTitle,
  prevHref,
  nextTitle,
  nextHref,
  saving,
  saveTags,
  update,
}) => {
  let history = useHistory();

  return (
    <div className="d-flex flex-column flex-sm-row bg-light rounded-3 mt-5">
      {!update && (
        <>
          {!prevHref ? (
            <button
              className="btn btn-outline-primary btn-lg rounded-pill mb-3 mb-sm-0"
              onClick={history.goBack}
            >
              <i className="fi-chevron-left fs-sm mx-2"></i>
              {prevTitle}
            </button>
          ) : (
            <Link
              className="btn btn-outline-primary btn-lg rounded-pill mb-3 mb-sm-0"
              to={prevHref}
            >
              <i className="fi-chevron-left fs-sm mx-2"></i>
              {prevTitle}
            </Link>
          )}
        </>
      )}

      {!update ? (
        <button
          type="submit"
          disabled={saving}
          className="btn btn-success btn-lg rounded-pill ms-sm-auto"
          onClick={() => saveTags()}
        >
          {saving ? "Saving..." : nextTitle}
          <i className="fi-chevron-right mx-2"></i>
        </button>
      ) : (
        <button
          type="submit"
          disabled={saving}
          className="btn btn-success btn-lg rounded-pill ms-sm-auto w-100"
          onClick={() => saveTags()}
        >
          {saving ? "Saving..." : nextTitle}
        </button>
      )}
    </div>
  );
};
