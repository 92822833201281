import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconCard } from "../../../common/IconCard";

export const ProfilingSystem = () => {
  return (
    <>
      <div className="container pb-5 pt-5 mb-md-5">
        <div className="card border-0 bg-faded-info p-1">
          <div className="card-body">
            <h2 className="mb-4 pb-md-2 text-center text-primary">
              Skills based career and networking opportunities
            </h2>{" "}
            <p className="mb-4 pb-2 mx-auto text-center text-primary">
              Our proprietory profiling system uses a combination of AI and
              human consulancy to match you with relevant opportunities. You can
              control your personalised feed of jobs, recruiters, hiring
              managers and organisations by aligning your skills profile with
              your experience and aspirations.
            </p>
            <div className="row row-cols-lg-4 mt-4 row-cols-md-3 row-cols-sm-1 row-cols-1 g-4 justify-content-center">
              <IconCard
                icon="fi-briefcase"
                iconClass="bg-faded-accent"
                textClass="text-accent"
                title="Positions"
                description="Select your expereince level based on the positions that are of interest to you."
              />

              <IconCard
                icon="fi-building"
                iconClass="bg-faded-accent"
                textClass="text-accent"
                title="Firm Types"
                description="Are you interested in consulting or industry roles? Do you want to work at a start up or scale up?
                Perhaps you are only interested in listed companies."
              />

              <IconCard
                icon="fi-award"
                iconClass="bg-faded-success"
                textClass="text-success"
                title="Qualifications"
                description="Do the roles that interest you require certain qualifications?  Personalise your feed accordingly."
              />

              <IconCard
                icon="fi-settings"
                iconClass="bg-faded-success"
                textClass="text-success"
                title="Skills"
                description="Create a highly personalised feed based on your skills.  You can fine tune results with 
                'all of' and 'any of' across thousands of skills in our taxonomy."
              />

              <IconCard
                icon="fi-globe"
                iconClass="bg-faded-warning"
                textClass="text-warning"
                title="Locations"
                description="Your feed is set to only return opportunities in particular cities and countries.  You can also set remote / hybrid preferences
                 based on particular countries or regions."
              />

              <IconCard
                icon="fi-grid"
                iconClass="bg-faded-danger"
                textClass="text-danger"
                title="Divisions"
                description="There is a big difference between specialising in java in the talent division and the engineering division.  Ensure
                your feed is set up to only return relevant results by providing divisions of interest."
              />

              <IconCard
                icon="fi-education"
                iconClass="bg-faded-danger"
                textClass="text-danger"
                title="Education"
                description="Are the roles you seek likely to require certain levels of education?  We aim to ensure you only receive roles you 
                are qualified to fulfil."
              />

              <IconCard
                icon="fi-cash"
                iconClass="bg-faded-danger"
                textClass="text-danger"
                title="Compensation"
                description="Your feed can be personalised to meet your salary expectations.  We encourage early discussion about compensation to ensure 
                the time you dedicate to hiring processes is worthwhile."
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
