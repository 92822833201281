import { OnboardStaticTagsPage } from "../OnboardStaticTagsPage";

export const DivisionTagsPageWrapper = (props) => {
  // Divsion is called sector in the backend
  // Was changed in past on FE for user experience
  return (
    <OnboardStaticTagsPage
      tagType={"Sector"}
      tagColor={"success"}
      lead={"Select the teams you have experience in."}
      {...props}
    />
  );
};
