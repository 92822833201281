import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { FacetHeader } from "../FacetHeader";

import { ClearFilters } from "../../common/search/ClearFiltersComponents";

export const Facets = ({
  facets,
  selectedCheckboxes,
  handleSelectedCheckboxes,
  handleUpdateIndustries,
  handleUpdateSectors,
  handleUpdateLocations,
  handleUpdateFirmTypes,
  handleClearFilters,
  isMobile,
}) => {
  const dispatch = useDispatch();
  // Function to check if a checkbox should be checked

  const [showFilter, setShowFilter] = useState(false);

  const isCheckboxChecked = (facet) => {
    if (selectedCheckboxes) {
      const { value, filterType } = facet;
      const selectedCheckbox = `${filterType}_${value}`;
      // alert("from candidate feed - selectedCheckbox:- ", selectedCheckbox);

      return selectedCheckboxes.includes(selectedCheckbox);
    }
  };

  const toggleFacet = ({ facet, facets }) => {
    const facetType = facets.facetType.toLowerCase();

    dispatch(handleSelectedCheckboxes(facet));

    if (facetType === "industries") {
      dispatch(handleUpdateIndustries(facet.value));
    }
    if (facetType === "sectors") {
      dispatch(handleUpdateSectors(facet.value));
    }
    if (facetType === "cities") {
      dispatch(handleUpdateLocations(facet.value));
    }
    if (facetType === "firmtypes") {
      dispatch(handleUpdateFirmTypes(facet.value));
    }
  };

  return (
    <>
      {!isMobile ? (
        <>
          <ClearFilters
            resetFilters={() => {
              dispatch(handleClearFilters());
            }}
          />

          {facets &&
            facets.map((facets, index) => (
              <div className="pb-4 mb-2" key={index}>
                <FacetHeader facetType={facets.facetType} />
                <div
                  className="overflow-auto"
                  data-simplebar
                  data-simplebar-auto-hide="false"
                  style={{ height: "11rem" }}
                >
                  {facets.facetResults &&
                    facets.facetResults.map((facet) => (
                      <div className="form-check" key={facet.value}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={facet.value}
                          checked={isCheckboxChecked(facet)}
                          onChange={() => toggleFacet({ facet, facets })}
                        />
                        <label
                          className="form-check-label fs-sm"
                          htmlFor={facet.value}
                        >
                          {facet.value} ({facet.count})
                        </label>
                      </div>
                    ))}
                </div>
              </div>
            ))}
        </>
      ) : (
        <div className="d-sm-block d-md-none col-md-3">
          <div className="d-flex flex-sm-row flex-column align-items-sm-center align-items-stretch mb-3">
            <div className="d-none d-sm-block d-flex align-items-center flex-shrink-0">
              <label className="fs-sm me-2 pe-1 text-nowrap" htmlFor="sortby">
                <i className="fi-filter"></i>
              </label>
            </div>
            <hr className="d-none d-sm-block w-100 mx-2" />
            <div className="d-sm-flex align-items-center flex-shrink-0 text-muted">
              <button
                className="btn btn-sm btn-outline-primary mr-1 w-100"
                onClick={() => setShowFilter(!showFilter)}
              >
                Filters
              </button>{" "}
            </div>
          </div>
          {showFilter && (
            <>
              {facets &&
                facets.map((facets, index) => (
                  <div className="pb-4 mb-2" key={index}>
                    <FacetHeader facetType={facets.facetType} />
                    <div
                      className="overflow-auto"
                      data-simplebar
                      data-simplebar-auto-hide="false"
                      style={{ height: "11rem" }}
                    >
                      {facets.facetResults &&
                        facets.facetResults.map((facet) => (
                          <div className="form-check" key={facet.value}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={facet.value}
                              checked={isCheckboxChecked(facet)}
                              onChange={() => toggleFacet({ facet, facets })}
                            />
                            <label
                              className="form-check-label fs-sm"
                              htmlFor={facet.value}
                            >
                              {facet.value} ({facet.count})
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
              <ClearFilters
                resetFilters={() => {
                  dispatch(handleClearFilters());
                }}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};
