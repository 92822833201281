import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  addNewRecruiter,
  updateRecruiter,
  fetchRecruiter,
} from "../../../../../features/recruiter/recruiterSlice";

import { toast } from "react-toastify";
import { RecruiterBasicInfoForm } from "./RecruiterBasicInfoForm";

import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";

import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";
import { useAuth } from "../../../../../contexts/AuthContext";

import { CenteredSingleColumnLayout } from "../../../../common/CenteredSingleColumnLayout";
import { InfoAlert } from "../../../../common/InfoAlert";

export const RecruiterBasicInfoPage = ({ update, ...props }) => {
  const history = useHistory();
  const recruiterFromStore = useSelector((state) => state.recruiter || {});
  const [recruiter, setRecruiter] = useState(
    recruiterFromStore.recruiter || {}
  );

  const { firstName, lastName } = useAuth();

  const [editorState, setEditorState] = useState(
    EditorState.createEmpty() // or provide an initial content state if needed
  );
  const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);

  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const [initialFetchCompleted, setInitialFetchCompleted] = useState(false);

  const dispatch = useDispatch();

  const maxCharacters = 750;

  const [currentTextLength, setCurrentTextLength] = useState(0);

  useEffect(() => {
    setRecruiter(recruiterFromStore.recruiter);

    setRecruiter((prevRecruiter) => ({
      ...prevRecruiter,
      firstName: firstName,
      lastName: lastName,
    }));
  }, [recruiterFromStore.recruiter]);

  // Set the editor with HTML from user pitch field

  useEffect(() => {
    if (recruiter && recruiter.pitch) {
      if (!initialFetchCompleted) {
        const contentState = ContentState.createFromBlockArray(
          convertFromHTML(recruiter.pitch)
        );
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
        setInitialFetchCompleted(true);
        setCurrentTextLength(contentState.getPlainText("").length);
      }
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [recruiter, initialFetchCompleted]);

  useEffect(() => {
    if (recruiterFromStore === null) {
      dispatch(fetchRecruiter());
    }
  }, [dispatch]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setRecruiter((prevRecruiter) => ({
      ...prevRecruiter,
      [name]: value,
    }));
  };

  const [prevContentState, setPrevContentState] = useState(
    editorState.getCurrentContent()
  );

  const handlePitchChange = (newEditorState) => {
    const currentContent = newEditorState.getCurrentContent();
    const currentText = currentContent.getPlainText("");

    if (currentText.length <= maxCharacters) {
      setPrevContentState(currentContent); // Save the current content state
      setEditorState(newEditorState);
      setCurrentTextLength(currentText.length);
      convertContentToHTML();
    } else {
      // If the text exceeds the character limit, restore the previous content state
      setEditorState(EditorState.createWithContent(prevContentState));
    }
  };

  const convertContentToHTML = () => {
    const extractedHTML = extractHTML();
    setRecruiter((prevRecruiter) => ({
      ...prevRecruiter,
      pitch: extractedHTML,
    }));
  };

  // Get HTML from editor for saving to db
  const extractHTML = () => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlToSanitize = draftToHtml(rawContentState);
    return sanitize(htmlToSanitize);
  };

  // Sanitize any bad input from editor
  const sanitize = (html) => {
    return DOMPurify.sanitize(html);
  };

  const canSave =
    [
      recruiter.firstName,
      recruiter.lastName,
      recruiter.title,
      recruiter.employer,
    ].every(Boolean) && addRequestStatus === "idle";

  const formIsValid = () => {
    const { firstName, lastName, title, employer } = recruiter;
    const errors = {};

    if (!firstName) errors.firstName = "First name is required";
    if (!lastName) errors.lastName = "Last name is required";
    if (!title) errors.title = "Job title is required";
    if (!employer) errors.company = "Company is required";

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSave = async (event) => {
    event.preventDefault();
    if (!formIsValid()) return;

    if (canSave) {
      try {
        setSaving(true);
        setAddRequestStatus("pending");

        recruiter.id
          ? await dispatch(updateRecruiter(recruiter))
              .unwrap()
              .then(() => {
                toast.success("Recruiter Updated");
                history.push(
                  update
                    ? "/recruiter/dashboard"
                    : "/onboard/recruiter/positions"
                );
              })
          : await dispatch(addNewRecruiter({ recruiter }))
              .unwrap()
              .then(() => {
                toast.success("Recruiter Created");
                history.push(`/onboard/recruiter/positions`);
              });
      } catch (error) {
        console.error("Failed to save recruiter");
        setErrors({ onSave: error.message });
        setSaving(false);
        toast.error("Saving Recruiter failed. ", JSON.stringify(error.message));
      } finally {
        setAddRequestStatus("idle");
        setSaving(false);
      }
    }
  };

  return (
    <>
      <CenteredSingleColumnLayout>
        <InfoAlert
          lead="Your profile will be visible to candidates who have the skills you select on the subsequent stages of onboarding."
          text={
            <>
              The details provided below will be visible to candidates with a
              relevant skills profile and those who have received posts that you
              create (i.e. they have a relevant skills profile for your post).
              Add any information about yourself or the organisation you
              represent that you feel will encourage candidates to engage with
              you.
            </>
          }
        />
        {recruiter && (
          <RecruiterBasicInfoForm
            recruiter={recruiter}
            onChange={handleChange}
            onSave={handleSave}
            errors={errors}
            saving={saving}
            editorState={editorState}
            onPitchChange={handlePitchChange}
            maxCharacters={maxCharacters}
            currentTextLength={currentTextLength}
            update={update}
          />
        )}
      </CenteredSingleColumnLayout>
    </>
  );
};
