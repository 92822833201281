import {
  createEntityAdapter,
  createSlice,
  nanoid,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";

import { client } from "../../api/client";

import * as remoteClient from "../../dataServices/remoteClient";

const postsAdapter = createEntityAdapter({
  //sortComparer: (a, b) => b.date.localeCompare(a.date),
});

const initialState = postsAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchPosts = createAsyncThunk("posts/fetchPosts", async () => {
  return await remoteClient.get(`api/posts`);
});

export const addNewPost = createAsyncThunk(
  "posts/addNewPost",
  async (initialPost) => {
    //We send initial data to the fake API server
    return await remoteClient.post("/api/posts", initialPost);
  }
);

export const updatePost = createAsyncThunk(
  "posts/updatePost",
  async ({ postToUpdateId, title, pitch }) => {
    return await remoteClient.put(`/api/posts/${postToUpdateId}`, {
      title,
      pitch,
    });
  }
);

export const savePostProfileTags = createAsyncThunk(
  "posts/savePostProfileTags",
  async ({ post, tagType }) => {
    return await remoteClient.post(
      `/api/posts/${post.id}/profiles/${post.profile.id}/tagsCollection/${tagType}`,
      post.profile.tags.filter((t) => t.type === tagType)
    );
  }
);

export const saveProfileMultiTags = createAsyncThunk(
  "posts/saveProfileMultiTags",
  async ({ post, tagType, selectedOptions }) => {
    return await remoteClient.post(
      `/api/posts/${post.id}/profiles/${post.profile.id}/skillsCollection/${tagType}`,
      selectedOptions
    );
  }
);

export const saveProfileCountries = createAsyncThunk(
  "posts/saveProfileCountries",
  async ({ post, selectedOptions }) => {
    return await remoteClient.post(
      `/api/posts/${post.id}/profiles/${post.profile.id}/countriescollection`,
      selectedOptions.anyTags
    );
  }
);

export const saveProfileCities = createAsyncThunk(
  "posts/saveProfileCities",
  async ({ post, selectedOptions }) => {
    return await remoteClient.post(
      `/api/posts/${post.id}/profiles/${post.profile.id}/citiescollection`,
      selectedOptions.anyTags
    );
  }
);

export const updateProfileSalary = createAsyncThunk(
  "posts/updateProfileSalary",
  async ({ post, profileSalaryDto }) => {
    return await remoteClient.post(
      `/api/posts/${post.id}/profiles/${post.profile.id}/`,
      profileSalaryDto
    );
  }
);

export const sendRecruiterPostMessage = createAsyncThunk(
  "posts/sendRecruiterPostMessage",
  async ({ postId, postResponseId, dto }) => {
    return await remoteClient.post(
      `api/posts/${postId}/postresponses/${postResponseId}/messages`,
      dto
    );
  }
);

const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    postAdded: {
      reducer(state, action) {
        state.posts.push(action.payload);
      },
      prepare(title, content, userId) {
        return {
          payload: {
            id: nanoid(),
            date: new Date().toISOString(),
            title,
            content,
            user: userId,
            reactions: {
              thumbsUp: 0,
              hooray: 0,
              heart: 0,
              rocket: 0,
              eyes: 0,
            },
          },
        };
      },
    },

    postUpdated(state, action) {
      const { id, title, content } = action.payload;
      const existingPost = state.entities[id];
      if (existingPost) {
        existingPost.title = title;
        existingPost.content = content;
      }
    },

    reactionAdded(state, action) {
      const { postId, reaction } = action.payload;
      const existingPost = state.entities[postId];
      if (existingPost) {
        existingPost.reactions[reaction]++;
      }
    },

    postTagAdded(state, action) {
      const { postId, tag } = action.payload;
      const existingPost = state.entities[postId];
      if (existingPost && existingPost.profile && existingPost.profile.tags) {
        existingPost.profile.tags.push(tag);
      }
    },

    postTagRemoved(state, action) {
      const { postId, tag } = action.payload;
      const existingPost = state.entities[postId];
      if (existingPost && existingPost.profile && existingPost.profile.tags) {
        existingPost.profile.tags = existingPost.profile.tags.filter(
          (t) => t.id !== tag.id
        );
      }
    },
    setSelectedResponseForPost: (state, action) => {
      const { postId, response } = action.payload;
      const existingPost = state.entities[postId];
      if (existingPost) {
        existingPost.selectedResponse = response;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.status = "succeeded";
        //Add any fetched posts to the array
        // Use the upserMany reducer as a mutating update utility
        postsAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewPost.fulfilled, postsAdapter.addOne)
      .addCase(updatePost.pending, (state) => {
        state.status = "updating";
      })
      .addCase(updatePost.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Call the `updateOne` function to update the state based on the action payload
        postsAdapter.updateOne(state, action.payload);
      })
      .addCase(updatePost.rejected, (state, action) => {
        state.status = "updateFailed";
        state.error = action.error.message;
      })
      .addCase(savePostProfileTags.fulfilled, (state, action) => {
        state.isLoading = false;
        postsAdapter.updateOne(state, action.payload);
        state.profileTouched = true;
      })
      .addCase(savePostProfileTags.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(saveProfileMultiTags.fulfilled, (state, action) => {
        state.isLoading = false;
        postsAdapter.updateOne(state, action.payload);
        state.profileTouched = true;
      })
      .addCase(saveProfileMultiTags.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(saveProfileCountries.fulfilled, (state, action) => {
        //alert(JSON.stringify(action.payload));
        state.isLoading = false;
        postsAdapter.updateOne(state, action.payload);
        state.profileTouched = true;
      })
      .addCase(saveProfileCountries.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(saveProfileCities.fulfilled, (state, action) => {
        state.isLoading = false;
        postsAdapter.updateOne(state, action.payload);
        state.profileTouched = true;
      })
      .addCase(saveProfileCities.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateProfileSalary.fulfilled, (state, action) => {
        postsAdapter.updateOne(state, action.payload);
        state.profileTouched = true;
      })
      .addCase(updateProfileSalary.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(sendRecruiterPostMessage.fulfilled, (state, action) => {
        const { postId, postResponseId, message } = action.payload;
        const post = state.entities[postId];

        if (post) {
          const postResponse = post.postResponses.find(
            (response) => response.id === postResponseId
          );
          if (postResponse) {
            //postResponse.messageThread.messages.push(message);
            post.selectedResponse.messageThread.messages.push(message);
          }
        }
      })
      .addCase(sendRecruiterPostMessage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  postAdded,
  postUpdated,
  reactionAdded,
  postTagAdded,
  postTagRemoved,
  setSelectedResponseForPost,
} = postsSlice.actions;

export default postsSlice.reducer;

export const {
  selectAll: selectAllPosts,
  selectById: selectPostById,
  selectIds: selectPostIds,
} = postsAdapter.getSelectors((state) => state.posts);

export const selectPostsByUser = createSelector(
  [selectAllPosts, (state, userId) => userId],
  (posts, userId) => posts.filter((post) => post.user === userId)
);

export const selectPostsWithResponses = createSelector(
  [selectAllPosts],
  (posts) => {
    return posts.filter((post) => post.postResponses.length > 0);
  }
);
