import React from "react";

export const EmployerHoldingPage = () => {
  return (
    <div className="mt-10">
      <h6>Thanks for registering</h6>

      <p>
        <strong>We will contact you shortly to get your profile set up</strong>
      </p>
      <p>You will be sourcing top talent in no time :)</p>
    </div>
  );
};
