import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Tooltip,
} from "reactstrap";

import { useAuth } from "../../contexts/AuthContext";

import "./NavMenu.css";
import { NavMenuCandidateLinks } from "./NavMenuCandidateLinks";
import { NavMenuRecruiterLinks } from "./NavMenuRecruiterLinks";

export const NavMenu = () => {
  const [collapsed, setCollapsed] = useState(true);
  const { isLoggedIn, isCandidate, isEmployer, logoutUrl } = useAuth();
  const [loginTooltipOpen, setLoginTooltipOpen] = useState(false);
  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <Navbar className="navbar-expand-sm fixed-top bg-dark" light>
      <NavbarBrand href="/" className="me-auto">
        <img
          src="https://busoca.blob.core.windows.net/images/sourcers.png"
          width={92}
          alt="Sourcers Logo"
        />{" "}
      </NavbarBrand>
      {/* <NavbarToggler onClick={toggleNavbar} className="me-2" /> */}

      <Nav navbar className="flex-grow">
        <Collapse
          isOpen={!collapsed}
          className="flex-sm-row-reverse d-sm-none"
          navbar
        >
          {isLoggedIn && (
            <>
              {isCandidate && <NavMenuCandidateLinks />}
              {isEmployer && <NavMenuRecruiterLinks />}
            </>
          )}
        </Collapse>

        <NavItem>
          <a
            //This needs to be an <a> not NavLink
            //Or it just redirects to 404
            className="text-light nav-link"
            id="loginButton"
            href={isLoggedIn ? logoutUrl : "/bff/login"}
          >
            {isLoggedIn ? (
              <>
                <Tooltip
                  isOpen={loginTooltipOpen}
                  placement="bottom"
                  target="loginButton"
                  toggle={() => {
                    setLoginTooltipOpen(!loginTooltipOpen);
                  }}
                >
                  {isLoggedIn && "Log out"}
                </Tooltip>
                <i className="fi-x" />
              </>
            ) : (
              <>
                <i className="fi-user me-2"></i>Login / Register
              </>
            )}{" "}
          </a>
        </NavItem>
      </Nav>
    </Navbar>
  );
};
