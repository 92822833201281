import { OnboardStaticTagsPage } from "../OnboardStaticTagsPage";

export const UpdatePositionsPageWrapper = (props) => {
  return (
    <OnboardStaticTagsPage
      tagType={"Position"}
      lead={"Select positions which are of interest to you."}
      tagColor={"primary"}
      {...props}
      update={true}
    />
  );
};
