import { OnboardStaticTagsPage } from "../OnboardStaticTagsPage";

export const ContractTagsPageWrapper = (props) => {
  return (
    <OnboardStaticTagsPage
      tagType={"Type"}
      tagColor={"info"}
      lead={"What type of contracts are you interested in?"}
      {...props}
    />
  );
};
