import { StaticTagsPage } from "../recruiter/OnboardStaticTagsPage";

export const UpdateRecruiterOfficePreferencesPageWrapper = (props) => {
  return (
    <StaticTagsPage
      tagType={"OfficePreference"}
      text={"What is your companies remote work policy?"}
      tagColor={"secondary"}
      profileType={"recruiter"}
      {...props}
      update={true}
    />
  );
};
