export const options = [
  { label: "All", value: 0 },
  { label: "1 day", value: 1 },
  { label: "2 days", value: 2 },
  { label: "3 days", value: 3 },
  { label: "1 week", value: 4 },
  { label: "2 weeks", value: 5 },
  { label: "3 weeks", value: 6 },
  { label: "1 month", value: 7 },
];
