import React from "react";

export const IconCard = ({
  icon,
  title,
  description,
  iconClass,
  textClass,
  subTitle,
  link,
}) => {
  return (
    <>
      <div className="col">
        <div className="card border-0 shadow-sm card-hover h-100">
          <div className="card-body icon-box text-primary">
            <div
              className={`icon-box-media ${iconClass} ${textClass} rounded-circle mb-3`}
            >
              <i className={icon}></i>
            </div>
            {link ? (
              <a href={link}>
                <h3 className="h5 card-title text-info">{title}</h3>
              </a>
            ) : (
              <h3 className="h5 card-title text-info">{title}</h3>
            )}
            <p className="card-text fs-sm">{description}</p>

            {subTitle && <p>{subTitle}</p>}
          </div>
        </div>
      </div>
    </>
  );
};
