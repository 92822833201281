import EmployerRoute from "../auth/EmployerRoute";
import { PostPositionTagsPageWrapper } from "../components/pages/recruiter/posts/PostPositionTagsPageWrapper";
import { PostFirmTypeTagsPageWrapper } from "../components/pages/recruiter/posts/PostFirmTypeTagsPageWrapper";
import { PostDivisionTagsPageWrapper } from "../components/pages/recruiter/posts/PostDivisionTagsPageWrapper";
import { PostIndustryTagsPageWrapper } from "../components/pages/recruiter/posts/PostIndustryTagsPageWrapper";

import { OnboardPostSkillsPage } from "../components/pages/recruiter/posts/OnboardPostSkillsPage";
import { POstQualificationTagsPageWrapper } from "../components/pages/recruiter/posts/PostQualificationTagsPageWrapper";
import { PostOfficePreferenceTagsPageWrapper } from "../components/pages/recruiter/posts/PostOfficePreferenceTagsPageWrapper";
import { PostCountryTagsPageWrapper } from "../components/pages/recruiter/posts/PostCountryTagsPageWrapper";
import { PostCityTagsPageWrapper } from "../components/pages/recruiter/posts/PostCityTagsPageWrapper";
import { PostLanguageTagsPageWrapper } from "../components/pages/recruiter/posts/PostLanguageTagsPageWrapper";
import { PostEducationTagsPageWrapper } from "../components/pages/recruiter/posts/OnboardPostEducationTagsPageWrapper";
import { PostContractTagsPageWrapper } from "../components/pages/recruiter/posts/PostContractTagsPageWrapper";
import { PostOnboardSalaryPage } from "../components/pages/recruiter/posts/PostOnboardSalaryPage";

export const OnboardRecruiterPostRoutes = () => {
  return (
    <>
      <EmployerRoute
        path="/recruiter/posts/:postId/profile/:profileId/positions"
        component={PostPositionTagsPageWrapper}
      />

      <EmployerRoute
        path="/recruiter/posts/:postId/profile/:profileId/firmtypes"
        component={PostFirmTypeTagsPageWrapper}
      />

      <EmployerRoute
        path="/recruiter/posts/:postId/profile/:profileId/divisions"
        component={PostDivisionTagsPageWrapper}
      />

      <EmployerRoute
        path="/recruiter/posts/:postId/profile/:profileId/industries"
        component={PostIndustryTagsPageWrapper}
      />

      <EmployerRoute
        path="/recruiter/posts/:postId/profile/:profileId/skills"
        component={OnboardPostSkillsPage}
      />

      <EmployerRoute
        path="/recruiter/posts/:postId/profile/:profileId/qualifications"
        component={POstQualificationTagsPageWrapper}
      />

      <EmployerRoute
        path="/recruiter/posts/:postId/profile/:profileId/office"
        component={PostOfficePreferenceTagsPageWrapper}
      />

      <EmployerRoute
        path="/recruiter/posts/:postId/profile/:profileId/countries"
        component={PostCountryTagsPageWrapper}
      />

      <EmployerRoute
        path="/recruiter/posts/:postId/profile/:profileId/cities"
        component={PostCityTagsPageWrapper}
      />

      <EmployerRoute
        path="/recruiter/posts/:postId/profile/:profileId/languages"
        component={PostLanguageTagsPageWrapper}
      />

      <EmployerRoute
        path="/recruiter/posts/:postId/profile/:profileId/educations"
        component={PostEducationTagsPageWrapper}
      />

      <EmployerRoute
        path="/recruiter/posts/:postId/profile/:profileId/contracts"
        component={PostContractTagsPageWrapper}
      />

      <EmployerRoute
        path="/recruiter/posts/:postId/profile/:profileId/salary"
        component={PostOnboardSalaryPage}
      />
    </>
  );
};
