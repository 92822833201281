import { OnboardRecruiterMultiTagsPage } from "../recruiter/OnboardRecruiterMultiTagsPage";

export const UpdateRecruiterCountriesPageWrapper = (props) => {
  return (
    <OnboardRecruiterMultiTagsPage
      tagType={"Country"}
      text={"What countries can remote candidates work from / be based?"}
      {...props}
      update={true}
    />
  );
};
