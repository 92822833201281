import { TimeAgo } from "../../common/TimeAgo";

export const MatchCardHeader = ({ match }) => {
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-row align-items-center">
          {match && match.recruiter && match.recruiter.avatar && (
            <img
              className="avatar avatar-sm avatar-4x3"
              src={match.recruiter.avatar.location}
              alt="logo"
              style={{ verticalAlign: "middle" }}
            />
          )}
          <div className="ms-2 c-details">
            {match && match.recruiter && (
              <>
                <h6 className="mb-0">
                  {match.recruiter.firstName} {match.recruiter.lastName}
                </h6>

                <p className="small mb-0">
                  {match.recruiter.title} | {match.recruiter.employer}
                </p>
                <span className="small">
                  <TimeAgo timestamp={match.created} />
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
