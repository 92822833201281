import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const CommentEditor = ({
  editorState,
  handleEditorChange,
  content,
  placeholder,
}) => {
  return (
    <>
      <Editor
        editorState={editorState}
        wrapperClassName="form-control form-control-square-right"
        editorClassName="comment-editor-class"
        onEditorStateChange={handleEditorChange}
        placeholder={placeholder}
        toolbarHidden
      />
    </>
  );
};
