import { PostStaticTagsPage } from "./PostStaticTagsPage";

export const PostFirmTypeTagsPageWrapper = ({ match, ...props }) => {
  const { postId, profileId } = match.params;

  return (
    <PostStaticTagsPage
      tagType={"FirmType"}
      postId={postId}
      profileId={profileId}
      lead={<>What type of companies is this post relevant to?</>}
      {...props}
    />
  );
};
