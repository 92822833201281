import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { selectPostById } from "../../features/posts/postsSlice";
import { CardHTML } from "./CardHTML";
import { PostCardRecruiterButtonLinks } from "./PostCardRecruiterButtonLinks";
import { PostCardPostResponses } from "./PostCardPostResponses";
import { PostResponsesOverview } from "./PostResponsesOverview";
import { CardTagsComponent } from "../common/CardTagsComponent";
import { PostResponseComponent } from "./posts/PostResponseComponent";

import { EditorState, convertToRaw } from "draft-js";
import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";

import { CommentEditor } from "./CommentEditor";

import {
  sendRecruiterPostMessage,
  setSelectedResponseForPost,
} from "../../features/posts/postsSlice";

import { toast } from "react-toastify";

export const PostCard = ({ postId, ...props }) => {
  const dispatch = useDispatch();
  const post = useSelector((state) => selectPostById(state, postId));

  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [errors, setErrors] = useState({});
  const [editorStates, setEditorStates] = useState({});
  const [editorsCurrentCharacters, setEditorsCurrentCharacters] = useState(
    post.postResponses
      .filter((post) => post.type === "Engage")
      .reduce((acc, response) => {
        acc[response.id] = 0;
        return acc;
      }, {})
  );

  const editorStateId = post.selectedResponse?.id || ""; // Use an empty string as a default
  const editorState = editorStates[editorStateId];
  const currentCharacters = editorsCurrentCharacters[editorStateId] || 0;

  useEffect(() => {
    if (post.postResponses !== 0) {
      const postResponse = post.postResponses[0];
      dispatch(setSelectedResponseForPost({ postId, postResponse }));
    }
  }, [post.postResponses, dispatch, postId]);

  useEffect(() => {});

  const [dtos, setDtos] = useState({});

  const maxCharacters = 750;

  const handleSelectResponse = ({ response }) => {
    const postId = post.id;
    dispatch(setSelectedResponseForPost({ postId, response }));
  };

  useEffect(() => {
    initializeEditorStates();
  }, [post]);

  useEffect(() => {
    // Set the selected response for each post
    post.postResponses.forEach((response) => {
      dispatch(setSelectedResponseForPost({ postId: post.id, response }));
    });
  }, []);

  const initializeEditorStates = () => {
    const editorStatesMap = {};
    const dtosMap = {};

    post.postResponses
      .filter((response) => response.type === "Engage")
      .forEach((response) => {
        const postResponseId = response.id;

        editorStatesMap[postResponseId] = EditorState.createEmpty();
        dtosMap[postResponseId] = { comment: "" };
      });

    setEditorStates(editorStatesMap);
    setDtos(dtosMap);
  };

  const handleCommentChange = (newEditorState, postResponseId) => {
    const currentContent = newEditorState.getCurrentContent();
    const currentText = currentContent.getPlainText("");

    // Check if the current text length exceeds the max characters
    if (currentText.length <= maxCharacters) {
      // Update the editor state for the specified post response
      setEditorStates((prevState) => ({
        ...prevState,
        [postResponseId]: newEditorState,
      }));

      setEditorsCurrentCharacters((prevState) => ({
        ...prevState,
        [postResponseId]: currentText.length,
      }));

      convertContentToHTML(postResponseId);
    } else {
      // If the text exceeds the character limit, restore the previous editor state
      setEditorStates((prevState) => ({
        ...prevState,
        [postResponseId]: EditorState.createWithContent(
          prevState[postResponseId].getCurrentContent()
        ),
      }));
    }
  };

  const convertContentToHTML = () => {
    const contentState = editorStates[editorStateId].getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlToSanitize = draftToHtml(rawContentState);
    const sanitizedHTML = sanitize(htmlToSanitize);
    setDtos((prevDtos) => ({
      ...prevDtos,
      [editorStateId]: { comment: sanitizedHTML },
    }));
  };

  const sanitize = (html) => {
    return DOMPurify.sanitize(html);
  };

  const canSave = () =>
    dtos[editorStateId].comment && addRequestStatus === "idle";

  const handleSendMessage = async (response, postResponseId) => {
    if (!canSave(postResponseId)) {
      return;
    }

    try {
      setAddRequestStatus("pending");

      const postId = post.selectedResponse.postId;
      const postResponseId = editorStateId;

      // Use the dto for the specified post response
      const dto = dtos[editorStateId];

      await dispatch(sendRecruiterPostMessage({ postId, postResponseId, dto }))
        .unwrap()
        .then((response) => {
          toast.success("Message sent");
          setDtos((prevDtos) => ({
            ...prevDtos,
            [postResponseId]: { comment: "" },
          }));
          setEditorStates((prevEditorStates) => ({
            ...prevEditorStates,
            [postResponseId]: EditorState.createEmpty(),
          }));
        });
    } catch (error) {
      toast.error("Failed to send message");
      setErrors({ onSave: error.message });
    } finally {
      setAddRequestStatus("idle");
    }
  };

  return (
    <>
      <div className="card card-bordered mb-2">
        <div className="card-body p-3">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-row align-items-center">
              {post && post.recruiter && post.recruiter.avatar && (
                <img
                  className="avatar avatar-sm avatar-4x3"
                  src={post.recruiter.avatar.location}
                  alt="logo"
                  style={{ verticalAlign: "middle" }}
                />
              )}
              <div className="ms-2 c-details">
                {post && post.recruiter && (
                  <>
                    <h6 className="mb-0">
                      {post.recruiter.firstName} {post.recruiter.lastName}
                    </h6>
                    <p className="small mb-0">
                      {post.recruiter.title} | {post.recruiter.employer}
                    </p>
                  </>
                )}
              </div>
            </div>

            <PostCardRecruiterButtonLinks post={post} />
            {/* Space for edit etc. button */}
          </div>

          <div className="mt-2">
            <p className="card-title text-lead">
              <strong>
                <a
                  className="text-primary"
                  href={post.extUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {post.title}
                </a>
              </strong>
            </p>

            <hr className="mb-3" />
            <CardHTML text={post.pitch} />
            <hr className="mb-3 mt-3" />

            <CardTagsComponent profile={post.profile} />

            {/* <div className="d-flex flex-wrap">
              {post.profile && post.profile.cities && (
                <CityBadge cityName={post.profile.cities} />
              )} 
            </div>*/}
            <hr className="mb-2"></hr>
            {post.postResponses && post.postResponses.length > 0 && (
              <>
                <div className="border-bottom">
                  <PostResponsesOverview postResponses={post.postResponses} />
                </div>
                {/* <PostResponseList
                  postResponses={post.postResponses}
                  recruiter={post.recruiter}
                /> */}
                <div className="row">
                  <div className="col-4">
                    <div
                      className="overflow-auto"
                      data-simplebar
                      data-simplebar-auto-hide="false"
                    >
                      <PostCardPostResponses
                        post={post}
                        handleSelectResponse={handleSelectResponse}
                      />
                    </div>
                  </div>
                  <div className="col-8">
                    {post.selectedResponse && (
                      <div
                        className="overflow-auto"
                        data-simplebar
                        data-simplebar-auto-hide="false"
                      >
                        <PostResponseComponent
                          postResponse={post.selectedResponse}
                        />
                        <div className="py-2">
                          <div className="d-flex justify-content-end">
                            <CommentEditor
                              editorState={editorStates[editorStateId]}
                              handleEditorChange={(newEditorState) =>
                                handleCommentChange(
                                  newEditorState,
                                  editorStateId
                                )
                              }
                              toolbarHidden
                            />
                            <button
                              className="btn btn-success"
                              onClick={() => handleSendMessage()}
                              style={{
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                              }}
                            >
                              <i className="fi-send"></i>
                            </button>
                          </div>
                          <p className="d-flex justify-content-end">
                            <em>
                              {maxCharacters -
                                editorsCurrentCharacters[editorStateId]}{" "}
                              / {maxCharacters} remaining
                            </em>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
