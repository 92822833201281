import React from "react";
import PropTypes from "prop-types";

export const SourcersTextInput = ({
  name,
  label,
  onChange,
  placeholder,
  value,
  required,
  error,
  type,
}) => {
  let wrapperClass = "form-group";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }

  return (
    <>
      <label className="form-label" for="pr-fn">
        {label}
        {required && <span className="text-danger">*</span>}
      </label>
      {type === "textarea" ? (
        <textarea
          className="form-control form-control-lg"
          type="text"
          id="pr-fn"
          value={value}
          name={name}
          placeholder={placeholder}
          required={required}
          onChange={onChange}
          rows={5}
        />
      ) : (
        <input
          className="form-control form-control-lg"
          type="text"
          id="pr-fn"
          value={value}
          name={name}
          placeholder={placeholder}
          required={required}
          onChange={onChange}
        />
      )}
      {error && <div className="alert alert-danger">{error}</div>}
    </>
  );
};

SourcersTextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
};
