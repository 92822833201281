import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { fetchTags, selectAllTags } from "../../../../features/tags/tagsSlice";

import {
  addNewPost,
  updatePost,
  fetchPosts,
  selectPostById,
} from "../../../../features/posts/postsSlice";

import { tagConstants } from "../../../../app/tagConstants";
import { InfoAlert } from "../../../common/InfoAlert";
import { CenteredSingleColumnLayout } from "../../../common/CenteredSingleColumnLayout";

import { OnboardPostTags } from "./OnboardPostTags";
import { CheckOwnerComponent } from "../../../../auth/CheckOwnerComponent";

export const PostStaticTagsPage = ({
  tagType,
  text,
  lead,
  link,
  postId,
  profileId,
  ...props
}) => {
  const dispatch = useDispatch();
  const tags = useSelector(selectAllTags);
  const tagStatus = useSelector((state) => state.tags.status);

  const [currentTag, setCurrentTag] = useState();

  const [nextTag, setNextTag] = useState();
  const [prevTag, setPrevTag] = useState();

  const allTags = tags.filter((t) => t.type === tagType);

  const post = useSelector((state) => selectPostById(state, postId));
  const postStatus = useSelector((state) => state.posts.status);

  const [icon, setIcon] = useState();

  const [requiredOwnerId, setRequiredOwnerId] = useState();

  const currentTagFromTagConstants = tagConstants.find(
    (t) => t.type === tagType
  );

  useEffect(() => {
    console.log(tagStatus);
    if (tagStatus === "idle") {
      dispatch(fetchTags());
    }
  }, [tagStatus, dispatch]);

  useEffect(() => {
    if (postStatus === "idle") {
      dispatch(fetchPosts());
    }
  }, [postStatus, dispatch]);

  useEffect(() => {
    if (postId && post) {
      setRequiredOwnerId(post.ownerId);
    }
  }, [postId, post]);

  useEffect(() => {
    setCurrentTag(tagConstants.find((t) => t.type === tagType));

    setNextTag(
      tagConstants.find((t) => t.order === currentTagFromTagConstants.order + 1)
    );

    setPrevTag(
      tagConstants.find((t) => t.order === currentTagFromTagConstants.order - 1)
    );
  }, [currentTagFromTagConstants.order, tagType]);

  useEffect(() => {
    if (currentTag && currentTag.fiIcon) {
      setIcon(currentTag.fiIcon);
    }
  }, [currentTag]);

  return (
    <>
      <CenteredSingleColumnLayout>
        {post && (
          <CheckOwnerComponent requiredOwnerId={post.ownerId}>
            {(lead || text) && (
              <InfoAlert
                lead={lead}
                text={text}
                link={link}
                href={nextTag ? nextTag.href : "salary"}
              />
            )}

            {currentTag && prevTag && nextTag && (
              <OnboardPostTags
                post={post}
                profileId={profileId}
                all={allTags}
                tagType={tagType}
                tagColor={currentTag.color}
                tagTypeName={currentTag.name}
                nextHref={nextTag ? nextTag.href : "salary"}
                prevHref={prevTag ? prevTag.href : "basic-info"}
                nextTitle={nextTag ? nextTag.name : "Salary"}
                prevTitle={prevTag ? prevTag.name : "Basic Info"}
                showFilter={currentTag.showFilter}
                icon={icon}
              />
            )}
          </CheckOwnerComponent>
        )}
      </CenteredSingleColumnLayout>
    </>
  );
};
