import React from "react";
import { SourcersTextInput } from "../../../common/SourcersTextInput";

export const ContactForm = ({
  dto,
  onSave,
  onChange,
  saving = false,
  errors = {},
}) => {
  return (
    <form onSubmit={onSave}>
      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}

      {/* <input type="hidden" name="test" value={type} /> */}

      <SourcersTextInput
        name="email"
        placeholder="Enter your email"
        value={dto.email}
        onChange={onChange}
        error={errors.email}
      />

      <SourcersTextInput
        name="firstName"
        placeholder="Enter your first name"
        value={dto.firstName}
        onChange={onChange}
        error={errors.firstName}
      />

      <SourcersTextInput
        name="lastName"
        placeholder="Enter your last name"
        value={dto.lastName}
        onChange={onChange}
        error={errors.lastName}
      />

      <SourcersTextInput
        name="company"
        placeholder="Enter your company name"
        value={dto.company}
        onChange={onChange}
        error={errors.company}
      />

      <SourcersTextInput
        name="position"
        placeholder="Enter your job title"
        value={dto.position}
        onChange={onChange}
        error={errors.position}
      />

      <SourcersTextInput
        name="message"
        placeholder="Enter your message"
        value={dto.message}
        onChange={onChange}
        error={errors.message}
        type={"textarea"}
        rows={5}
      />

      <button
        type="submit"
        disabled={saving}
        className="btn btn-primary btn-lg d-block w-100 mt-5"
      >
        {saving ? "Sending..." : "Send"}
      </button>
    </form>
  );
};
