import React from "react";

export const ClearFilters = ({ resetFilters }) => {
  return (
    <>
      <div className="d-flex flex-sm-row flex-column align-items-sm-center align-items-stretch mb-3">
        <hr className="d-none d-sm-block w-100 mx-2" />
        <div className="d-sm-flex align-items-center flex-shrink-0 text-muted">
          <button
            className="btn btn-sm btn-outline-danger mr-1 w-100"
            onClick={() => resetFilters()}
          >
            Clear Filters
          </button>{" "}
        </div>
      </div>
    </>
  );
};
