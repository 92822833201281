import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { PrevNextBtnLgRound } from "../PrevNextButtonLgRound";
import { MultiTagSelect } from "../../../../common/MultiTagSelect";

import * as remoteClient from "../../../../../dataServices/remoteClient";

import { toast } from "react-toastify";
import { InfoAlert } from "../../../../common/InfoAlert";

import {
  fetchRecruiter,
  saveProfileMultiTags,
} from "../../../../../features/recruiter/recruiterSlice";
import { CenteredSingleColumnLayout } from "../../../../common/CenteredSingleColumnLayout";
import { RecruiterAllAnySkillsDescription } from "./RecruiterAllAnySkillsDescription";

export const OnboardRecruiterSkillsPage = ({ update }) => {
  const recruiter = useSelector((state) => state.recruiter.recruiter);

  const [defaultValue, setDefaultValue] = useState();
  const [allDefaultValue, setAllDefaultValue] = useState();

  useEffect(() => {
    if (recruiter && recruiter.profile && recruiter.profile.tagsForSelect) {
      setDefaultValue(
        recruiter.profile.tagsForSelect.filter(
          (t) => t.type === "Skill" && t.profileTagType === "Any"
        )
      );
      setAllDefaultValue(
        recruiter.profile.tagsForSelect.filter(
          (t) => t.type === "Skill" && t.profileTagType === "All"
        )
      );
    }
  }, [recruiter]);

  const dispatch = useDispatch();

  const [selectedOptions, setSelectedOptions] = useState({
    allTags: [],
    anyTags: [],
    allTouched: false,
    anyTouched: false,
  });

  //const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);

  let history = useHistory();

  const getSkills = async (input) => {
    if (input !== null && input !== undefined && input !== "") {
      return await remoteClient.get(
        `/api/tags/select/skills?searchTerm=${input}`
      );
    }
  };

  useEffect(() => {
    if (recruiter === null) {
      dispatch(fetchRecruiter());
    }
  }, [recruiter, dispatch]);

  const handleAnySkillsInput = (newValue) => {
    setSelectedOptions({
      ...selectedOptions,
      anyTags: newValue,
      anyTouched: true,
    });
  };

  const handleAllSkillsInput = (newValue) => {
    setSelectedOptions({
      ...selectedOptions,
      allTags: newValue,
      allTouched: true,
    });
  };

  const redirectAfterSave = () => {
    if (update) {
      history.push("/recruiter/dashboard");
    } else {
      history.push("qualifications");
    }
  };

  const handleSaveSkills = () => {
    if (!selectedOptions.allTouched && !selectedOptions.anyTouched) {
      redirectAfterSave();
    } else {
      setSaving(true);
      let tagType = "skill";
      dispatch(saveProfileMultiTags({ recruiter, tagType, selectedOptions }))
        .then(() => {
          toast.success(`Skills saved`);
          redirectAfterSave();
        })
        .catch((error) => {
          setSaving(false);
          // setErrors({ onSave: error.message });
          toast.error(`Saving tags failed: ${error.message}`);
        });
    }
  };

  return (
    <>
      <CenteredSingleColumnLayout>
        {" "}
        <InfoAlert
          lead="What skills do you want candidates to have to engage with you?"
          text={<RecruiterAllAnySkillsDescription />}
        />
        <div className="border-top py-4 mb-md-2">
          <h2 className="h4 mb-4">
            <i className="fi-star text-primary fs-5 mt-n1 me-2 pe-1"></i>
            ANY Skill Tags
          </h2>

          {defaultValue ? (
            <MultiTagSelect
              loadOptions={getSkills}
              placeholder="Start typing skills"
              onChange={handleAnySkillsInput}
              defaultValue={defaultValue}
              creatable={true}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="border-top py-4 mb-md-2">
          <h2 className="h4 mb-4">
            <i className="fi-star text-primary fs-5 mt-n1 me-2 pe-1"></i>
            ALL Skill Tags
          </h2>

          {allDefaultValue ? (
            <MultiTagSelect
              loadOptions={getSkills}
              placeholder="Start typing skills"
              onChange={handleAllSkillsInput}
              defaultValue={allDefaultValue}
              creatable={true}
            />
          ) : (
            <></>
          )}
        </div>
        <PrevNextBtnLgRound
          nextTitle={update ? "Update" : "Qualifications"}
          prevTitle={"Industries"}
          prevHref={update ? "" : "industries"}
          saving={saving}
          saveTags={handleSaveSkills}
          update={update}
        />
      </CenteredSingleColumnLayout>
    </>
  );
};
