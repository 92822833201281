import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchTags,
  selectAllTags,
} from "../../../../../features/tags/tagsSlice";

import { OnboardRecruiterTags } from "./OnboardRecruiterTags";

import { tagConstants } from "../../../../../app/tagConstants";
import { InfoAlert } from "../../../../common/InfoAlert";
import { CenteredSingleColumnLayout } from "../../../../common/CenteredSingleColumnLayout";

export const StaticTagsPage = ({
  tagType,
  profileType,
  text,
  lead,
  href,
  link,
  update,
}) => {
  const dispatch = useDispatch();

  const tags = useSelector(selectAllTags);
  const tagStatus = useSelector((state) => state.tags.status);

  const [currentTag, setCurrentTag] = useState();
  const [nextTag, setNextTag] = useState();
  const [prevTag, setPrevTag] = useState();
  const [icon, setIcon] = useState();

  const allTags = tags.filter((t) => t.type === tagType);

  const currentTagFromTagConstants = tagConstants.find(
    (t) => t.type === tagType
  );

  useEffect(() => {
    if (tagStatus === "idle") {
      dispatch(fetchTags());
    }
  }, [tagStatus, dispatch]);

  useEffect(() => {
    setCurrentTag(tagConstants.find((t) => t.type === tagType));

    setNextTag(
      tagConstants.find((t) => t.order === currentTagFromTagConstants.order + 1)
    );

    setPrevTag(
      tagConstants.find((t) => t.order === currentTagFromTagConstants.order - 1)
    );
  }, [currentTagFromTagConstants.order, tagType]);

  useEffect(() => {
    if (currentTag && currentTag.fiIcon) {
      setIcon(currentTag.fiIcon);
    }
  }, [currentTag]);

  return (
    <>
      <CenteredSingleColumnLayout>
        {(lead || text) && (
          <InfoAlert
            lead={lead}
            text={text}
            link={link}
            href={nextTag ? nextTag.href : "salary"}
          />
        )}

        {currentTag && prevTag && nextTag && (
          <OnboardRecruiterTags
            all={allTags}
            tagType={tagType}
            tagColor={currentTag.color}
            tagTypeName={currentTag.name}
            icon={icon}
            nextHref={nextTag ? nextTag.href : "salary"}
            prevHref={prevTag ? prevTag.href : "basic-info"}
            nextTitle={nextTag ? nextTag.name : "Salary"}
            prevTitle={prevTag ? prevTag.name : "Basic Info"}
            showFilter={currentTag.showFilter}
            update={update}
          />
        )}
      </CenteredSingleColumnLayout>{" "}
    </>
  );
};
