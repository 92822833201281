import React from "react";
import { useSelector } from "react-redux";

import { selectJobById } from "../../../features/search/jobSearchSlice";
import { JobCard } from "../../common/JobCard";

export const JobSearchResult = ({ jobId }) => {
  const job = useSelector((state) => selectJobById(state, jobId)).document;
  return (
    <>
      <JobCard key={jobId} job={job} />
    </>
  );
};
