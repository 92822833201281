import React, { useState } from "react";
import NewsLetterSignUpForm from "./NewsLetterSignUpForm";
import { toast } from "react-toastify";

import * as newsLetterDataService from "../../../dataServices/newsLetterDataService";

const NewsLetterSignUp = ({ newsletterType }) => {
  const [signupDto, setSignUpDto] = useState({});
  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);

  function handleChange(event) {
    const { name, value } = event.target;
    setSignUpDto((prevsignup) => ({
      ...prevsignup,
      [name]: value,
    }));
  }

  // function handleEmailChange(event) {
  //   const newEmail = event.target.value;
  //   setSignUpDto((prevSignUpDto) => ({
  //     ...prevSignUpDto,
  //     email: newEmail,
  //   }));
  // }

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function formIsValid() {
    const { email, firstName, lastName, company } = signupDto;
    const errors = {};

    if (!isValidEmail(email)) errors.email = "Email is required";
    if (!company) errors.company = "Company is required";
    if (!firstName) errors.firstName = "Name is required";
    if (!lastName) errors.lastName = "Name is required";

    setErrors(errors);
    return Object.keys(errors).length === 0;
  }

  function handleSave(event) {
    event.preventDefault();

    if (!formIsValid()) return;
    setSaving(true);
    newsLetterDataService
      .subscribeToNewsletter(signupDto, newsletterType)
      .then((data) => {
        setSaving(false);
        setSignUpDto({});
        toast.success(`Thanks for siging up ${data.firstName}`);
      })
      .catch((error) => {
        toast.error("Failed to subscribe.  Please try again");
      });
  }

  return (
    <section className="container mb-5 pb-lg-5">
      <div className="rounded-3 bg-faded-success py-5 px-sm-5 px-4">
        <div className="mx-auto py-md-4 text-center">
          <h2>Keep in touch</h2>
          <p className="mb-4 pb-2 fs-lg">
            Subscribe to stay informed about sourcing techniques, talent hacks,
            employer branding, candidate marketing and receive occassional
            offers and promotions.
          </p>
          <NewsLetterSignUpForm
            signupDto={signupDto}
            onChange={handleChange}
            onSave={handleSave}
            saving={saving}
            errors={errors}
          />{" "}
          <div className="form-text mt-5">
            * By signing up you agree to our{" "}
            <a href="/privacy" className="text-decoration-none">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsLetterSignUp;
