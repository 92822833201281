import React from "react";

export const HeaderText = ({
  background,
  headerText,
  headerSpan,
  leadText,
}) => {
  const backgroundColorClass = background === "light" ? "bg-light" : "bg-dark";

  return (
    // <section className={`${backgroundColorClass} py-5`}>
    //   <div className="container pt-5 pb-lg-5">
    //     <div className="col-md-7 mb-lg-5 mx-auto text-center">
    //       <h1 className="display-5 text-light mb-4 pb-lg-2">
    //         {headerText} <span className="text-info-light">{headerSpan}</span>
    //       </h1>
    //       <p className="lead text-light opacity-70">{leadText}</p>
    //     </div>
    //   </div>
    // </section>
    <section className="mt-5">
      <div className="container py-5">
        <div className="card border-0 bg-dark p-md-3 p-1">
          <div className="card-body">
            <div className="col-md-7 mb-lg-5 mx-auto text-center">
              <h1 className="display-5 text-light mb-4 pb-lg-2">
                {headerText}{" "}
                <span className="text-info-light">{headerSpan}</span>
              </h1>
              <p className="lead text-light opacity-70">{leadText}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
