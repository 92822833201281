import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";

import * as remoteClient from "../../dataServices/remoteClient";

const jobsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.created.localeCompare(a.created),
});

//const jobsAdapter = createEntityAdapter();

const initialState = jobsAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchJobs = createAsyncThunk("jobs/fetchJobs", async () => {
  const response = await remoteClient.get("api/jobs");
  return response;
});

const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    // jobAdded: {
    //   reducer(state, action) {
    //     state.jobs.push(action.payload);
    //   },
    // },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.status = "succeeded";
        //console.log("Succeeded: ", JSON.stringify(action.payload));
        jobsAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    //.addCase(addNewJob.fulfilled, jobsAdapter.addOne);
  },
});

//export const { jobAdded } = jobsSlice.actions;

export default jobsSlice.reducer;

export const {
  selectAll: selectAllJobs,
  selectById: selectJobById,
  selectIds: selectJobIds,
} = jobsAdapter.getSelectors((state) => state.jobs);
