import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

import { OnboardTagsForm } from "../../user/onboard/OnboardTagsForm";

import { PrevNextBtnLgRound } from "../../user/onboard/PrevNextButtonLgRound";

import { toast } from "react-toastify";

import {
  fetchPosts,
  postTagAdded,
  postTagRemoved,
  savePostProfileTags,
} from "../../../../features/posts/postsSlice";

export const OnboardPostTags = ({
  post,
  profileId,
  all,
  tagType,
  tagTypeName,
  icon,
  nextHref,
  prevHref,
  nextTitle,
  prevTitle,
  showFilter,
  tagColor,
  ...props
}) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const [availableTags, setAvailableTags] = useState([]);
  const [postId, setPostId] = useState("");
  const [touched, setTouched] = useState(false);
  const [errors, setErrors] = useState(false);

  const [saving, setSaving] = useState(false);

  const postStatus = useSelector((state) => state.posts.status);

  useEffect(() => {
    if (post && post.profile) {
      setAvailableTags([
        ...all.filter((a) => !post.profile.tags.some((t) => t.id === a.id)),
      ]);
      setPostId(post.id);
    }
  }, [post, all]);

  useEffect(() => {
    if (postStatus === "idle") {
      dispatch(fetchPosts());
    }
  }, [postStatus, dispatch]);

  const handleSelectTag = (tag) => {
    setTouched(true);
    dispatch(postTagAdded({ postId, tag }));
  };

  const handleRemoveTag = (tag) => {
    setTouched(true);
    dispatch(postTagRemoved({ postId, tag }));
  };

  const handleSaveTags = () => {
    if (!touched) {
      history.push(nextHref);
    } else {
      setSaving(true);
      history.push(nextHref);

      dispatch(savePostProfileTags({ post, tagType }))
        .then(() => {
          toast.success(`${tagTypeName} tags saved`);
          setTouched(false);
        })
        .catch((error) => {
          setSaving(false);
          setErrors({ onSave: error.message });
          toast.error(`Saving tags failed: ${error.message}`);
        });
    }
  };

  return (
    <>
      {post && post.profile ? (
        <>
          <OnboardTagsForm
            tags={availableTags}
            tagColor={tagColor}
            title={tagTypeName}
            tagType={tagTypeName}
            selectedTags={post.profile.tags.filter((t) => t.type === tagType)}
            profileId={post.profile.id}
            errors={errors}
            selectTag={handleSelectTag}
            removeTag={handleRemoveTag}
            showFilter={showFilter}
            icon={icon}
          />

          <PrevNextBtnLgRound
            nextHref={nextHref}
            prevHref={prevHref}
            nextTitle={nextTitle}
            prevTitle={prevTitle}
            saving={saving}
            saveTags={handleSaveTags}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
