import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { CommentEditor } from "../../common/CommentEditor";

import {
  selectPostIds,
  fetchPosts,
  selectPostsWithResponses,
  sendRecruiterPostMessage,
} from "../../../features/posts/postsSlice";

import { useAuth } from "../../../contexts/AuthContext";
import { Spinner } from "../../common/Spinner";
import { PostResponseComponent } from "../../common/posts/PostResponseComponent";
import { PostRecruiterMessageCard } from "../../common/posts/PostRecruiterMessageCard";
import { PostResponseCandidateMessageCard } from "../../common/posts/PostResponseCandidateMessageCard";

import { EditorState, convertToRaw } from "draft-js";
import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";

import { toast } from "react-toastify";

export const MessagesPage = () => {
  const { user, name } = useAuth();

  const dispatch = useDispatch();

  const [selectedPost, setSelectedPost] = useState();
  const [selectedPostResponse, setSelectedPostResponse] = useState();

  const orderedPostIds = useSelector(selectPostIds);
  const postStatus = useSelector((state) => state.posts.status);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [errors, setErrors] = useState({});

  const postsWithResponses = useSelector(selectPostsWithResponses);

  useEffect(() => {
    if (postStatus === "idle") {
      dispatch(fetchPosts());
    }
  }, [[postStatus, dispatch]]);

  useEffect(() => {
    if (postsWithResponses.length > 0) {
      setSelectedPost(postsWithResponses[0]);
      setSelectedPostResponse(postsWithResponses[0].postResponses[0]);
    }
  }, [postsWithResponses]);

  ////Multiple Editor States

  const [editorStates, setEditorStates] = useState({});
  const [dtos, setDtos] = useState({});

  useEffect(() => {
    initializeEditorStates();
  }, [postStatus]);

  const initializeEditorStates = () => {
    const editorStatesMap = {};
    const dtosMap = {};

    postsWithResponses.forEach((post) => {
      post.postResponses
        .filter((response) => response.type === "Engage")
        .forEach((response) => {
          const postResponseId = response.id;

          editorStatesMap[postResponseId] = EditorState.createEmpty();
          dtosMap[postResponseId] = { comment: "" };
        });
    });

    setEditorStates(editorStatesMap);
    setDtos(dtosMap);
  };

  const handleCommentChange = (newEditorState, postResponseId) => {
    // Update the editor state for the specified post response
    setEditorStates((prevState) => ({
      ...prevState,
      [postResponseId]: newEditorState,
    }));
    convertContentToHTML(postResponseId);
  };

  const convertContentToHTML = (postResponseId) => {
    const contentState = editorStates[postResponseId].getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlToSanitize = draftToHtml(rawContentState);
    const sanitizedHTML = sanitize(htmlToSanitize);
    setDtos((prevDtos) => ({
      ...prevDtos,
      [postResponseId]: { comment: sanitizedHTML },
    }));
  };

  // Sanitize any bad input from the editor
  const sanitize = (html) => {
    return DOMPurify.sanitize(html);
  };

  const canSave = (postResponseId) =>
    dtos[postResponseId].comment && addRequestStatus === "idle";

  const handleSendMessage = async (response, postResponseId) => {
    if (!canSave(postResponseId)) {
      return;
    }

    try {
      setAddRequestStatus("pending");

      const postId = response.postId;

      // Use the dto for the specified post response
      const dto = dtos[postResponseId];

      await dispatch(sendRecruiterPostMessage({ postId, postResponseId, dto }))
        .unwrap()
        .then((response) => {
          toast.success("Message sent");
          setDtos((prevDtos) => ({
            ...prevDtos,
            [postResponseId]: { comment: "" },
          }));
          setEditorStates((prevEditorStates) => ({
            ...prevEditorStates,
            [postResponseId]: EditorState.createEmpty(),
          }));
        });
    } catch (error) {
      toast.error("Failed to send message");
      setErrors({ onSave: error.message });
    } finally {
      setAddRequestStatus("idle");
    }
  };

  return (
    <div className="mt-5 row justify-content-center pb-sm-2">
      <div className="mt-5 col-lg-11 col-xl-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 col-lg-2 p-0" id="contact-list">
              <div className="pb-4 mb-2">
                <div
                  className="overflow-auto"
                  data-simplebar
                  data-simplebar-auto-hide="false"
                  style={{ height: "100vh" }}
                >
                  {postsWithResponses &&
                    postsWithResponses.map((post) => (
                      <div onClick={() => setSelectedPost(post)} key={post.id}>
                        <PostRecruiterMessageCard post={post} />
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-2 p-0" id="contact-list">
              <div className="pb-4 mb-2">
                <div
                  className="overflow-auto"
                  data-simplebar
                  data-simplebar-auto-hide="false"
                  style={{ height: "100vh" }}
                >
                  {selectedPost &&
                    selectedPost.postResponses
                      .filter((response) => response.type === "Engage")
                      .map((response) => (
                        <>
                          {response.candidate && (
                            <div
                              onClick={() => setSelectedPostResponse(response)}
                              key={response.id}
                            >
                              <div className="border-bottom mb-2">
                                <PostResponseCandidateMessageCard
                                  candidate={response.candidate}
                                />
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-8" id="message-thread">
              <div
                className="overflow-auto"
                data-simplebar
                data-simplebar-auto-hide="false"
                style={{ height: "100vh" }}
              >
                {selectedPostResponse && (
                  <>
                    <PostResponseComponent
                      postResponse={selectedPostResponse}
                    />
                    <div id="fixed-chat-input">
                      <div className="d-flex justify-content-end bottom-0 mb-2">
                        <CommentEditor
                          editorState={editorStates[selectedPostResponse.id]}
                          handleEditorChange={(newEditorState) =>
                            handleCommentChange(
                              newEditorState,
                              selectedPostResponse.id
                            )
                          }
                          toolbarHidden
                        />
                        <button
                          className="btn btn-success"
                          onClick={() =>
                            handleSendMessage(
                              selectedPostResponse,
                              selectedPostResponse.id
                            )
                          }
                          style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                          }}
                        >
                          <i className="fi-send"></i>
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
