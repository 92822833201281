import React, { useState } from "react";

import { Link } from "react-router-dom";
import { Col, NavLink, Tooltip } from "reactstrap";

export const NavFooterRecruiterLinks = () => {
  const [navFooterFeedTooltipOpen, setNavFooterFeedTooltipOpen] =
    useState(false);
  const [navFooterProfileTooltipOpen, setNavFooterProfileTooltipOpen] =
    useState(false);
  const [navFooterSettingsTooltipOpen, setNavFooterSettingsTooltipOpen] =
    useState(false);

  return (
    <>
      <Col>
        <NavLink
          tag={Link}
          className="text-light text-center"
          id="navFooterSettingsButton"
          to="/settings"
        >
          <i className="fi-settings" />
          <Tooltip
            isOpen={navFooterSettingsTooltipOpen}
            placement="bottom"
            target="navFooterSettingsButton"
            toggle={() => {
              setNavFooterSettingsTooltipOpen(!navFooterSettingsTooltipOpen);
            }}
          >
            Feed
          </Tooltip>
        </NavLink>
      </Col>

      <Col>
        <NavLink
          tag={Link}
          className="text-light text-center"
          id="navFooterFeedButton"
          to="/recruiter/dashboard"
        >
          <i className="fi-list" />
          <Tooltip
            isOpen={navFooterFeedTooltipOpen}
            placement="bottom"
            target="navFooterFeedButton"
            toggle={() => {
              setNavFooterFeedTooltipOpen(!navFooterFeedTooltipOpen);
            }}
          >
            Feed
          </Tooltip>
        </NavLink>
      </Col>
      <Col>
        <NavLink
          tag={Link}
          className="text-light text-center"
          to="/onboard/recruiter/basic-info"
          id="navFooterProfileButton"
        >
          <i className="fi-user" />
          <Tooltip
            isOpen={navFooterProfileTooltipOpen}
            placement="bottom"
            target="navFooterProfileButton"
            toggle={() => {
              setNavFooterProfileTooltipOpen(!navFooterProfileTooltipOpen);
            }}
          >
            Profile
          </Tooltip>
        </NavLink>
      </Col>
    </>
  );
};
