import React from "react";
import { useDispatch } from "react-redux";

import { toggleSavedItem } from "../../features/feeds/candidateFeedSlice";
import { toggleSavedItemOnSearch } from "../../features/search/jobSearchSlice";
import { addToSavedJobsList } from "../../features/saved/savedFeedItemsSlice";

export const SaveFeedItemButton = ({ feedItem }) => {
  const dispatch = useDispatch();
  const itemId = feedItem.id;

  const saveItem = () => {
    dispatch(toggleSavedItem(itemId));
    dispatch(toggleSavedItemOnSearch(itemId));

    //Needs refactored to send result instead of document.
    //dispatch(addToSavedJobsList(feedItem));
  };

  return (
    <>
      {feedItem.saved ? (
        <button
          className="btn btn-transparent p-0"
          type="button"
          onClick={() => {
            saveItem();
          }}
        >
          <i className="fi-heart text-danger fs-2"></i>
        </button>
      ) : (
        <button
          className="btn btn-transparent p-0"
          type="button"
          onClick={() => {
            saveItem();
          }}
        >
          <i className="fi-heart fs-2"></i>
        </button>
      )}
    </>
  );
};
