import React, { useEffect } from "react";
import { DraftEditor } from "../../../../common/DraftEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../../../contexts/AuthContext";

export const RecruiterBasicInfoForm = ({
  recruiter,
  onSave,
  onChange,
  editorState,
  onPitchChange,
  saving = false,
  errors = {},
  currentTextLength,
  maxCharacters,
  update,
}) => {
  return (
    <form onSubmit={onSave}>
      <div className="bg-light rounded-3 mb-3">
        <h2 className="h4 mb-4">
          <i className="fi-user text-primary fs-5 mt-n1 me-2"></i>
          {recruiter.id ? "Update" : "Create"} Profile
        </h2>

        {recruiter.id && <input type="hidden" name="id" value={recruiter.id} />}

        <div className="row">
          <div className="col-sm-6 mb-4">
            <label className="form-label" htmlFor="firstName">
              First name <span className="text-danger">*</span>
            </label>
            <input
              className="form-control form-control-lg"
              type="text"
              name="firstName"
              id="firstName"
              value={recruiter.firstName}
              placeholder="Enter your first name"
              onChange={onChange}
              disabled
            />
            {errors.firstName && (
              <p className="text-danger">{errors.firstName}</p>
            )}
          </div>
          <div className="col-sm-6 mb-4">
            <label className="form-label" htmlFor="lastName">
              Second name <span className="text-danger">*</span>
            </label>
            <input
              className="form-control form-control-lg"
              type="text"
              name="lastName"
              id="lastName"
              value={recruiter.lastName}
              placeholder="Enter your second name"
              onChange={onChange}
              disabled
            />
            {errors.lastName && (
              <p className="text-danger">{errors.lastName}</p>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 mb-4">
            <label className="form-label" htmlFor="title">
              Job Title <span className="text-danger">*</span>
            </label>
            <input
              className="form-control form-control-lg"
              type="text"
              name="title"
              id="title"
              value={recruiter.title}
              placeholder="Enter your job title"
              onChange={onChange}
            />
            {errors.jobTitle && (
              <p className="text-danger">{errors.firstTitle}</p>
            )}
          </div>
          <div className="col-sm-6 mb-4">
            <label className="form-label" htmlFor="employer">
              Employer <span className="text-danger">*</span>
            </label>
            <input
              className="form-control form-control-lg"
              type="text"
              name="employer"
              id="employer"
              value={recruiter.employer}
              placeholder="Enter your current employer"
              onChange={onChange}
            />
            {errors.employer && (
              <p className="text-danger">{errors.employer}</p>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 mb-4">
            <label className="form-label" htmlFor="telephone">
              Telephone <span className="text-danger"></span>
            </label>
            <input
              className="form-control form-control-lg"
              type="text"
              name="telephone"
              id="telephone"
              value={recruiter.telephone}
              placeholder="Enter your phone number"
              onChange={onChange}
            />
          </div>

          <div className="col-sm-6 mb-4">
            <label className="form-label" htmlFor="employerType">
              Employer Type
            </label>
            <select
              className="form-control form-control-lg"
              name="type"
              id="employerType"
              value={recruiter.type}
              onChange={onChange}
            >
              <option value="HiringManager">Hiring Manager</option>
              <option value="InternalRecruiter">Internal Recruiter / HR</option>
              <option value="AgencyRecruiter">Agency Recruiter</option>
            </select>
          </div>
        </div>

        <div className="pb-4 mb-2">
          <label className="form-label fw-bold mb-3" htmlFor="pitch">
            Pitch / Summary
          </label>
          <div className="d-flex align-items-center mb-3">
            <DraftEditor
              editorState={editorState}
              handleEditorChange={onPitchChange}
              placeholder={
                <>
                  Describe why people should engage with you and / or the
                  business you represent. <br />
                  This is the information candidates will see about you on their
                  feed if you are matched with them.
                </>
              }
            />
          </div>
          <p className="d-flex justify-content-end">
            <em>
              {maxCharacters - currentTextLength} / {maxCharacters} remaining
            </em>
          </p>
        </div>

        <div className="pb-4 mb-2">
          <div className="form-label fw-bold mb-3">Socials</div>

          <div className="d-flex align-items-center mb-3">
            <div className="btn btn-icon btn-light btn-xs shadow-sm rounded-circle pe-none flex-shrink-0 me-3">
              <i className="fi-linkedin text-body"></i>
            </div>
            <label htmlFor="linkedIn" />
            <input
              className="form-control"
              type="text"
              placeholder="Your LinkedIn account"
              name="linkedIn"
              id="linkedIn"
              value={recruiter.linkedIn}
              onChange={onChange}
            />
          </div>
          <div className="d-flex align-items-center mb-3">
            <div className="btn btn-icon btn-light btn-xs shadow-sm rounded-circle pe-none flex-shrink-0 me-3">
              <FontAwesomeIcon icon="fa-brands fa-x-twitter text-body" />
            </div>
            <label htmlFor="twitter" />

            <input
              className="form-control"
              type="text"
              placeholder="Your Twitter account"
              name="twitter"
              value={recruiter.twitter}
              onChange={onChange}
            />
          </div>
          <div className="d-flex align-items-center mb-3">
            <div className="btn btn-icon btn-light btn-xs shadow-sm rounded-circle pe-none flex-shrink-0 me-3">
              <i className="fi-globe text-body"></i>
            </div>
            <label htmlFor="webSiteUrl" />

            <input
              className="form-control"
              type="text"
              placeholder="Your Company Website"
              name="webUrl"
              id="webSiteUrl"
              value={recruiter.webUrl}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-column flex-sm-row bg-light rounded-3">
        {update ? (
          <input
            type="submit"
            className="btn btn-primary btn-lg rounded-pill ms-sm-auto"
            value={saving ? "Updating..." : "Update"}
            disabled={saving}
          />
        ) : (
          <input
            type="submit"
            className="btn btn-success btn-lg rounded-pill ms-sm-auto"
            value={saving ? "Saving..." : "Save"}
            disabled={saving}
          />
        )}
      </div>
    </form>
  );
};
