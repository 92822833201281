import React from "react";
import { IconCard } from "../../../common/IconCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ProfilingSystemEmployers = () => {
  return (
    <>
      <div className="container pb-5 pt-5 mb-md-5">
        <div className="card border-0 bg-faded-success p-1">
          <div className="card-body">
            <h2 className="mb-4 pb-md-2 text-center text-primary">
              Skills based sourcing and inbound applications
            </h2>{" "}
            <p className="mb-4 pb-2 mx-auto text-center text-primary">
              Our proprietory profiling system uses a combination of AI and
              human consultancy to distribute your organisation, opportunities,
              recruiters, hiring managers and outreach messages to the most
              relevant of pre-screened approved professionals.
            </p>
            <div className="row row-cols-lg-4 mt-4 row-cols-md-3 row-cols-sm-1 row-cols-1 g-4 justify-content-center">
              <IconCard
                icon="fi-briefcase"
                iconClass="bg-faded-accent"
                textClass="text-accent"
                title="Positions"
                description="Target professionals based on their level of experience / position aspirations."
              />

              <IconCard
                icon="fi-building"
                iconClass="bg-faded-accent"
                textClass="text-accent"
                title="Firm Types"
                description="Are you a start up or scale up?  Perhaps you are targeting professionals who are only interested in 
                consulting firms.  Ensure you are targeting professionals who are interested in organisations like yours."
              />

              <IconCard
                icon="fi-award"
                iconClass="bg-faded-success"
                textClass="text-success"
                title="Qualifications"
                description="Do the roles you hire require certain qualifications?  
                Zone in on only professionals who meet these criteria."
              />

              <IconCard
                icon="fi-settings"
                iconClass="bg-faded-success"
                textClass="text-success"
                title="Skills"
                description="Skills based sourcing leads to skills based hiring.  Our technology enables distribution to 
                professionals based on their skills.  Target candidates with 'all of' and 'any of' the skills you require."
              />

              <IconCard
                icon="fi-globe"
                iconClass="bg-faded-warning"
                textClass="text-warning"
                title="Locations"
                description="Your feed is set to only return opportunities in particular cities and countries.  You can also set remote / hybrid preferences
                 based on particular countries or regions."
              />

              <IconCard
                icon="fi-grid"
                iconClass="bg-faded-danger"
                textClass="text-danger"
                title="Divisions"
                description="There is a big difference between specialising in java in the talent division and the engineering division.  Ensure
                your feed is set up to only return relevant results by providing divisions of interest."
              />

              <IconCard
                icon="fi-education"
                iconClass="bg-faded-danger"
                textClass="text-danger"
                title="Education"
                description="Are the roles you seek likely to require certain levels of education?  We aim to ensure you only receive roles you 
                are qualified to fulfil."
              />

              <IconCard
                icon="fi-cash"
                iconClass="bg-faded-danger"
                textClass="text-danger"
                title="Compensation"
                description="Ensure your roles are only visible to professionals whose expectactions are in line with your salary bands."
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
