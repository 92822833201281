import { StaticTagsPage } from "../recruiter/OnboardStaticTagsPage";

export const UpdateRecruiterEducationsPageWrapper = (props) => {
  return (
    <StaticTagsPage
      tagType={"Education"}
      profileType={"recruiter"}
      tagColor={"warning"}
      text={
        <>
          Do you only hire candidates who have attained certain levels of
          education?
        </>
      }
      {...props}
      update={true}
    />
  );
};
