import { OnboardStaticTagsPage } from "../OnboardStaticTagsPage";

export const UpdateOfficesPageWrapper = (props) => {
  return (
    <OnboardStaticTagsPage
      tagType={"OfficePreference"}
      lead={"What are your working type preferences? "}
      tagColor={"secondary"}
      {...props}
      update={true}
    />
  );
};
