import React, { useState } from "react";
import { Link } from "react-router-dom";

import { NavLink, Tooltip } from "reactstrap";

export const NavMenuCandidateLinks = ({}) => {
  const [feedTooltipOpen, setFeedTooltipOpen] = useState(false);
  const [profileTooltipOpen, setProfileTooltipOpen] = useState(false);
  const [searchTooltipOpen, setSearchTooltipOpen] = useState(false);
  const [settingsTooltipOpen, setSettingsTooltipOpen] = useState(false);
  const [savedTooltipOpen, setSavedTooltipOpen] = useState(false);
  const [matchesTooltipOpen, setMatchesTooltipOpen] = useState(false);

  return (
    <>
      {/* <NavLink
        tag={Link}
        className="text-light"
        id="settingsButton"
        to="/settings"
      >
        <i className="fi-settings" />
        <Tooltip
          isOpen={settingsTooltipOpen}
          placement="bottom"
          target="settingsButton"
          toggle={() => {
            setSettingsTooltipOpen(!settingsTooltipOpen);
          }}
        >
          Account Settings
        </Tooltip>
      </NavLink> */}
      <NavLink
        tag={Link}
        className="text-light"
        id="searchButton"
        to="/search/jobs"
      >
        <i className="fi-search" />
        <Tooltip
          isOpen={searchTooltipOpen}
          placement="bottom"
          target="searchButton"
          toggle={() => {
            setSearchTooltipOpen(!searchTooltipOpen);
          }}
        >
          Search
        </Tooltip>
      </NavLink>

      <NavLink tag={Link} className="text-light" id="savedButton" to="/saved">
        <i className="fi-heart" />
        <Tooltip
          isOpen={savedTooltipOpen}
          placement="bottom"
          target="savedButton"
          toggle={() => {
            setSavedTooltipOpen(!savedTooltipOpen);
          }}
        >
          Saved
        </Tooltip>
      </NavLink>

      <NavLink tag={Link} className="text-light" id="feedButton" to="/feed">
        <i className="fi-list" />
        <Tooltip
          isOpen={feedTooltipOpen}
          placement="bottom"
          target="feedButton"
          toggle={() => {
            setFeedTooltipOpen(!feedTooltipOpen);
          }}
        >
          Feed
        </Tooltip>
      </NavLink>

      <NavLink
        tag={Link}
        className="text-light"
        id="matchesButton"
        to="/matches"
      >
        <i className="fi-mail" />
        <Tooltip
          isOpen={matchesTooltipOpen}
          placement="bottom"
          target="matchesButton"
          toggle={() => {
            setMatchesTooltipOpen(!matchesTooltipOpen);
          }}
        >
          Matches
        </Tooltip>
      </NavLink>

      <NavLink
        tag={Link}
        className="text-light"
        id="profileButton"
        to="/settings"
      >
        <i className="fi-user" />
        <Tooltip
          isOpen={profileTooltipOpen}
          placement="bottom"
          target="profileButton"
          toggle={() => {
            setProfileTooltipOpen(!profileTooltipOpen);
          }}
        >
          Profile
        </Tooltip>
      </NavLink>
    </>
  );
};
