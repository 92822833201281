import React, { useState, useEffect } from "react";
import { TimeAgo } from "./TimeAgo";
import { CityBadge } from "./CityBadge";
import { Link } from "react-router-dom";

import { HTMLSubstring } from "./HTMLSubstring";
import { DivisionBadgeList } from "./DivisionBadgeList";
import { BadgeList } from "./BadgeList";
import { SaveFeedItemButton } from "./SaveFeedItemButton";
import { RemoveSavedItemButton } from "./RemoveSavedItemButton";
import { UniqueCitiesBadges } from "./UniqueCitiesBadges";

export const JobCard = ({ job, saved, handleSelectJob }) => {
  const isSaved = saved ? true : false;

  return (
    <>
      <div className="card card-bordered mb-2">
        <div className="card-body p-3">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-row align-items-center">
              {job.logo && (
                <img
                  className="avatar avatar-sm avatar-4x3"
                  src={job.logo.location}
                  alt="logo"
                />
              )}
              <div className="ms-2 c-details">
                <h6 className="mb-0">{job.company}</h6>{" "}
                <p className="small">
                  <TimeAgo timestamp={job.created} />
                </p>
              </div>
            </div>
            {!isSaved ? (
              <SaveFeedItemButton feedItem={job} />
            ) : (
              <RemoveSavedItemButton feedItem={job} />
            )}
          </div>
          <div className="mt-2">
            <p className="card-title text-lead">
              <strong>
                <a
                  className="text-primary"
                  href={job.extUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {job.title}
                </a>
              </strong>
            </p>
            <hr className="mb-3" />
            {job.pitch ? (
              <>
                <p className="fs-6">{job.pitch}</p>
                <hr className="mb-3" />
              </>
            ) : (
              <>
                <HTMLSubstring text={job.description} maxLength={300} />
                <hr className="mb-3 mt-3" />
              </>
            )}

            <div className="d-flex flex-wrap">
              {job.profile && job.profile.sectors && (
                <DivisionBadgeList divisions={job.profile.sectors} />
              )}
            </div>

            <div className="d-flex flex-wrap">
              {job.profile && job.profile.firmTypes && (
                <BadgeList tags={job.profile.firmTypes} badgeClass="danger" />
              )}
            </div>
            <div className="d-flex flex-wrap">
              {job.profile && job.profile.industries && (
                <BadgeList tags={job.profile.industries} badgeClass="info" />
              )}
            </div>

            <div className="d-flex flex-wrap">
              {job.profile && job.profile.cities && (
                <UniqueCitiesBadges cities={job.profile.cities} />
              )}
            </div>

            {/* <div className="mt-3">
              {" "}
              <span className="text1">
                32 Applied <span className="text2">of 50 capacity</span>
              </span>{" "}
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
