import React from "react";
import { Route } from "react-router";
import { ContactPage } from "../components/pages/home/contact/ContactPage";

import JobSeekersHowItWorksPage from "../components/pages/home/job-seekers/JobSeekersHowItWorks";

export const JobSeekerHomePageRoutes = () => {
  return (
    <>
      <Route
        path="/job-seekers/how-it-works"
        component={JobSeekersHowItWorksPage}
      />

      <Route path="/contact" component={ContactPage} />
    </>
  );
};
