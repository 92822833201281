import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { PrevNextBtnLgRound } from "../PrevNextButtonLgRound";
import { MultiTagSelect } from "../../../../common/MultiTagSelect";

import * as remoteClient from "../../../../../dataServices/remoteClient";

import { toast } from "react-toastify";

import { InfoAlert } from "../../../../common/InfoAlert";
import { CandidateAllAnySkillsDescription } from "./CandidateAllAnySkillsDescription";

import {
  fetchUser,
  saveProfileMultiTags,
} from "../../../../../features/user/userSlice";
import { CenteredSingleColumnLayout } from "../../../../common/CenteredSingleColumnLayout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/free-solid-svg-icons";

export const OnboardSkillsPage = ({ update }) => {
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();

  const [selectedOptions, setSelectedOptions] = useState({
    allTags: [],
    anyTags: [],
    allTouched: false,
    anyTouched: false,
  });

  //const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);

  let history = useHistory();

  const getSkills = async (input) => {
    return await remoteClient.get(
      `/api/tags/select/skills?searchTerm=${input}`
    );
  };

  useEffect(() => {
    if (user === null) {
      dispatch(fetchUser());
    }
  }, [user, dispatch]);

  const handleAnySkillsInput = (newValue) => {
    setSelectedOptions({
      ...selectedOptions,
      anyTags: newValue,
      anyTouched: true,
    });
  };

  const handleAllSkillsInput = (newValue) => {
    setSelectedOptions({
      ...selectedOptions,
      allTags: newValue,
      allTouched: true,
    });
  };

  const handleSaveSkills = () => {
    if (!selectedOptions.allTouched && !selectedOptions.anyTouched) {
      if (update) {
        history.push("/matches");
      } else {
        history.push("qualifications");
      }
    } else {
      setSaving(true);
      let tagType = "skill";
      dispatch(saveProfileMultiTags({ user, tagType, selectedOptions }))
        .then(() => {
          toast.success(`Skills saved`);
          if (update) {
            history.push("/matches");
          } else {
            history.push("qualifications");
          }
        })
        .catch((error) => {
          setSaving(false);
          // setErrors({ onSave: error.message });
          toast.error(`Saving tags failed: ${error.message}`);
        });
    }
  };

  return (
    <>
      <CenteredSingleColumnLayout>
        <InfoAlert
          lead="Use Any and All to refine the roles / messages you receive."
          text={
            <>
              You can create your own tags here and add anything you like. Using
              lots of ANY tags will return less relevant results. If the roles
              you are interested in are likely to have 2-3 required skills add
              them to the All section.
              <CandidateAllAnySkillsDescription />
            </>
          }
        />

        <h2 className="h4 mb-4">
          <FontAwesomeIcon
            icon={faBrain}
            className="opacity-60 fs-5 mt-n1 me-2 pe-1"
          />
          Any Skill Tags
        </h2>

        {user && user.profile && user.profile.tagsForSelect ? (
          <MultiTagSelect
            loadOptions={getSkills}
            placeholder="Start typing skills"
            onChange={handleAnySkillsInput}
            defaultValue={user.profile.tagsForSelect.filter(
              (t) => t.type === "Skill" && t.profileTagType === "Any"
            )}
            creatable={true}
          />
        ) : (
          <></>
        )}

        <div className="border-top py-4 mb-md-2">
          <h2 className="h4 mb-4">
            <FontAwesomeIcon
              icon={faBrain}
              className="opacity-60 fs-5 mt-n1 me-2 pe-1"
            />
            All Skill Tags
          </h2>

          {user && user.profile && user.profile.tagsForSelect ? (
            <MultiTagSelect
              loadOptions={getSkills}
              placeholder="Start typing skills"
              onChange={handleAllSkillsInput}
              defaultValue={user.profile.tagsForSelect.filter(
                (t) => t.type === "Skill" && t.profileTagType === "All"
              )}
              creatable={true}
            />
          ) : (
            <></>
          )}
        </div>
        <PrevNextBtnLgRound
          nextTitle={update ? "Update" : "Qualifications"}
          prevTitle={"Industries"}
          prevHref={update ? "" : "industries"}
          saving={saving}
          saveTags={handleSaveSkills}
          update={update}
        />
      </CenteredSingleColumnLayout>{" "}
    </>
  );
};
