import React from "react";
import { Redirect } from "react-router-dom";
import { useClaims } from "../../contexts/ClaimsContext";
import { useAuth } from "../../contexts/AuthContext";

export const RedirectComponent = () => {
  const { profileComplete, recruiterProfileComplete } = useClaims();
  const { isCandidate, isEmployer } = useAuth();

  return (
    <>
      {isCandidate &&
        (profileComplete ? (
          <Redirect to="/feed" />
        ) : (
          <Redirect to="/onboard/basic-info" />
        ))}

      {isEmployer &&
        (recruiterProfileComplete ? (
          <Redirect to="/recruiter/dashboard" />
        ) : (
          <Redirect to="/onboard/recruiter/basic-info" />
        ))}
    </>
  );
};
