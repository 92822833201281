import React from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import AsyncSelect from "react-select/async";
import "./MultiTagSelect.css";

export const MultiTagSelect = ({
  defaultValue,
  loadOptions,
  placeholder,
  onChange,
  creatable,
  color,
}) => {
  const backgroundColor = color ? color : "#0b6e4f";
  const customStyles = {
    control: (_, { selectProps: { width } }) => ({
      width: width,
      color: "#fff !important",
    }),
    multiValue: (base) => ({
      ...base,

      backgroundColor: `${backgroundColor}`,
      color: "#fff !important",
      fontSize: "100% !important",
      fontWeight: "bold",
      borderRadius: "50rem",
      padding: "0.375rem 0.875rem",
    }),
    valueContainer: (base) => ({
      ...base,
      color: "#fff",
    }),
  };

  return (
    <>
      {creatable ? (
        <AsyncCreatableSelect
          styles={customStyles}
          cacheOptions
          defaultOptions
          loadOptions={loadOptions}
          placeholder={placeholder}
          onChange={onChange}
          isMulti
          defaultValue={defaultValue}
        />
      ) : (
        <AsyncSelect
          styles={customStyles}
          cacheOptions
          defaultOptions
          loadOptions={loadOptions}
          placeholder={placeholder}
          onChange={onChange}
          isMulti
          defaultValue={defaultValue}
        />
      )}
    </>
  );
};
