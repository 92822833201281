import React from "react";

export const CardHTML = ({ text }) => {
  if (!text) {
    // Handle the case where text is not provided or is undefined
    return null;
  }

  const convertLinksToAnchors = (text) => {
    // Regular expression to match URLs
    // Regular expression to match URLs with various schemes
    const urlRegex = /((http|https|ftp):\/\/[^\s/$.?#].[^\s]*)/g;

    return text.replace(urlRegex, (url) => {
      // Remove non-breaking spaces and other unwanted characters
      url = url.replace(/&nbsp;|<\/?[^>]+(>|$)/g, "");
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  };

  if (text.startsWith("<p>&nbsp;</p>")) {
    text = text.slice(13); // Remove the first 13 characters
  }

  text = convertLinksToAnchors(text);

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </>
  );
};
