import React from "react";

import { Route } from "react-router";
import AuthorizeRoute from "./auth/AuthorizeRoute";

import { Home } from "./components/Home";
import Layout from "./components/Layout";
import UserSession from "./components/UserSession";

import { AuthProvider } from "./contexts/AuthContext";
import { ClaimsProvider } from "./contexts/ClaimsContext";

import "./custom.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faCheckSquare,
  faCoffee,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import PayingUserRoute from "./auth/PayingUserRoute";
import AddJobPage from "./components/pages/jobs/AddJobPage";

// import SearchPage from "./components/pages/search/SearchPage";

//From Redux Tutorial
import { PostsList } from "./features/posts/PostsList";
import { SinglePostPage } from "./features/posts/SinglePostPage";
import { EditPostForm } from "./features/posts/EditPostForm";

//import { UserPage } from "./features/users/UserPage";
import { NotificationsList } from "./features/notifications/NotificationsList";

//Onboard router
// End from Redux Tutorial

//From React Tutorial
import { TagsList } from "./features/tags/TagsList";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// End React Tutorial

import { OnboardUserRoutes } from "./routes/OnboardUserRoutes";
import { OnboardRecruiterRoutes } from "./routes/OnboardRecruiterRoutes";
import { CandidateFeed } from "./components/pages/feed/CandidateFeed";
import { JobSearchPage } from "./components/pages/search/JobSearchPage";
import AboutPage from "./components/pages/about/AboutPage";
import { JobSeekerHomePageRoutes } from "./routes/JobSeekerHomePageRoutes";

import { PrivacyPage } from "./components/pages/home/privacy/PrivacyPage";
import { EmployerHomePageRoutes } from "./routes/EmployerHomePageRoutes";
import { EmployerRoutes } from "./routes/EmployerRoutes";

import { SavedPage } from "./components/pages/saved/SavedPage";
import { Settings } from "./components/pages/settings/Settings";
import { OnboardRecruiterPostRoutes } from "./routes/OnboardRecruiterPostRoutes";
import { CandidateRoutes } from "./routes/CandidateRoutes";
import MessagesPage from "./components/pages/messages/MessagesPage";
import { SlackStyleTestPage } from "./components/pages/messages/SlackStyleTestPage";

import { EditProfileLinks } from "./components/pages/settings/EditProfileLinks";

library.add(fab, faCheckSquare, faCoffee, faMessage);

const App = () => {
  return (
    <AuthProvider>
      <ClaimsProvider>
        <Layout>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/privacy" component={PrivacyPage} />

          <AuthorizeRoute path="/user-session" component={UserSession} />

          <OnboardUserRoutes />

          <OnboardRecruiterRoutes />

          <JobSeekerHomePageRoutes />

          <EmployerHomePageRoutes />

          <OnboardRecruiterPostRoutes />

          <EmployerRoutes />

          <CandidateRoutes />

          <AuthorizeRoute path="/feed" component={CandidateFeed} />

          <AuthorizeRoute path="/search/jobs" component={JobSearchPage} />

          <AuthorizeRoute path="/saved" component={SavedPage} />

          <AuthorizeRoute path="/settings" component={Settings} />

          <AuthorizeRoute path="/update/profile" component={EditProfileLinks} />

          <ToastContainer theme="light" position="bottom-left" />
        </Layout>
      </ClaimsProvider>
    </AuthProvider>
  );
};

export default App;
