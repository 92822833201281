import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { InfoAlert } from "../../common/InfoAlert";

import {
  fetchMatches,
  selectMatchIds,
  rejectMatch,
} from "../../../features/matches/matchesSlice";

import { CenteredSingleColumnLayout } from "../../common/CenteredSingleColumnLayout";

import { MatchCard } from "./MatchCard";
import { Spinner } from "../../common/Spinner";

export const MatchesPage = () => {
  const dispatch = useDispatch();
  const matchStatus = useSelector((state) => state.matches.status);
  const orderedMatchIds = useSelector(selectMatchIds);

  useEffect(() => {
    if (matchStatus === "idle") {
      dispatch(fetchMatches());
    }
  }, [matchStatus, dispatch]);

  return (
    <>
      {matchStatus === "loading" ? (
        <Spinner text="Loading matches..." />
      ) : (
        <>
          <CenteredSingleColumnLayout>
            <InfoAlert
              lead={
                <>
                  These messages are delivered to you based on your skills
                  profile.
                </>
              }
              text="When you engage with these posts your profile will become 
              visibile to the poster and their business.  While you can't see the 
              salary banding it is matched with the numbers you provided.
              Please advise us if the salary turns out not to be aligned 
              with your expectations or the skills posted aren't relevant to the 
              role being advertised."
            />

            {!orderedMatchIds ||
              (orderedMatchIds.length === 0 && (
                <InfoAlert
                  lead="No current exact matches."
                  text={
                    <>
                      <br></br>
                      <Link className="btn btn-link" to="/feed">
                        Check out recommendations
                      </Link>
                      or
                      <Link className="btn btn-link" to="/search/jobs">
                        Search Jobs
                      </Link>
                    </>
                  }
                />
              ))}

            {orderedMatchIds &&
              orderedMatchIds.map((matchId) => (
                <MatchCard key={matchId} matchId={matchId} />
              ))}
          </CenteredSingleColumnLayout>
        </>
      )}
    </>
  );
};
