import { PostStaticTagsPage } from "./PostStaticTagsPage";

export const PostPositionTagsPageWrapper = ({ match, ...props }) => {
  const { postId, profileId } = match.params;
  return (
    <PostStaticTagsPage
      tagType={"Position"}
      postId={postId}
      profileId={profileId}
      lead={<>What positions is this post relevant to?</>}
      {...props}
    />
  );
};
