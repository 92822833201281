import React from "react";
import NewsLetterSignUp from "./NewsLetterSignUp";

const AboutPage = () => (
  <>
    <section className="position-relative bg-white rounded-xxl-4 mb-5 pb-1 py-md-3 zindex-5 mt-5">
      <div className="container pt-5 mb-4 mb-md-5">
        <h1 className="display-4 mb-4 pb-md-2 text-center">
          We <i className="fi-heart fs-1 text-primary"></i> to source
        </h1>{" "}
        {/* <h2 className="text-center">About Us</h2>
        <h6 className="text-center">Recruitment is broken! </h6> */}
        <p className="mb-4 pb-2 mx-auto fs-lg text-center">
          sourcers exists to overcome the issues facing candidates and employers
          in the early stages of the hiring process. Candidates need a private
          platform where they can access highly relevant roles without
          publishing their intent to move from their current role. Using a mix
          of AI and human consulting we provide passive candidates with a wide
          variety of exceptional opportunities from leading employers and enable
          direct communication with hiring managers and recruiters.
        </p>
        <p className="mb-4 pb-2 mx-auto fs-lg text-center"></p>
        <div className="row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 g-4 justify-content-center">
          <div className="col">
            <div className="card border-0 shadow-sm card-hover h-100">
              <div className="card-body icon-box">
                <div className="icon-box-media bg-faded-accent text-accent rounded-circle mb-3">
                  <i className="fi-lock"></i>
                </div>
                <h3 className="h5 card-title">Privacy</h3>
                <p className="card-text fs-sm">
                  A space for candidates to receive relevant feeds of jobs,
                  hiring managers, recruiters and companies without revealing
                  their profile.
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card border-0 shadow-sm card-hover h-100">
              <div className="card-body icon-box">
                <div className="icon-box-media bg-faded-info text-info rounded-circle mb-3">
                  <i className="fi-geo"></i>
                </div>
                <h3 className="h5 card-title">Relevance</h3>
                <p className="card-text fs-sm">
                  Our profiling system ensures only highly relevant content is
                  received in candidate feeds, which in turn improves the
                  quality of applications received / hires made.
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card border-0 shadow-sm card-hover h-100">
              <div className="card-body icon-box">
                <div className="icon-box-media bg-faded-primary text-primary rounded-circle mb-3">
                  <i className="fi-heart"></i>
                </div>
                <h3 className="h5 card-title">Empathy</h3>
                <p className="card-text fs-sm">
                  While we hire everyday, we understand that candidates have
                  often been planning for our engagement for days or weeks. We
                  provide our service with empathy and understand the importance
                  of our transactions four our candidates and employers brands.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card border-0 shadow-sm card-hover h-100">
              <div className="card-body icon-box">
                <div className="icon-box-media bg-faded-success text-success rounded-circle mb-3">
                  <i className="fi-users"></i>
                </div>
                <h3 className="h5 card-title">Data-driven</h3>
                <p className="card-text fs-sm">
                  We set targets with employers to ensure we are continually
                  delivering the numbers required reach your hiring goals.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card border-0 shadow-sm card-hover h-100">
              <div className="card-body icon-box">
                <div className="icon-box-media bg-faded-primary text-primary rounded-circle mb-3">
                  <i className="fi-like"></i>
                </div>
                <h3 className="h5 card-title">Transparency</h3>
                <p className="card-text fs-sm">
                  Our platform provides process transparency to all stakeholders
                  in the hiring process including candidates, hiring managers,
                  recruiters and leaders.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card border-0 shadow-sm card-hover h-100">
              <div className="card-body icon-box">
                <div className="icon-box-media bg-faded-success text-success rounded-circle mb-3">
                  <i className="fi-chat-left"></i>
                </div>
                <h3 className="h5 card-title">Communication</h3>
                <p className="card-text fs-sm">
                  We provide multi-stakeholder communication for candidates and
                  employers to keep candidate experience scores high.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card border-0 shadow-sm card-hover h-100">
              <div className="card-body icon-box">
                <div className="icon-box-media bg-faded-warning text-warning rounded-circle mb-3">
                  <i className="fi-cash"></i>
                </div>
                <h3 className="h5 card-title">Fair price</h3>
                <p className="card-text fs-sm">
                  We provide our services on a sensible pricing scale and offer
                  subsription and success based models.
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card border-0 shadow-sm card-hover h-100">
              <div className="card-body icon-box">
                <div className="icon-box-media bg-faded-warning text-warning rounded-circle mb-3">
                  <i className="fi-checkbox-checked-alt"></i>
                </div>
                <h3 className="h5 card-title">Simple solutions</h3>
                <p className="card-text fs-sm">
                  We aim to provide a simple and effective user experience to
                  make sourcing candidates or your next role as easy as
                  possible.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="container mb-5 pb-2 pb-lg-5">
      <h2 className="mb-4 pb-2 text-center">Global Reach</h2>
      <p className="mb-4 pb-2 mx-auto fs-lg text-center">
        Our talent community is truly global and diverse. We have sourced from
        San Francisco to Sydney and believe in the benefits of hiring a truly
        diverse team.
      </p>
      <img
        src="https://busoca.blob.core.windows.net/images/map.jpg"
        alt="World map"
      />
    </section>

    <NewsLetterSignUp newsletterType="company" />
  </>
);

export default AboutPage;
