import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { Facets } from "../../common/search/FacetsComponent";
import { ClearFilters } from "../../common/search/ClearFiltersComponents";

import {
  fetchFeed,
  updateFeed,
  selectFeedItemIds,
  setSearchDtoSince,
  setSelectedCheckboxes,
  updateFirmTypes,
  updateIndustries,
  updateLocations,
  updateSectors,
  clearFilters,
} from "../../../features/feeds/candidateFeedSlice";

import { resetProfileTouched } from "../../../features/user/userSlice";
import { Spinner } from "../../../components/common/Spinner";
import { JobFeedCard } from "./JobFeedCard";
import { ShowSinceComponent } from "../../common/search/ShowSince";
import { FullPageExternalJobModal } from "../../common/FullPageExternalJobModal";
import { InfoAlert } from "../../common/InfoAlert";

export const CandidateFeed = () => {
  const dispatch = useDispatch();
  const orderedFeedIds = useSelector(selectFeedItemIds);
  const location = useLocation();

  const feedStatus = useSelector((state) => state.feed.status);
  const updateStatus = useSelector((state) => state.feed.updateStatus);

  const [selectedJob, setSelectedJob] = useState({});

  const error = useSelector((state) => state.feed.error);

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const profileTouched = useSelector((state) => state.user.profileTouched);
  const totalCount = useSelector((state) => state.feed.totalCount);

  const searchDto = useSelector((state) => state.feed.searchDto);
  const selectedCheckboxes = useSelector(
    (state) => state.feed.selectedCheckboxes
  );

  const firstFetchComplete = useSelector(
    (state) => state.feed.firstFetchComplete
  );

  const previousLocation = useRef(location.pathname);

  useEffect(() => {
    if (initialLoadComplete) {
      dispatch(updateFeed(searchDto));
    }
  }, [searchDto, dispatch]);

  const handleSelectJob = ({ job }) => {
    setSelectedJob(job);
  };

  useEffect(() => {
    if (
      initialLoadComplete &&
      !firstFetchComplete &&
      feedStatus === "idle" &&
      searchDto &&
      searchDto.currentPage !== null
    ) {
      dispatch(fetchFeed(searchDto));
    }
  }, [
    feedStatus,
    dispatch,
    searchDto,
    initialLoadComplete,
    firstFetchComplete,
  ]);

  useEffect(() => {
    // Check if the location changes, indicating a navigation event
    if (location.pathname !== previousLocation.current) {
      // Reset the initial load flag
      setInitialLoadComplete(false);
      // Store the new location in the ref
      previousLocation.current = location.pathname;
    } else {
      // Set the initial load to true when the component mounts
      setInitialLoadComplete(true);
    }
  }, [location]);

  useEffect(() => {
    if (profileTouched) {
      dispatch(updateFeed(searchDto));
    }

    dispatch(resetProfileTouched());
  }, [profileTouched, dispatch]);

  const handleSetSince = (value) => {
    dispatch(setSearchDtoSince(value));
  };

  const facets = useSelector((state) => state.feed.facets);

  return (
    <>
      <div className="mt-5 row justify-content-center pb-sm-2">
        <div className="mt-5 col-lg-9 col-xl-8">
          {feedStatus === "loading" ? (
            <Spinner text="Loading your personalised feed..." />
          ) : feedStatus === "succeeded" ? (
            <>
              <div className="col-md-9">
                <InfoAlert
                  lead={
                    <>
                      These are recommendations based on the profile provided.
                    </>
                  }
                  text={
                    <>
                      These positions are collated from a variety of sources.
                      You will be redirected to the source of vacancy to make an
                      application.{" "}
                      <Link className="btn-link" to="onboard/positions">
                        Optimise your recommendations by managing / editing your
                        skills profile{" "}
                      </Link>
                    </>
                  }
                />
              </div>

              <p className="mt-5">
                <strong>
                  <em>{totalCount} jobs based on your profile</em>
                </strong>
              </p>
              <div className="row">
                <div className="col-md-9 pr-2">
                  <ShowSinceComponent
                    totalCount={totalCount}
                    handleSetSince={handleSetSince}
                    selectedValue={searchDto.since}
                  />

                  <Facets
                    facets={facets}
                    selectedCheckboxes={selectedCheckboxes}
                    handleSelectedCheckboxes={setSelectedCheckboxes}
                    handleUpdateIndustries={updateIndustries}
                    handleUpdateSectors={updateSectors}
                    handleUpdateLocations={updateLocations}
                    handleUpdateFirmTypes={updateFirmTypes}
                    handleClearFilters={clearFilters}
                    isMobile
                  />

                  {updateStatus === "succeeded" ? (
                    <>
                      {orderedFeedIds.map((feedId) => {
                        return (
                          <JobFeedCard
                            key={feedId}
                            feedId={feedId}
                            handleSelectJob={handleSelectJob}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <div className="mt-3">
                      <Spinner text="Updating feed..." />
                    </div>
                  )}
                </div>
                <div className="d-none d-md-block col-md-3">
                  <Facets
                    facets={facets}
                    selectedCheckboxes={selectedCheckboxes}
                    handleSelectedCheckboxes={setSelectedCheckboxes}
                    handleUpdateIndustries={updateIndustries}
                    handleUpdateSectors={updateSectors}
                    handleUpdateLocations={updateLocations}
                    handleUpdateFirmTypes={updateFirmTypes}
                    handleClearFilters={clearFilters}
                  />
                </div>
              </div>

              <FullPageExternalJobModal
                title={selectedJob.title}
                extUrl={selectedJob.extUrl}
              />
            </>
          ) : (
            <div>{error}</div>
          )}
        </div>
      </div>
    </>
  );
};
