import React from "react";

export const HTMLSubstring = ({ text, maxLength }) => {
  if (!text) {
    // Handle the case where text is not provided or is undefined
    return null;
  }

  const truncateToLastWord = (text, maxLength) => {
    text = stripHTags(text);
    text = stripITags(text);
    text = stripStyleTags(text);
    text = stripEmTags(text);
    text = stripHrTags(text);
    text = stripStrongTags(text);
    text = stripSupTags(text);

    if (text.startsWith("<p>&nbsp;</p>")) {
      text = text.slice(13); // Remove the first 10 characters
    }

    if (text.length <= maxLength) {
      return text;
    }

    // Find the substring that fits within maxLength
    let truncated = text.substring(0, maxLength);

    // Find the last space in the truncated substring
    const lastSpaceIndex = truncated.lastIndexOf(" ");

    // If a space was found, truncate to the last word
    if (lastSpaceIndex !== -1) {
      truncated = truncated.substring(0, lastSpaceIndex);
    }

    return truncated + "...";
  };

  const stripHTags = (text) => {
    return text.replace(/<h[1-6]>/g, "<p>");
  };

  const stripITags = (text) => {
    return text.replace(/<i[^>]*>(.*?)<\/i>/g, "");
  };

  const stripStyleTags = (text) => {
    return text.replace(/style="[^"]*"/g, "");
  };

  const stripEmTags = (text) => {
    return text.replace(/<em[^>]*>(.*?)<\/em>/g, "$1");
  };

  const stripHrTags = (text) => {
    return text.replace(/<hr[^>]*>(.*?)<\/hr>/g, "$1");
  };

  const stripStrongTags = (text) => {
    return text.replace(/<strong[^>]*>(.*?)<\/strong>/g, "$1");
  };

  const stripSupTags = (text) => {
    return text.replace(/<sup[^>]*>(.*?)<\/sup>/g, "$1");
  };

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: truncateToLastWord(text, maxLength),
        }}
      />
    </>
  );
};
