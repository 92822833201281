import { OnboardStaticTagsPage } from "../OnboardStaticTagsPage";

export const EducationTagsPageWrapper = (props) => {
  return (
    <OnboardStaticTagsPage
      tagType={"Education"}
      lead={"Which of the following formal education levels have you achieved?"}
      tagColor="outline-danger"
      text={
        <>
          If roles that you are interested in are unlikely to require a
          particular level of education you can skip this step.
        </>
      }
      {...props}
    />
  );
};
