import React from "react";
import { GuidingPrinciples } from "./GuidingPrinciples";
import { ProfilingSystem } from "./ProfilingSystem";
import { HeaderText } from "../../../common/HeaderText";

const JobSeekersHowItWorksPage = () => {
  return (
    <>
      <HeaderText
        headerText="Meet the best employers"
        headerSpan="while maintaining your privacy"
        leadText="We provide a private platform where you can take control of the
          messages you receive and quickly identify the most relevant
          opportunities from leading employers, hiring managers and recruiters."
      />

      <GuidingPrinciples />

      <ProfilingSystem />
    </>
  );
};

export default JobSeekersHowItWorksPage;
