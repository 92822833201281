import { CenteredSingleColumnLayout } from "../../common/CenteredSingleColumnLayout";
import { RecruiterPostsList } from "./posts/RecruiterPostsList";
import { Link } from "react-router-dom";
import { InfoAlert } from "../../common/InfoAlert";

export const RecruiterPostsListPage = () => {
  return (
    <>
      <CenteredSingleColumnLayout>
        <>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <p></p>
            <span>
              <Link
                className="btn btn-outline-success btn-icon"
                to="/recruiter/posts/create"
              >
                <i className="fi-plus"></i>
              </Link>
            </span>
          </div>
          <InfoAlert
            lead="Create posts to distribute here."
            text={
              <>
                Posts are distributed to candidates with a matching skills
                profile. Post can only be 750 characters, they aren't intended
                to be job descriptions. Use posts to highlight key reasons that
                a candidates should engage with you. Candidate responses will
                populate in your posts when you receive them.
              </>
            }
          />
          <RecruiterPostsList />
        </>
      </CenteredSingleColumnLayout>
    </>
  );
};
