import { StaticTagsPage } from "../recruiter/OnboardStaticTagsPage";

export const UpdateRecruiterFirmTypesPageWrapper = (props) => {
  return (
    <StaticTagsPage
      tagType={"FirmType"}
      profileType={"recruiter"}
      tagColor={"danger"}
      text={`Select the tags that best describe the company you are hiring for?`}
      {...props}
      update={true}
    />
  );
};
