import React from "react";

import { useAuth } from "../contexts/AuthContext";

const UserSession = () => {
  const { user } = useAuth();

  return (
    <>
      {user ? (
        <div className="container mt-10">
          <table className="table table-striped" aria-labelledby="tabelLabel">
            <thead>
              <tr>
                <th>Claim Type</th>
                <th>Claim Value</th>
              </tr>
            </thead>
            <tbody>
              {user.map((claim) => (
                <tr key={claim.type}>
                  <td>{claim.type}</td>
                  <td>{claim.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>Loading</p>
      )}
    </>
  );
};

export default UserSession;
