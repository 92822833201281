import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as remoteClient from "../../dataServices/remoteClient";

const initialState = {
  recruiter: {},
  isLoading: false,
  error: null,
  profileTouched: false,
};

export const fetchRecruiter = createAsyncThunk(
  "recruiters/fetchRecruiter",
  async () => {
    return await remoteClient.get("/api/recruiters");
  }
);

export const addNewRecruiter = createAsyncThunk(
  "recruiters/addNewRecruiter",
  async (initialRecruiter) => {
    return await remoteClient.post(
      "api/recruiters",
      initialRecruiter.recruiter
    );
  }
);

export const updateRecruiter = createAsyncThunk(
  "users/updateRecruiter",
  async (recruiter) => {
    return await remoteClient.put(`/api/recruiters/${recruiter.id}`, recruiter);
  }
);

export const updateTag = createAsyncThunk(
  "users/updateTag",
  async (initialRecruiter) => {
    return;
  }
);

export const saveRecruiterProfileTags = createAsyncThunk(
  "recruiters/saveRecruiterProfileTags",
  async ({ recruiter, tagType }) => {
    return await remoteClient.post(
      `/api/recruiters/${recruiter.id}/profiles/${recruiter.profile.id}/tagsCollection/${tagType}`,
      recruiter.profile.tags.filter((t) => t.type === tagType)
    );
  }
);

export const saveProfileMultiTags = createAsyncThunk(
  "recruiters/saveProfileMultiTags",
  async ({ recruiter, tagType, selectedOptions }) => {
    return await remoteClient.post(
      `/api/recruiters/${recruiter.id}/profiles/${recruiter.profile.id}/skillsCollection/${tagType}`,
      selectedOptions
    );
  }
);

export const saveProfileCountries = createAsyncThunk(
  "recruiters/saveProfileCountries",
  async ({ recruiter, selectedOptions }) => {
    return await remoteClient.post(
      `/api/recruiters/${recruiter.id}/profiles/${recruiter.profile.id}/countriescollection`,
      selectedOptions.anyTags
    );
  }
);

export const saveProfileCities = createAsyncThunk(
  "recruiters/saveProfileCities",
  async ({ recruiter, selectedOptions }) => {
    return await remoteClient.post(
      `/api/recruiters/${recruiter.id}/profiles/${recruiter.profile.id}/citiescollection`,
      selectedOptions.anyTags
    );
  }
);

export const updateProfileSalary = createAsyncThunk(
  "recruiters/updateProfileSalary",
  async ({ recruiter, profileSalaryDto }) => {
    return await remoteClient.post(
      `/api/recruiters/${recruiter.id}/profiles/${recruiter.profile.id}/`,
      profileSalaryDto
    );
  }
);

export const updateRecruiterAvatar = createAsyncThunk(
  "recruiters/updateRecruiterAvatar",
  async ({ recruiterId, formData }) => {
    return await remoteClient.postFormData(
      `/api/recruiters/${recruiterId}/avatar`,
      formData
    );
  }
);

const recruiterSlice = createSlice({
  name: "recruiters",
  initialState,
  reducers: {
    recruiterAdded(state, action) {
      state.recruiter = action.payload;
    },
    recruiterUpdated(state, action) {
      state.recruiter = action.payload;
    },
    recruiterTagAdded(state, action) {
      const { tag } = action.payload;
      const recruiter = state.recruiter;
      if (recruiter) {
        recruiter.profile.tags.push(tag);
      }
    },
    recruiterTagRemoved(state, action) {
      const { tag } = action.payload;
      const recruiter = state.recruiter;
      if (recruiter) {
        recruiter.profile.tags = recruiter.profile.tags.filter(
          (t) => t.id !== tag.id
        );
      }
    },
    resetProfileTouched(state) {
      state.profileTouched = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRecruiter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchRecruiter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.recruiter = action.payload;
      })
      .addCase(fetchRecruiter.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(addNewRecruiter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.recruiter = action.payload;
      })
      .addCase(addNewRecruiter.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateRecruiter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.recruiter = action.payload;
        //state.profileTouched = true;
      })
      .addCase(updateRecruiter.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(saveRecruiterProfileTags.fulfilled, (state, action) => {
        state.isLoading = false;
        state.recruiter.profile.tags = action.payload;
        state.profileTouched = true;
      })
      .addCase(saveRecruiterProfileTags.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(saveProfileMultiTags.fulfilled, (state, action) => {
        state.isLoading = false;
        state.recruiter.profile.tags = action.payload.tags;
        state.recruiter.profile.tagsForSelect = action.payload.tagsForSelect;
        state.profileTouched = true;
      })
      .addCase(saveProfileMultiTags.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(saveProfileCountries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.recruiter.profile.countries = action.payload;
        state.profileTouched = true;
      })
      .addCase(saveProfileCountries.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(saveProfileCities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.recruiter.profile.cities = action.payload;
        state.profileTouched = true;
      })
      .addCase(saveProfileCities.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateProfileSalary.fulfilled, (state, action) => {
        state.recruiter = action.payload;
        state.profileTouched = true;
      })
      .addCase(updateProfileSalary.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateRecruiterAvatar.fulfilled, (state, action) => {
        state.recruiter.avatar = action.payload;
      })
      .addCase(updateRecruiterAvatar.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.payload;
      });
  },
});

export const {
  recruiterAdded,
  recruiterUpdated,
  recruiterTagAdded,
  recruiterTagRemoved,
  resetProfileTouched,
} = recruiterSlice.actions;

export default recruiterSlice.reducer;
