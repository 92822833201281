import { OnboardPostMultiTagsPage } from "./OnboardPostMultiTagsPage";

export const PostCityTagsPageWrapper = ({ match, ...props }) => {
  const { postId, profileId } = match.params;

  return (
    <OnboardPostMultiTagsPage
      tagType={"City"}
      text={"Which cities must candidates be based for office / hybrid roles?"}
      postId={postId}
      profileId={profileId}
      {...props}
    />
  );
};
