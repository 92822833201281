import { Link } from "react-router-dom";

import { useAuth } from "../../../contexts/AuthContext";
import { CenteredSingleColumnLayout } from "../../common/CenteredSingleColumnLayout";
import { InfoAlert } from "../../common/InfoAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain, faUserGraduate } from "@fortawesome/free-solid-svg-icons";

export const EditProfileLinks = () => {
  const { isCandidate, isEmployer } = useAuth();

  return (
    <>
      <CenteredSingleColumnLayout>
        <InfoAlert
          text={`Click 'Onboard Process' to navigate through process or update individual tags by type`}
        />
        <div className="card card-body border-0   pb-1 mt-5">
          <div className="d-md-block mt-3" id="card-nav">
            <div className="card-nav">
              <Link
                className="btn btn-lg btn-outline-primary w-100 mb-5"
                to={
                  isCandidate
                    ? `/onboard/positions`
                    : `/onboard/recruiter/positions`
                }
              >
                Onboard Process
              </Link>
              <Link
                className="card-nav-link"
                to={
                  isCandidate
                    ? `/update/positions`
                    : `/update/recruiter/positions`
                }
              >
                <i className="fi-arrows-sort opacity-60 me-2"></i>
                Positions
              </Link>
              <Link
                className="card-nav-link"
                to={
                  isCandidate
                    ? `/update/firmtypes`
                    : `/update/recruiter/firmtypes`
                }
              >
                <i className="fi-grid opacity-60 me-2"></i>
                Firm Types
              </Link>
              <Link
                className="card-nav-link"
                to={
                  isCandidate
                    ? `/update/divisions`
                    : `/update/recruiter/divisions`
                }
              >
                <i className="fi-briefcase opacity-60 me-2"></i>
                Divisions
              </Link>

              <Link
                className="card-nav-link"
                to={
                  isCandidate
                    ? `/update/industries`
                    : `/update/recruiter/industries`
                }
              >
                <i className="fi-apartment opacity-60 me-2"></i>
                Industries
              </Link>

              <Link
                className="card-nav-link"
                to={isCandidate ? `/update/skills` : `/update/recruiter/skills`}
              >
                <FontAwesomeIcon icon={faBrain} className="opacity-60 me-2" />
                Skills
              </Link>

              <Link
                className="card-nav-link"
                to={
                  isCandidate
                    ? `/update/qualifications`
                    : `/update/recruiter/qualifications`
                }
              >
                <i className="fi-award opacity-60 me-2"></i>
                Qualifications
              </Link>

              <Link
                className="card-nav-link"
                to={isCandidate ? `/update/office` : `/update/recruiter/office`}
              >
                <i className="fi-building opacity-60 me-2"></i>
                Offices
              </Link>

              <Link
                className="card-nav-link"
                to={
                  isCandidate
                    ? `/update/countries`
                    : `/update/recruiter/countries`
                }
              >
                <i className="fi-globe opacity-60 me-2"></i>
                Countries for Remote
              </Link>

              <Link
                className="card-nav-link"
                to={isCandidate ? `/update/cities` : `/update/recruiter/cities`}
              >
                <i className="fi-map-pin opacity-60 me-2"></i>
                Cities for Office / Hybrid
              </Link>

              <Link
                className="card-nav-link"
                to={
                  isCandidate
                    ? `/update/languages`
                    : `/update/recruiter/languages`
                }
              >
                <i className="fi-chat-right opacity-60 me-2"></i>
                Languages
              </Link>

              <Link
                className="card-nav-link"
                to={
                  isCandidate
                    ? `/update/educations`
                    : `/update/recruiter/educations`
                }
              >
                {/* <i className="fi-pencil opacity-60 me-2"></i> */}
                <FontAwesomeIcon
                  icon={faUserGraduate}
                  className="opacity-60 me-2"
                />
                Education
              </Link>

              <Link
                className="card-nav-link"
                to={
                  isCandidate
                    ? `/update/contracts`
                    : `/update/recruiter/contracts`
                }
              >
                <i className="fi-calendar opacity-60 me-2"></i>
                Contract Types
              </Link>

              <Link
                className="card-nav-link"
                to={isCandidate ? `/update/salary` : `/update/recruiter/salary`}
              >
                <i className="fi-cash opacity-60 me-2"></i>
                Salary Details
              </Link>
            </div>
          </div>
        </div>
      </CenteredSingleColumnLayout>
    </>
  );
};
