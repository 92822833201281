import { StaticTagsPage } from "../recruiter/OnboardStaticTagsPage";

export const UpdateRecruiterDivisonsPageWrapper = (props) => {
  return (
    <StaticTagsPage
      tagType={"Sector"}
      profileType={"recruiter"}
      text={`What teams do you recruit into?`}
      {...props}
      update={true}
    />
  );
};
