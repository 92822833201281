import { handleResponse, handleError } from "./dataUtils";

export async function get(route) {
  return fetch(`${route}`, {
    headers: {
      "X-CSRF": "1",
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function post(route, body) {
  return fetch(`${route}`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json",
    },
    method: "POST",

    body: JSON.stringify(body),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function postFormData(route, formData) {
  return fetch(`${route}`, {
    headers: {
      "X-CSRF": "1",
    },
    method: "POST",
    body: formData,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function put(route, body) {
  return fetch(`${route}`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json",
    },
    method: "PUT",
    body: JSON.stringify(body),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function putFormData(route, formData) {
  return fetch(`${route}`, {
    headers: {
      "X-CSRF": "1",
    },
    method: "PUT",
    body: formData,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function remove(route) {
  return fetch(`${route}`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json",
    },
    method: "DELETE",
  })
    .then(handleResponse)
    .catch(handleError);
}
