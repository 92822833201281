import React from "react";
import { CenteredSingleColumnLayout } from "./common/CenteredSingleColumnLayout";

export const NotAuthorized = () => {
  return (
    <CenteredSingleColumnLayout>
      <h1 className="display-3">Unauthorised</h1>

      <p class="fs-lg text-dark opacity-70">
        You do not have the right permissions to access this page.
      </p>
      <p class="mb-lg-4 mb-3 pb-lg-3 fs-lg text-dark opacity-70">
        If you belive this is wrong, try logging in again. If the problem
        persists, contact us.
      </p>

      <div class="pt-3">
        <a class="btn btn-lg btn-primary w-sm-auto w-100 mb-3 me-sm-4" href="/">
          Go to homepage
        </a>
      </div>
    </CenteredSingleColumnLayout>
  );
};
