import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { RedirectComponent } from "../components/redirect/RedirectComponent";
import { CandidateHomePage } from "./pages/home/HomePage";

export const Home = () => {
  const { isLoggedIn } = useAuth();

  return <>{isLoggedIn ? <RedirectComponent /> : <CandidateHomePage />}</>;
};
