import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { PrevNextBtnLgRound } from "../../user/onboard/PrevNextButtonLgRound";
import { MultiTagSelect } from "../../../common/MultiTagSelect";
import { InfoAlert } from "../../../common/InfoAlert";

import * as remoteClient from "../../../../dataServices/remoteClient";

import {
  fetchPosts,
  saveProfileMultiTags,
  saveProfileCities,
  saveProfileCountries,
  selectPostById,
} from "../../../../features/posts/postsSlice";

import { tagConstants } from "../../../../app/tagConstants";

import { toast } from "react-toastify";

import { CenteredSingleColumnLayout } from "../../../common/CenteredSingleColumnLayout";
import { CheckOwnerComponent } from "../../../../auth/CheckOwnerComponent";

export const OnboardPostMultiTagsPage = ({
  tagType,
  text,
  lead,
  creatable = false,
  postId,
  profileId,
}) => {
  const dispatch = useDispatch();

  const post = useSelector((state) => selectPostById(state, postId));
  const postStatus = useSelector((state) => state.posts.status);
  const [requiredOwnerId, setRequiredOwnerId] = useState("");
  const [icon, setIcon] = useState();

  useEffect(() => {
    if (postStatus === "idle") {
      dispatch(fetchPosts());
    }
  }, [postStatus, dispatch]);

  const [selectedOptions, setSelectedOptions] = useState({
    anyTags: [],
    anyTouched: false,
  });

  //const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);

  const [currentTag, setCurrentTag] = useState();
  const [nextTag, setNextTag] = useState();
  const [prevTag, setPrevTag] = useState();
  const [defaultValue, setDefaultValue] = useState();

  const currentTagFromTagConstants = tagConstants.find(
    (t) => t.type === tagType
  );

  let history = useHistory();

  const getTags = async (input) => {
    return await remoteClient.get(
      `/api/tags/select/${currentTag.href}?searchTerm=${input}`
    );
  };

  useEffect(() => {
    if (post && post.profile) {
      if (tagType === "Country") {
        setDefaultValue(post.profile.countries);
      } else if (tagType === "City") {
        setDefaultValue(post.profile.cities);
      } else {
        setDefaultValue(
          post.profile.tagsForSelect.filter(
            (t) => t.type === tagType && t.profileTagType === "Any"
          )
        );
      }
    }
  }, [post, tagType]);

  useEffect(() => {
    if (currentTag && currentTag.fiIcon) {
      setIcon(currentTag.fiIcon);
    }
  }, [currentTag]);

  useEffect(() => {
    if (post) {
      setRequiredOwnerId(post.ownerid);
    }
  }, [post]);

  useEffect(() => {
    setCurrentTag(tagConstants.find((t) => t.type === tagType));

    setNextTag(
      tagConstants.find((t) => t.order === currentTagFromTagConstants.order + 1)
    );

    setPrevTag(
      tagConstants.find((t) => t.order === currentTagFromTagConstants.order - 1)
    );
  }, [currentTagFromTagConstants, tagType]);

  const handleInput = (newValue) => {
    setSelectedOptions({
      ...selectedOptions,
      anyTags: newValue,
      anyTouched: true,
    });
  };

  const handleSave = () => {
    if (!selectedOptions.anyTouched && nextTag.href) {
      history.push(nextTag.href);
    } else {
      setSaving(true);

      if (tagType === "Country") {
        dispatch(saveProfileCountries({ post, selectedOptions }))
          .then(() => {
            toast.success(`${currentTag.name} tags saved`);
            history.push(`${nextTag.href}`);
          })
          .catch((error) => {
            setSaving(false);
            //setErrors({ onSave: error.message });
            toast.error(
              `Saving ${currentTag.name} tags failed: ${error.message}`
            );
          });
      } else if (tagType === "City") {
        dispatch(saveProfileCities({ post, selectedOptions }))
          .then(() => {
            toast.success(`${currentTag.name} tags saved`);
            history.push(`${nextTag.href}`);
          })
          .catch((error) => {
            setSaving(false);
            //setErrors({ onSave: error.message });
            toast.error(
              `Saving ${currentTag.name} tags failed: ${error.message}`
            );
          });
      } else {
        dispatch(saveProfileMultiTags({ post, tagType, selectedOptions }))
          .then(() => {
            toast.success(`${currentTag.name} tags saved`);
            history.push(`${nextTag.href}`);
          })
          .catch((error) => {
            setSaving(false);
            //setErrors({ onSave: error.message });
            toast.error(
              `Saving ${currentTag.name} tags failed: ${error.message}`
            );
          });
      }
    }
  };

  return (
    <>
      <CenteredSingleColumnLayout>
        {post && (
          <CheckOwnerComponent requiredOwnerId={requiredOwnerId}>
            {nextTag && (
              <InfoAlert
                lead={lead}
                text={text}
                link={"Skip"}
                href={nextTag.href}
              />
            )}

            <div className="py-4 mb-md-2">
              <h2 className="h4 mb-4">
                <i className={`${icon} text-primary fs-5 mt-n1 me-2 pe-1`}></i>
                Select {currentTag && currentTag.name} Tags
              </h2>

              <>
                {defaultValue && (
                  <>
                    {currentTag && (
                      <MultiTagSelect
                        loadOptions={getTags}
                        placeholder={`Start typing ${currentTag.name}`}
                        onChange={handleInput}
                        defaultValue={defaultValue}
                        creatable={creatable}
                        color={currentTag.colorHex}
                      />
                    )}
                  </>
                )}
              </>
            </div>
            {nextTag && prevTag && (
              <PrevNextBtnLgRound
                nextTitle={nextTag.name}
                prevTitle={prevTag.name}
                prevHref={prevTag.href}
                saving={saving}
                saveTags={handleSave}
              />
            )}
          </CheckOwnerComponent>
        )}
      </CenteredSingleColumnLayout>
    </>
  );
};
