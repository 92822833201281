import React from "react";
import { Link } from "react-router-dom";
import { SocialButton } from "./SocialButton";

export const Footer = () => {
  return (
    <>
      <footer className="footer bg-dark pt-5">
        <div className="container pb-2">
          <div className="row align-items-cneter pb-4 mx-1">
            <div className="col-md-9 col-xl-7">
              <div className="row gy-4 border-top border-light mt-3">
                <div className="col">
                  <h3 className="h6 mb-2 pb-1 fs-base text-light">
                    For Job Seekers
                  </h3>
                  <ul className="list-unstyled fs-sm">
                    <li>
                      <Link
                        to="/job-seekers/how-it-works"
                        className="nav-link-light"
                      >
                        How does it work?
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="#" className="nav-link-light">
                        Post a resume
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="nav-link-light">
                        Vacancy mailing
                      </Link>
                    </li> */}
                  </ul>
                </div>
                <div className="col">
                  <h3 className="h6 mb-2 pb-1 fs-base text-light">
                    For Employers
                  </h3>
                  <ul className="list-unstyled fs-sm">
                    <li>
                      <Link
                        to="/employers/fill-your-pipeline"
                        className="nav-link-light"
                      >
                        Fill your pipeline
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="employers/" className="nav-link-light">
                        Post a job
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="#" className="nav-link-light">
                        Resume mailing
                      </Link>
                    </li> */}
                  </ul>
                </div>
                <div className="col">
                  <h3 className="h6 mb-2 pb-1 fs-base text-light">sourcers</h3>
                  <ul className="list-unstyled fs-sm">
                    <li>
                      <Link to="/about" className="nav-link-light">
                        About
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="#" className="nav-link-light">
                        News
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/contact" className="nav-link-light">
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy" className="nav-link-light">
                        Privacy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Socials */}
              <div className="text-nowrap border-top border-light mt-3 py-4">
                <SocialButton
                  href="#"
                  brand="facebook"
                  variant="translucent"
                  roundedCircle
                  light
                  className="me-2"
                />
                <SocialButton
                  href="#"
                  brand="twitter"
                  variant="translucent"
                  roundedCircle
                  light
                  className="me-2"
                />
                <SocialButton
                  href="#"
                  brand="linkedin"
                  variant="translucent"
                  roundedCircle
                  light
                  className="me-2"
                />
                {/* <SocialButton
                  href="#"
                  brand="telegram"
                  variant="translucent"
                  roundedCircle
                  light
                  className="me-2"
                />
                <SocialButton
                  href="#"
                  brand="whatsapp"
                  variant="translucent"
                  roundedCircle
                  light
                /> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
