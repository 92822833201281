import React from "react";
import { Route } from "react-router";

import { FillYourPipeline } from "../components/pages/home/employers/FillYourPipeline";

export const EmployerHomePageRoutes = () => {
  return (
    <>
      <Route
        path="/employers/fill-your-pipeline"
        component={FillYourPipeline}
      />
    </>
  );
};
