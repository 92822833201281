import React from "react";
import { EmployerHoldingPage } from "../components/pages/employers/EmployerHoldingPage";
import EmployerRoute from "../auth/EmployerRoute";
import { RecruiterDashboardPage } from "../components/pages/recruiter/RecruiterDashboardPage";
import { RecruiterPostPage } from "../components/pages/recruiter/posts/RecruiterPostPage";
import { MessagesPage } from "../components/pages/messages/MessagesPage";
import { RecruiterPostsListPage } from "../components/pages/recruiter/RecruiterPostsListPage";

export const EmployerRoutes = () => {
  return (
    <>
      <EmployerRoute
        path="/employers/welcome"
        component={EmployerHoldingPage}
      />

      <EmployerRoute
        path="/recruiter/dashboard"
        component={RecruiterPostsListPage}
      />

      <EmployerRoute
        path="/recruiter/posts/create"
        component={RecruiterPostPage}
      />

      <EmployerRoute
        path="/recruiter/posts/:postId/edit"
        component={RecruiterPostPage}
      />

      <EmployerRoute path="/recruiter/messages" component={MessagesPage} />
    </>
  );
};
