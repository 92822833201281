export const PostResponseCandidateMessageCard = ({ candidate }) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center pe-2">
          <>
            {candidate && candidate.avatar && (
              <img
                className="rounded-circle m-2"
                src={candidate.avatar.location}
                width="36"
                alt="Avatar"
              />
            )}
            <div className="ps-2">
              <h6 className="fs-base mb-0">
                {candidate.firstName} {candidate.lastName}
              </h6>
              <span className="text-muted fs-sm mb-0">
                {candidate.employer}
                <br></br>
              </span>
            </div>
          </>
        </div>
      </div>
    </>
  );
};
