import React from "react";

export const ProfileSalaryForm = ({
  profile,
  onSave,
  onChange,
  saving = false,
  errors = {},
  update,
}) => {
  return (
    <form onSubmit={onSave}>
      <div className="bg-light rounded-3">
        <h2 className="h4 mb-4">
          <i className="fi-cash text-primary fs-5 mt-n1 me-2"></i>
          Salary Details
        </h2>

        <div className="row">
          <div className="col-sm-6 mb-4">
            <label className="form-label" htmlFor="currency">
              Currency
            </label>
            <select
              className="form-control form-control-lg"
              name="currency"
              id="currency"
              value={profile.currency}
              onChange={onChange}
            >
              <option value="GBP">£</option>
              <option value="USD">$</option>
              <option value="EUR">€</option>
            </select>
          </div>

          <div className="col-sm-6 mb-4">
            <label className="form-label" htmlFor="salaryFrequency">
              Frequency
            </label>
            <select
              className="form-control form-control-lg"
              name="salaryFrequency"
              id="salaryFrequency"
              value={profile.salaryFrequency}
              onChange={onChange}
            >
              <option value="Annual">Annual</option>
              <option value="Monthly">Monthly</option>
              <option value="Weekly">Weekly</option>
              <option value="Daily">Daily</option>
              <option value="Hourly">Hourly</option>
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 mb-4">
            <label className="form-label" htmlFor="minSalary">
              Minimum
            </label>
            <input
              className="form-control form-control-lg"
              type="number"
              name="minSalary"
              id="minSalary"
              value={profile.minSalary}
              placeholder="Enter Minimum Salary"
              onChange={onChange}
            />
          </div>
          <div className="col-sm-6 mb-4">
            <label className="form-label" htmlFor="maxSalary">
              Maximum
            </label>
            <input
              className="form-control form-control-lg"
              type="number"
              name="maxSalary"
              id="maxSalary"
              value={profile.maxSalary}
              placeholder="Enter Maximum Salary"
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-column flex-sm-row bg-light rounded-3 mt-5">
        {update ? (
          <button
            type="submit"
            className="btn btn-success btn-lg rounded-pill ms-sm-auto w-100"
            disabled={saving}
          >
            {saving ? "Updating..." : "Update"}
          </button>
        ) : (
          <button
            type="submit"
            className="btn btn-success btn-lg rounded-pill ms-sm-auto"
            disabled={saving}
          >
            {saving ? "Saving..." : "Save"}
            <i className="fi-chevron-right m-2"></i>
          </button>
        )}
      </div>
    </form>
  );
};
