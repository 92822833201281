import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { ProfileSalaryForm } from "./ProfileSalaryForm";
import { InfoAlert } from "../../../../common/InfoAlert";

import { useHistory } from "react-router-dom";

import {
  fetchRecruiter,
  updateProfileSalary,
} from "../../../../../features/recruiter/recruiterSlice";
import { CenteredSingleColumnLayout } from "../../../../common/CenteredSingleColumnLayout";

export const RecruiterOnboardSalaryPage = ({ update }) => {
  const recruiter = useSelector((state) => state.recruiter.recruiter);

  const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);
  const [profileToUpdate, setProfileToUpdate] = useState(null);
  const [profileSalaryDto, setProfileSalaryDto] = useState();

  const [, setAddRequestStatus] = useState("idle");

  //const [recruiter, setRecruiter] = useState(recruiterFromStore);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (recruiter && recruiter.profile) {
      //setRecruiter(recruiterFromStore);
      setProfileToUpdate(recruiter.profile);
      setProfileSalaryDto({
        id: recruiter.profile.id,
        minSalary: recruiter.profile.minSalary,
        maxSalary: recruiter.profile.maxSalary,
        minDailyRate: recruiter.profile.minDailyRate,
        maxDailyRate: recruiter.profile.maxDailyRate,
        minHourlyRate: recruiter.profile.minHourlyRate,
        maxHourlyRate: recruiter.profile.maxHourlyRate,
      });
    }
  }, [recruiter]);

  useEffect(() => {
    if (recruiter === null) {
      dispatchEvent(fetchRecruiter());
    }
  }, [recruiter]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProfileToUpdate((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));

    setProfileSalaryDto((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleSave = async (event) => {
    event.preventDefault();

    try {
      setAddRequestStatus("pending");
      setSaving(true);

      await dispatch(updateProfileSalary({ recruiter, profileSalaryDto }))
        .unwrap()
        .then(() => {
          toast.success("Recruiter Updated");
          history.push(
            update ? "/recruiter/dashboard" : "/onboard/recruiter/avatar"
          );
        });
    } catch (error) {
      console.error("Failed to update salary. " + error.message);
      setErrors({ onSave: error.message });
      setSaving(false);
      toast.error("Updating Salary failed. ", JSON.stringify(error.message));
    } finally {
      setAddRequestStatus("idle");
      setSaving(false);
    }
  };

  return (
    <>
      <CenteredSingleColumnLayout>
        <InfoAlert
          lead=" Provide a salary banding for the candidates you hope to engage
                with."
          text={
            <>
              <p>
                The band provided is not displayed to candidates. You will only
                be visible to candidates who have selected a salary band which
                corresponds with the numbers you provide.
              </p>
            </>
          }
        />
        {profileToUpdate && (
          <ProfileSalaryForm
            profile={profileToUpdate}
            onChange={handleChange}
            errors={errors}
            saving={saving}
            onSave={handleSave}
            update={update}
          />
        )}
      </CenteredSingleColumnLayout>
    </>
  );
};
