import { OnboardPostMultiTagsPage } from "./OnboardPostMultiTagsPage";

export const PostCountryTagsPageWrapper = ({ match, ...props }) => {
  const { postId, profileId } = match.params;

  return (
    <OnboardPostMultiTagsPage
      tagType={"Country"}
      text={"Which countries should candidates be based if remote?"}
      postId={postId}
      profileId={profileId}
      {...props}
    />
  );
};
