import { PostStaticTagsPage } from "./PostStaticTagsPage";

export const PostLanguageTagsPageWrapper = ({ match, ...props }) => {
  const { postId, profileId } = match.params;
  return (
    <PostStaticTagsPage
      tagType={"Language"}
      postId={postId}
      profileId={profileId}
      lead={<>Are there any language requirements?</>}
      {...props}
    />
  );
};
