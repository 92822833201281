import { OnboardStaticTagsPage } from "../OnboardStaticTagsPage";

export const OfficeTagsPageWrapper = (props) => {
  return (
    <OnboardStaticTagsPage
      tagType={"OfficePreference"}
      lead={"What are your working type preferences?"}
      tagColor={"secondary"}
      {...props}
      update={false}
    />
  );
};
