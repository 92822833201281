import { StaticTagsPage } from "../recruiter/OnboardStaticTagsPage";

export const UpdateRecruiterLanguagesPageWrapper = (props) => {
  return (
    <StaticTagsPage
      tagType={"Language"}
      text={"Do you require candidates to speak any languages?"}
      tagColor={"accent"}
      link="Skip"
      profileType={"recruiter"}
      {...props}
      update={true}
    />
  );
};
