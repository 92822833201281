import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { PrevNextBtnLgRound } from "./PrevNextButtonLgRound";
import { MultiTagSelect } from "../../../common/MultiTagSelect";
import { InfoAlert } from "../../../common/InfoAlert";

import * as remoteClient from "../../../../dataServices/remoteClient";

import {
  fetchUser,
  saveProfileMultiTags,
  saveProfileCountries,
  saveProfileCities,
} from "../../../../features/user/userSlice";

import { tagConstants } from "../../../../app/tagConstants";

import { toast } from "react-toastify";
import { CenteredSingleColumnLayout } from "../../../common/CenteredSingleColumnLayout";

export const OnboardMultiTagsPage = ({
  tagType,
  tagColor,
  lead,
  text,
  link,
  href,
  creatable = false,
  update,
}) => {
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();

  const [selectedOptions, setSelectedOptions] = useState({
    anyTags: [],
    anyTouched: false,
  });

  //const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);

  const [currentTag, setCurrentTag] = useState();
  const [nextTag, setNextTag] = useState();
  const [prevTag, setPrevTag] = useState();
  const [defaultValue, setDefaultValue] = useState();
  const [icon, setIcon] = useState();

  const currentTagFromTagConstants = tagConstants.find(
    (t) => t.type === tagType
  );

  let history = useHistory();

  const [initialLoad, setInitialLoad] = useState(false);

  // ... (other code)

  const getTags = async (input) => {
    if (input !== null) {
      return await remoteClient.get(
        `/api/tags/select/${currentTag.href}?searchTerm=${input}`
      );
    }
  };

  // const getTags = async (input) => {
  //   return await remoteClient.get(
  //     `/api/tags/select/${currentTag.href}?searchTerm=${input}`
  //   );
  // };

  useEffect(() => {
    if (user === null) {
      dispatch(fetchUser());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user && user.profile) {
      if (tagType === "Country") {
        setDefaultValue(user.profile.countries);
      } else if (tagType === "City") {
        setDefaultValue(user.profile.cities);
      } else {
        setDefaultValue(
          user.profile.tagsForSelect.filter(
            (t) => t.type === tagType && t.profileTagType === "Any"
          )
        );
      }
    }
  }, [user, tagType]);

  useEffect(() => {
    setCurrentTag(tagConstants.find((t) => t.type === tagType));

    setNextTag(
      tagConstants.find((t) => t.order === currentTagFromTagConstants.order + 1)
    );

    setPrevTag(
      tagConstants.find((t) => t.order === currentTagFromTagConstants.order - 1)
    );
  }, [currentTagFromTagConstants, tagType]);

  useEffect(() => {
    if (currentTag && currentTag.fiIcon) {
      setIcon(currentTag.fiIcon);
    }
  }, [currentTag]);

  const handleInput = (newValue) => {
    setSelectedOptions({
      ...selectedOptions,
      anyTags: newValue,
      anyTouched: true,
    });
  };

  const redirectAfterSave = () => {
    if (update) {
      history.push("/matches");
    } else {
      history.push(`${nextTag.href}`);
    }
  };

  const handleSave = () => {
    if (!selectedOptions.anyTouched && nextTag.href) {
      redirectAfterSave();
    } else {
      setSaving(true);

      if (tagType === "Country") {
        dispatch(saveProfileCountries({ user, selectedOptions }))
          .then(() => {
            toast.success(`${currentTag.name} tags saved`);
            redirectAfterSave();
          })
          .catch((error) => {
            setSaving(false);
            //setErrors({ onSave: error.message });
            toast.error(
              `Saving ${currentTag.name} tags failed: ${error.message}`
            );
          });
      } else if (tagType === "City") {
        dispatch(saveProfileCities({ user, selectedOptions }))
          .then(() => {
            toast.success(`${currentTag.name} tags saved`);
            redirectAfterSave();
          })
          .catch((error) => {
            setSaving(false);
            //setErrors({ onSave: error.message });
            toast.error(
              `Saving ${currentTag.name} tags failed: ${error.message}`
            );
          });
      } else {
        dispatch(saveProfileMultiTags({ user, tagType, selectedOptions }))
          .then(() => {
            toast.success(`${currentTag.name} tags saved`);
            redirectAfterSave();
          })
          .catch((error) => {
            setSaving(false);
            //setErrors({ onSave: error.message });
            toast.error(
              `Saving ${currentTag.name} tags failed: ${error.message}`
            );
          });
      }
    }
  };

  return (
    <>
      <CenteredSingleColumnLayout>
        {(text || lead) && (
          <InfoAlert text={text} lead={lead} link={link} href={href} />
        )}
        <h2 className="h4 mb-4">
          <i className={`${icon} text-primary fs-5 mt-n1 me-2 pe-1`}></i>
          Select {currentTag && currentTag.name} Tags
        </h2>

        <>
          {defaultValue && (
            <>
              {currentTag && (
                <MultiTagSelect
                  loadOptions={getTags}
                  placeholder={`Click here & start typing ${currentTag.name}`}
                  onChange={handleInput}
                  defaultValue={defaultValue}
                  creatable={creatable}
                  color={currentTag.colorHex}
                />
              )}
            </>
          )}
        </>
        {nextTag && prevTag && (
          <PrevNextBtnLgRound
            nextTitle={update ? "Update" : nextTag.namePlural}
            prevTitle={update ? "" : prevTag.namePlural}
            prevHref={update ? "" : prevTag.href}
            saving={saving}
            saveTags={handleSave}
            update={update}
          />
        )}
      </CenteredSingleColumnLayout>
    </>
  );
};
