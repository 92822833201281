import React from "react";

export const BadgeList = ({ tags, badgeClass }) => {
  const uniqueTagsSet = new Set();

  const classToReturn = badgeClass ? badgeClass : "success";

  for (let i = 0; i < tags.length; i++) {
    const tag = tags[i].name.trim();
    uniqueTagsSet.add(tag);
  }

  const uniqueTagsArray = Array.from(uniqueTagsSet);

  if (uniqueTagsArray.length > 0) {
    return uniqueTagsArray.map((tag) => (
      <span
        key={tag}
        className={`badge badge-lg bg-${classToReturn} me-1 mb-1`}
      >
        {tag}
      </span>
    ));
  }
};
