import React from "react";
import { ContactComponent } from "./ContactComponent";

export const ContactPage = () => {
  return (
    <>
      <section className="py-5">
        <div className="container pt-5 pb-lg-5">
          <div className="row">
            <div className="col-lg-4 mb-2">
              <div className="card border-0 bg-faded-danger p-md-3 p-1">
                <div className="card-body">
                  <h3 className="mb-4">Contact</h3>
                  <div className="mb-md-5 mb-4">
                    <a
                      className="nav-link fw-normal d-flex align-items-start mb-3 p-0"
                      href="mailto:peter@sourcers.co"
                    >
                      <i className="fi-mail mt-1 me-2 pe-1 text-primary"></i>
                      peter@sourcers.co
                    </a>
                    <a
                      className="nav-link fw-normal d-flex align-items-start mb-3 p-0"
                      href="tel:+447891533885"
                    >
                      <i className="fi-device-mobile mt-1 me-2 pe-1 text-primary"></i>
                      +44 7891 533 885
                    </a>

                    {/* <span className="nav-link fw-normal d-flex align-items-start mb-3 p-0">
                      <i className="fi-clock mt-1 me-2 pe-1 text-primary"></i>Mon –
                      Fri: 9:00am – 6:00pm (GMT - London)
                    </span> */}
                  </div>
                  <h3 className="h6 mb-1">Follow us:</h3>
                  <a
                    className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mt-2 me-2"
                    href="#"
                  >
                    <i className="fi-facebook"></i>
                  </a>
                  <a
                    className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mt-2 me-2"
                    href="#"
                  >
                    <i className="fi-twitter"></i>
                  </a>
                  <a
                    className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mt-2 me-2"
                    href="#"
                  >
                    <i className="fi-instagram"></i>
                  </a>
                  <a
                    className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mt-2 me-2"
                    href="#"
                  >
                    <i className="fi-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <ContactComponent />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
