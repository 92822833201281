import { PostStaticTagsPage } from "./PostStaticTagsPage";

export const PostContractTagsPageWrapper = ({ match, ...props }) => {
  const { postId, profileId } = match.params;

  return (
    <PostStaticTagsPage
      tagType={"Type"}
      postId={postId}
      profileId={profileId}
      lead={<>What type of contract is on offer?</>}
      {...props}
    />
  );
};
