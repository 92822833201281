import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";

import * as remoteClient from "../../dataServices/remoteClient";

const savedFeedItemsAdapter = createEntityAdapter({
  selectId: (entity) => entity.document.id,
});

const initialState = savedFeedItemsAdapter.getInitialState({
  status: "idle",
  removeStatus: "idle",
  currentPage: 1,
  error: null,
  totalCount: 0,
  savedTouched: false,
  initialFetch: false,
});

export const fetchSavedFeedItems = createAsyncThunk(
  "/saved/fetchSavedFeedItems",
  async () => {
    return await remoteClient.get(`/api/savedfeeditems`);
  }
);

export const removeSavedItem = createAsyncThunk(
  "/saved/removeSavedItem",
  async (itemId) => {
    return await remoteClient.remove(`/api/savedfeeditems/${itemId}`);
  }
);

const savedFeedItemsSlice = createSlice({
  name: "savedFeedItems",
  initialState,
  reducers: {
    addToSavedJobsList: (state, action) => {
      const item = action.payload;
      savedFeedItemsAdapter.addOne(state, action.payload);
      state.savedTouched = true;
      state.initialFetch = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSavedFeedItems.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSavedFeedItems.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.currentPage = action.payload.currentPage;
        state.totalCount = action.payload.count;
        savedFeedItemsAdapter.setAll(state, action.payload);
        state.initialFetch = true;
      })
      .addCase(removeSavedItem.pending, (state, action) => {
        state.removeStatus = "loading";
      })
      .addCase(removeSavedItem.fulfilled, (state, action) => {
        state.removeStatus = "succeeded";
        const itemId = action.meta.arg;
        savedFeedItemsAdapter.removeOne(state, itemId);
        state.savedTouched = true;
      })
      .addCase(removeSavedItem.rejected, (state, action) => {
        state.removeStatus = "failed";
        // Handle error if the removal fails
      });
  },
});

export const { addToSavedJobsList } = savedFeedItemsSlice.actions;

export default savedFeedItemsSlice.reducer;

export const {
  selectAll: selectAllFeedItems,
  selectById: selectSavedFeedItemById,
  selectIds: selectSavedFeedItemIds,
} = savedFeedItemsAdapter.getSelectors((state) => state.savedFeedItems);
