import React from "react";

export const PrivacyTab = () => (
  <>
    <div className="mb-md-4 mb-3 fs-sm">
      <h3 className="mt-4">Privacy Policy</h3>

      <h4 className="mt-4">What this Policy Governs</h4>

      <p className="mt-4">
        This Privacy Policy applies to our site, https:sourcers.co and any other
        services that we may provide to you through our site (we’ll just call
        these “our services” for short).
      </p>
      <p className="mt-4">
        In the context of this Privacy Policy “personal information” means
        information which, on its own or in combination with other information,
        can be used to identify you, in particular by reference to an identifier
        such as your name, your address, or location data.
      </p>
      <p className="mt-4">
        Provided that you consent to us collecting your personal information
        when you visit our site then this Privacy Policy will govern how we may
        use that personal information.
      </p>

      <h4 className="mt-4">
        How You Consent To Us Collecting Your Personal Information
      </h4>
      <p className="mt-4">
        When you visit our site you should see an overlay at the bottom of your
        screen directing you to this Privacy Policy and our Cookie Policy.
      </p>
      <p className="mt-4">
        By clicking or selecting the “Accept” button that appears in the overlay
        then you are demonstrating to us that you are freely giving us informed
        and specific consent for us to collect and process your personal
        information for the purposes specified in this Privacy Policy and you
        are accepting and consenting to the practices described.
      </p>
      <p className="mt-4">
        If you do not click or select the “Accept” button that appears in the
        overlay at the bottom of your screen then we will endeavour to not
        collect your personal information – this is subject to the caveat below.
      </p>
      <p className="mt-4">
        If you do not see an overlay at the bottom of your screen then you may
        have already accepted our Privacy Policy and our Cookie Policy. You may
        also be using a pop-up blocker or similar tools that may prevent this
        policy from being brought to your attention. Please ensure that any
        pop-up blockers are disabled when you use our site. We cannot be held
        liable to you if you use pop-up blocks or similar tools that prevent us
        from complying with our obligations under the legislation.
      </p>
      <p className="mt-4">
        Caveat: Even if you do not click or select the “I Agree” button there is
        still some information that we may automatically collect about you –
        this normally isn’t personal information but rather technical
        information sent by your device and used to connect your computer,
        tablet, or mobile device to the Internet, including your browser type
        and version, the time when you visit our site, your time zone setting,
        certain browser plug-in types and versions, operating system and
        platform. This type of information is sent by you to our site
        automatically and it isn’t something that we can prevent. We only use
        this information to administer our site including troubleshooting, load
        testing, traffic throughput, data analysis, performance testing, and for
        anonymised research and statistics purposes that we only use internally.
      </p>
      <p className="mt-4">
        We will only hold your personal information as detailed in our Retention
        Policy <a href="~/home/retentionpolicy">here</a>.
      </p>

      <h4 className="mt-4">Information We May Collect</h4>
      <p className="mt-4">
        If you consent to us collecting your personal information then we may
        process the following data about you:
      </p>
      <h5 className="mt-4">Information you give to us:</h5>
      <p className="mt-4">
        You may give us personal information about you by filling in forms on
        our site or by corresponding with us by phone, e-mail or otherwise. This
        includes information you may provide to us when you register to use our
        site, subscribe to our services, search our site, participate in any
        discussion boards or other social media functions on our site, enter a
        competition, promotion or survey, and when you report a problem with our
        site.
      </p>
      <p className="mt-4">The information you give us may include:</p>
      <ul className="mt-4">
        <li>Your name</li>
        <li>Address</li>
        <li>Email Address</li>
        <li>Phone Number</li>
        <li>Personal Description</li>
        <li>Your CV / Resume</li>
        <li>Photographs and/or videos</li>
      </ul>

      <h5 className="mt-4">Information We Collect About You</h5>
      <p className="mt-4">
        When you visit our site we may collect information about you, including
        which pages you have visited (including date and time) which services
        and pages you viewed or searched for, page response times, download
        errors, files downloaded, length of visits to certain pages, page
        interaction information (such as scrolling, clicks, and mouse-overs),
        and methods used to browse away from a page and any phone number used to
        call our customer service number.
      </p>
      <p className="mt-4">
        The purpose of this information is to allow us to administer our site
        and for internal operations, including troubleshooting, data analysis,
        testing, research, improving our site to ensure that content is
        presented in the most effective manner for you and for your computer,
        and as part of our efforts to keep our site safe and secure.
      </p>
      <h5 className="mt-4">Information we receive from other sources</h5>
      <p className="mt-4">
        Data collected from any of the other sites we operate may be shared
        internally and combined with data collected on this site. We are also
        working closely with third parties (including, for example,
        sub-contractors in technical, payment and delivery services, analytics
        providers, search information providers, and credit reference agencies)
        and may receive information about you from them.
      </p>
      <h5 className="mt-4">
        Information you may pass to third-parties outside our control:
      </h5>

      <p className="mt-4">
        On many of the pages on our site you will see ‘social buttons’. These
        enable you to share or bookmark pages on our site. These may include
        buttons for: Twitter, Google+, Pinterest, Facebook or other social
        features.
      </p>
      <p className="mt-4">
        In order for us to implement these buttons on our site and connect them
        to the relevant social networks and external sites, there are scripts
        that are executed from domains owned by third-parties and outside of our
        control
      </p>
      <p className="mt-4">
        You should be aware that these sites are likely to be collecting
        information about what you are doing all around the Internet, including
        on our site. So if you click on any of these buttons, these sites will
        be registering that action and may use that information. In some cases
        these sites will be registering the fact that you are visiting our site,
        and the specific pages you are on, even if you don’t click on the button
        but are already or automatically logged into their services, like Google
        and Facebook.
      </p>
      <p className="mt-4">
        We recommend that you check the respective policies of each of these
        sites to see how exactly they use your personal information and to find
        out how to opt out, or delete, such personal information.
      </p>

      <h4 className="mt-4">How We May Use Your Personal Information</h4>
      <p className="mt-4">
        When you give us your personal information then we may use it to:
      </p>
      <ol className="mt-4">
        <li>
          Carry out our obligations from any contracts entered into between you
          and us and to provide you with information that you request from us.
        </li>
        <li>Provide you with information about the company.</li>
        <li>
          Ensure that content from our site is presented in the most effective
          manner for you and for your computer.
        </li>
      </ol>
      <p className="mt-4">
        If you provide us with your e-mail, telephone or mobile number then we
        may contact you by these electronic means (e-mail or SMS). However, we
        will not use your personal information for marketing purposes without
        your express consent.
      </p>
      <p className="mt-4">
        If you do not wish to receive any information by electronic means then
        please get in touch with us.
      </p>

      <h5 className="mt-4">Marketing</h5>

      <p className="mt-4">
        If you opt-in to receive information about us then, depending on what
        personal information we hold about you, we may decide to contact you
        via:
      </p>

      <ul className="mt-4">
        <li>Email</li>
        <li>SMS</li>
        <li>Telephone</li>
        <li>or Post</li>
      </ul>

      <p className="mt-4">
        You may opt-in to receive general company information or we may ask you
        to opt-in on a case by case basis.
      </p>
      <p className="mt-4">
        We will do our best to let you know what method or methods of
        communication we will use. Don’t forget, you can always opt-out from
        receiving communications from us after you have opted-in or you can tell
        us that you only want to receive communications in a particular manner,
        or about a particular element of the company. Depending on the type of
        campaign we might give you the ability to select how you would like to
        be contacted but this might not always be possible due to technical
        issues.
      </p>

      <p className="mt-4">
        If you opt-in to receive communication then we may need to pass your
        personal information to select third parties who are responsible for
        facilitating communication with you on our behalf. For example, we may
        need to pass some of your personal details if you wish to be contacted
        via SMS because we need to partner with a third party business to
        provide us with this ability.
      </p>

      <p className="mt-4">
        If you have opted-in to receive communications but that organisation is
        not listed above then this might be because we are using that
        organisation or service on a one-off basis – in which case, we will let
        you know. If you are unsure then please get in touch with us.
      </p>

      <p className="mt-4">
        The purpose of opting into receiving communications from us is to help
        us better understand you, engage with you, share details about the
        company, and to assist us in providing the types of communications that
        you wish to receive from us. We will not knowingly sell your personal
        information for marketing purposes or pass your personal information to
        third parties.
      </p>

      <p className="mt-4">
        If you are receiving communications from us in error or you have
        opted-out then please get in touch with us and we apologise for any
        inconvenience caused.
      </p>

      <h4 className="mt-4">Disclosure of Your Information</h4>
      <p className="mt-4">
        We may share your personal information with any member of our group,
        which means our subsidiaries, our ultimate holding company and its
        subsidiaries.
      </p>

      <p className="mt-4">
        Although we may share your personal information within members of our
        group, this does not mean that we will use it for advertising or
        marketing purposes. Unless you opt-in to receive marketing communication
        we will not contact you unless it is necessary for us to get in touch
        with you.
      </p>

      <p className="mt-4">
        Please remember that our Cookies Policy covers matters such as using
        your personal information for analytics purposes.
      </p>
      <p className="mt-4">
        In some cases we may disclose information about you with select
        third-parties:
      </p>

      <ul className="mt-4">
        <li>
          We may anonymise and aggregate your information with other users (for
          example, we may track that 500 men aged under 20 have clicked on a
          specific page on our site on any given day). We may use such aggregate
          and anonymised information to help reach a particular target audience
          or understand who uses our site.{" "}
        </li>
        <li>
          Credit reference agencies for the purpose of assessing your credit
          score where this is a condition of us entering into a contract with
          you or providing you with services.
        </li>
        <li>
          Debt collection agencies where you have breached a condition of any
          contract entered between us and you
        </li>
        <li>
          If we or substantially all of our assets are acquired by a third
          party, in which case personal information held by us about you may be
          one of the transferred assets.
        </li>
        <li>
          If we are under a duty to disclose or share your personal information
          in order to comply with any legal obligation, or in order to enforce
          any agreements with you, or to protect our rights, property, or safety
          of our employees, or others.{" "}
        </li>
      </ul>

      <h4 className="mt-4">Where We Store Your Personal Information</h4>
      <p className="mt-4">
        The personal information that we collect from you will be stored within
        the United Kingdom. We will not knowingly transfer your personal
        information outside of the European Economic Area. Where it may be
        necessary for us to transfer your personal information outside of the
        European Economic Area we will get in touch with us and seek your
        consent.
      </p>
      <p className="mt-4">
        All personal information you provide to us is stored on our secure
        servers. Where we have given you (or where you have chosen) a password
        which enables you to access certain parts of our site, you are
        responsible for keeping this password confidential. We ask you not to
        share a password with anyone.
      </p>
      <p className="mt-4">
        Unfortunately, the transmission of information via the Internet is not
        completely secure. Although we will do our best to protect your personal
        information, we cannot guarantee the security of your data transmitted
        from our site and any transmission is at your own risk. Once we have
        received your personal information, we will use strict procedures and
        security features to try to prevent unauthorised access. We apply a
        ‘Privacy by Design’ approach to protecting your personal information in
        order to promote privacy and data protection compliance from the start.
      </p>

      <h4 className="mt-4">Your Rights</h4>

      <p className="mt-4">
        You have the right to ask us not to process your personal information or
        to restrict the purposes for which we may process your personal
        information. However, if you have an account with us on our site and you
        withdraw your consent then we reserve the right to suspend or terminate
        your access to certain parts of our site.
      </p>
      <p className="mt-4">
        You can exercise your right to prevent processing of your personal
        information by not selecting or clicking on the “Accept” button when you
        visit our site or when you login or create an account on our site.
      </p>

      <p className="mt-4">
        You can also exercise your right at any time by contacting us. If you
        consented to us collecting and/or processing your personal information
        but you change your mind then you can get in touch with us about erasing
        the personal information we hold about you.
      </p>
      <p className="mt-4">
        Please be aware that, even if we receive a request from you to delete
        your personal information, it may be that we cannot entirely remove all
        of your personal information. This is usually because we have a
        legitimate interest to hold onto some of that personal information, for
        example, if you have an ongoing contract with us, there are outstanding
        payments due by you to us, the personal information relates to a
        third-party, or we have a legal obligation to retain some or all of your
        personal information – but we’ll let you know.
      </p>

      <h4 className="mt-4">Access To Information</h4>

      <p className="mt-4">
        The legislation gives you the right to access a copy of the personal
        information that we hold about you.
      </p>
      <p className="mt-4">
        In order to request a copy you need to submit a request – details on how
        to get in touch can be found at the bottom of this page. However, please
        remember that certain types of data cannot be disclosed to you as part
        of your request. For example, we cannot disclose information to you
        which identifies another individual or information that may be subject
        to legal professional privilege. In many cases we may be required to
        redact some of the documentation that you receive in order to comply
        with the legislation.
      </p>
      <p className="mt-4">
        Additionally, we can’t just release your personal information to anyone.
        We can only release your personal information to you or an authorised
        third party who can demonstrate that you consented to have your personal
        information released – this is a legal requirement. Therefore, it is
        important for us to ensure that we confirm your identity before we can
        release any personal information.
      </p>

      <p className="mt-4">
        If you send us a request to provide you with a copy of your personal
        information then we may ask you to provide some identification. We may
        ask you to provide a copy of your current and valid passport and/or
        driving license plus a utility bill (that is no more than 3 months old)
        and has your current address. We may also ask you to visit our offices
        or obtain a solicitors or notary publics’ confirmation of your
        identification.
      </p>

      <p className="mt-4">
        All of this might sound very onerous but please understand that we have
        an obligation to protect your personal information and we need to be
        certain that it is being released to the right person.
      </p>

      <h4 className="mt-4">What if I Do Not Consent Or Withdraw Consent</h4>

      <p className="mt-4">
        If you do not consent to us collecting your personal information then we
        cannot guarantee the full functionality of our site and cannot be held
        responsible or liable to you for any reduction or restriction in access
        to our site or any of our other services.
      </p>
      <p className="mt-4">
        Our site may, from time to time, contain links to and from the sites of
        our partners and affiliates . If you follow a link to any of these
        sites, please note that these sites have their own privacy and cookie
        policies and that we do not accept any responsibility or liability for
        these policies. Please check these policies before you submit any
        personal information to these sites.
      </p>

      <h4 className="mt-4">
        How Long Will You Hold On To My Personal Information
      </h4>
      <p className="mt-4">
        Depending on how you engage with us it may be necessary for us to store
        your personal information for shorter or longer periods of time. Either
        way, we will only store your personal information for a legitimate and
        lawful reason. Please remember that this Privacy Policy only applies to
        how we may collect, store, use, and retain your personal information
        when you visit our site.
      </p>

      <p className="mt-4">
        You can find details on how long we may hold onto your personal
        information on our Retention Policy which can be found{" "}
        <a href="~/Home/RetentionPolicy">here</a>
      </p>

      <h4 className="mt-4">
        We may need to change this Privacy Policy if it’s necessary for legal
        reasons or to reflect changes to our site and services. In any case, the
        provisions of this Privacy Policy may be changed without prejudice to
        your rights. When we change our Privacy Policy we will make the updated
        Privacy Policy available on our site and we will also update the “Last
        Updated” date.
      </h4>
      <p className="mt-4">
        Once we change our Privacy Policy, it will become legally binding on you
        30 days after we post it on our site. During that period you’re welcome
        to contact us if you have questions about the changes.
      </p>

      <p className="mt-4">
        If you don’t agree with our changes to the Privacy Policy (regardless of
        whether you get in touch with us) then unfortunately we may need to
        restrict or limit your use of the parts of our site that require your
        personal information in order for us to deliver our services to you.
        We’re sorry we have to say that, but we hope you’ll appreciate that in
        order for certain parts of our site to work properly and in order for us
        to identify you in connection with our services we need to have everyone
        using our site and our services under the same rules instead of
        different people having different rules – that’s why we encourage you to
        get in touch with us if you have queries.
      </p>

      <h4 className="mt-4">Complaints</h4>

      <p className="mt-4">
        If you are not satisfied with the way that we have handled any of your
        requests or queries relating to our use of your personal data then you
        can contact the Information Commissioner’s Office at www.ico.org.uk. The
        Information Commissioner’s Office is the statutory body responsible for
        overseeing data protection legislation and law in the United Kingdom.{" "}
      </p>
    </div>
  </>
);
