import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import {
  addNewUser,
  updateUser,
  fetchUser,
} from "../../../../../features/user/userSlice";

import { toast } from "react-toastify";
import { BasicInfoForm } from "./BasicInfoForm";
import { InfoAlert } from "../../../../common/InfoAlert";

//New redux imports required

import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";
import { useClaims } from "../../../../../contexts/ClaimsContext";
import { CenteredSingleColumnLayout } from "../../../../common/CenteredSingleColumnLayout";

export const BasicInfoPage = ({ update, ...props }) => {
  const history = useHistory();
  const userFromStore = useSelector((state) => state.user || {});

  const [user, setUser] = useState(userFromStore.user || {});

  const [editorState, setEditorState] = useState(
    EditorState.createEmpty() // or provide an initial content state if needed
  );
  const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);

  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const [initialFetchCompleted, setInitialFetchCompleted] = useState(false);

  const dispatch = useDispatch();

  const maxCharacters = 750;

  const [currentTextLength, setCurrentTextLength] = useState(0);

  // Set user object when user updates from dispatch
  useEffect(() => {
    setUser(userFromStore.user);
  }, [userFromStore]);

  // Set the editor with HTML from user pitch field
  useEffect(() => {
    if (user && user.pitch) {
      if (!initialFetchCompleted) {
        const contentState = ContentState.createFromBlockArray(
          convertFromHTML(user.pitch)
        );
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
        setInitialFetchCompleted(true);
        setCurrentTextLength(contentState.getPlainText("").length);
      }
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [user, user.pitch, initialFetchCompleted]);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  // Change handler for standard inputs
  function handleChange(event) {
    const { name, value } = event.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  }

  const [prevContentState, setPrevContentState] = useState(
    editorState.getCurrentContent()
  );

  // Change handler for pitch editor
  const handlePitchChange = (newEditorState) => {
    const currentContent = newEditorState.getCurrentContent();
    const currentText = currentContent.getPlainText("");

    if (currentText.length <= maxCharacters) {
      setPrevContentState(currentContent); // Save the current content state
      setEditorState(newEditorState);
      setCurrentTextLength(currentText.length);
      convertContentToHTML();
    } else {
      // If the text exceeds the character limit, restore the previous content state
      setEditorState(EditorState.createWithContent(prevContentState));
    }
  };

  const convertContentToHTML = () => {
    const extractedHTML = extractHTML();
    setUser((prevUser) => ({
      ...prevUser,
      pitch: extractedHTML,
    }));
  };

  // Get HTML from editor for saving to db
  const extractHTML = () => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlToSanitize = draftToHtml(rawContentState);
    return sanitize(htmlToSanitize);
  };

  // Sanitize any bad input from editor
  const sanitize = (html) => {
    return DOMPurify.sanitize(html);
  };

  const canSave =
    [user.firstName, user.lastName].every(Boolean) &&
    addRequestStatus === "idle";

  const formIsValid = () => {
    const { firstName, lastName } = user;
    const errors = {};

    if (!firstName) errors.firstName = "First name is required";
    if (!lastName) errors.lastName = "Last name is required";

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const redirectAfterSave = () => {
    history.push(update ? "/matches" : "/onboard/positions");
  };

  const handleSave = async (event) => {
    event.preventDefault();
    setSaving(true);

    if (!formIsValid()) return;

    if (canSave) {
      try {
        setAddRequestStatus("pending");

        user.id
          ? await dispatch(updateUser({ user }))
              .unwrap()
              .then(() => {
                toast.success("User Updated");
                redirectAfterSave();
              })
          : await dispatch(addNewUser({ user }))
              .unwrap()
              .then(() => {
                toast.success("User Created");
                redirectAfterSave();
              });
      } catch (error) {
        console.error("Failed to save user");
        setErrors({ onSave: error.message });
        setSaving(false);
        toast.error("Saving User failed. ", JSON.stringify(error.message));
      } finally {
        setAddRequestStatus("idle");
        setSaving(false);
      }
    }
  };

  return (
    <>
      <CenteredSingleColumnLayout>
        <InfoAlert
          lead={"Your profile is private by default."}
          text={
            <>
              <br></br>These details will only be visible to business users when
              you explicitly engage with a business, hiring manager or
              recruiter.
            </>
          }
        />
        {user && editorState && (
          <BasicInfoForm
            user={user}
            onChange={handleChange}
            onSave={handleSave}
            errors={errors}
            saving={saving}
            editorState={editorState}
            onPitchChange={handlePitchChange}
            maxCharacters={maxCharacters}
            currentTextLength={currentTextLength}
            update={update}
          />
        )}
      </CenteredSingleColumnLayout>
    </>
  );
};
