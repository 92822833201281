import React from "react";

import { BasicInfoPage } from "../components/pages/user/onboard/candidate/BasicInfoPage";
import { OnboardOfficePreferencesPage } from "../components/pages/user/onboard/office/OnboardOfficePreferencesPage";
import { UpdateOfficesPageWrapper } from "../components/pages/user/onboard/office/UpdateOfficesPageWrapper";

import { OnboardSkillsPage } from "../components/pages/user/onboard/skills/OnboardSkillsPage";

import { OnboardSkillTagsPageWrapper } from "../components/pages/user/onboard/skills/OnboardSkillTagsPageWrapper";
import { UpdateSkillsPageWrapper } from "../components/pages/user/onboard/skills/UpdateSkillsPageWrapper";

import { FirmTypesTagsPageWrapper } from "../components/pages/user/onboard/firmTypes/FirmTypesTagsPageWrapper";
import { UpdateFirmTypesPageWrapper } from "../components/pages/user/onboard/firmTypes/UpdateFirmTypesPageWrapper";
import { PositionTagsPageWrapper } from "../components/pages/user/onboard/positions/PositionTagsPageWrapper";
import { UpdatePositionsPageWrapper } from "../components/pages/user/onboard/positions/UpdatePositionsPageWrapper";
import { DivisionTagsPageWrapper } from "../components/pages/user/onboard/divisions/DivisionTagsPageWrapper";
import { UpdateDivisionsPageWrapper } from "../components/pages/user/onboard/divisions/UpdateDivisionsPageWrapper";
import { IndustryTagsPageWrapper } from "../components/pages/user/onboard/industries/IndustryTagsPageWrapper";
import { UpdateIndustriesPageWrapper } from "../components/pages/user/onboard/industries/UpdateIndustriesPageWrapper";
import { LanguageTagsPageWrapper } from "../components/pages/user/onboard/languages/LanguageTagsPageWrapper";
import { UpdateLanguagesPageWrapper } from "../components/pages/user/onboard/languages/UpdateLanguagesPageWrapper";

import { CountryTagsPageWrapper } from "../components/pages/user/onboard/countries/CountryTagsPageWrapper";
import { UpdateCountriesPageWrapper } from "../components/pages/user/onboard/countries/UpdateCountriesPageWrapper";
import { CityTagsPageWrapper } from "../components/pages/user/onboard/cities/CityTagsPageWrapper";
import { UpdateCitiesPageWrapper } from "../components/pages/user/onboard/cities/UpdateCitiesPageWrapper";
import { EducationTagsPageWrapper } from "../components/pages/user/onboard/education/EducationTagsPageWrapper";
import { UpdateEducationPageWrapper } from "../components/pages/user/onboard/education/UpdateEducationPageWrapper";
import { ContractTagsPageWrapper } from "../components/pages/user/onboard/contracts/ContractTagsPageWrapper";
import { UpdateContractsPageWrapper } from "../components/pages/user/onboard/contracts/UpdateContractsPageWrapper";
import { OnboardSalaryPage } from "../components/pages/user/onboard/salary/OnboardSalaryPage";
import { QualificationTagsWrapper } from "../components/pages/user/onboard/qualifications/QualificationTagsWrapper";
import { UpdateQualificationsPageWrapper } from "../components/pages/user/onboard/qualifications/UpdateQualificationsPageWrapper";

import { CandidateRoute } from "../auth/CandidateRoute";
import { UpdateAvatarPage } from "../components/pages/user/onboard/candidate/UpdateAvatar";
import { OnboardSalaryPageWrapper } from "../components/pages/user/onboard/salary/OnboardSalaryPageWrapper";
import { UpdateSalaryPageWrapper } from "../components/pages/user/onboard/salary/UpdateSalaryPageWrapper";
import { UpdateAvatarPageWrapper } from "../components/pages/user/onboard/candidate/UpdateAvatarPageWrapper";
import { OnboardAvatarPageWrapper } from "../components/pages/user/onboard/candidate/OnboardAvatarPageWrapper";
import { OfficeTagsPageWrapper } from "../components/pages/user/onboard/office/OfficeTagsPageWrapper";
import { BasicInfoPageWrapper } from "../components/pages/user/onboard/candidate/BasicInfoPageWrapper";
import { UpdateBasicInfoPageWrapper } from "../components/pages/user/onboard/candidate/UpdateBasicInfoPageWrapper";

export const OnboardUserRoutes = () => {
  return (
    <>
      <CandidateRoute
        path="/onboard/basic-info"
        component={BasicInfoPageWrapper}
      />
      <CandidateRoute
        path="/update/basic-info"
        component={UpdateBasicInfoPageWrapper}
      />

      <CandidateRoute
        path="/onboard/positions"
        component={PositionTagsPageWrapper}
      />
      <CandidateRoute
        path="/update/positions"
        component={UpdatePositionsPageWrapper}
      />

      <CandidateRoute
        path="/onboard/firmtypes"
        component={FirmTypesTagsPageWrapper}
      />

      <CandidateRoute
        path="/update/firmtypes"
        component={UpdateFirmTypesPageWrapper}
      />

      <CandidateRoute
        path="/onboard/divisions"
        component={DivisionTagsPageWrapper}
      />
      <CandidateRoute
        path="/update/divisions"
        component={UpdateDivisionsPageWrapper}
      />

      <CandidateRoute
        path="/onboard/industries"
        component={IndustryTagsPageWrapper}
      />

      <CandidateRoute
        path="/update/industries"
        component={UpdateIndustriesPageWrapper}
      />

      <CandidateRoute
        path="/onboard/skills"
        component={OnboardSkillTagsPageWrapper}
      />
      <CandidateRoute
        path="/update/skills"
        component={UpdateSkillsPageWrapper}
      />

      <CandidateRoute
        path="/onboard/qualifications"
        component={QualificationTagsWrapper}
      />
      <CandidateRoute
        path="/update/qualifications"
        component={UpdateQualificationsPageWrapper}
      />

      <CandidateRoute
        path="/onboard/office"
        component={OfficeTagsPageWrapper}
      />
      <CandidateRoute
        path="/update/office"
        component={UpdateOfficesPageWrapper}
      />

      <CandidateRoute
        path="/onboard/countries"
        component={CountryTagsPageWrapper}
      />

      <CandidateRoute
        path="/update/countries"
        component={UpdateCountriesPageWrapper}
      />

      <CandidateRoute path="/onboard/cities" component={CityTagsPageWrapper} />
      <CandidateRoute
        path="/update/cities"
        component={UpdateCitiesPageWrapper}
      />

      <CandidateRoute
        path="/onboard/languages"
        component={LanguageTagsPageWrapper}
      />
      <CandidateRoute
        path="/update/languages"
        component={UpdateLanguagesPageWrapper}
      />

      <CandidateRoute
        path="/onboard/educations"
        component={EducationTagsPageWrapper}
      />
      <CandidateRoute
        path="/update/educations"
        component={UpdateEducationPageWrapper}
      />

      <CandidateRoute
        path="/onboard/contracts"
        component={ContractTagsPageWrapper}
      />

      <CandidateRoute
        path="/update/contracts"
        component={UpdateContractsPageWrapper}
      />

      <CandidateRoute
        path="/onboard/salary"
        component={OnboardSalaryPageWrapper}
      />
      <CandidateRoute
        path="/update/salary"
        component={UpdateSalaryPageWrapper}
      />

      <CandidateRoute
        path="/update/avatar"
        component={UpdateAvatarPageWrapper}
      />

      <CandidateRoute
        path="/onboard/avatar"
        component={OnboardAvatarPageWrapper}
      />
    </>
  );
};
