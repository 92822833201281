import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";

import { HTMLSubstring } from "../../common/HTMLSubstring";
import { TimeAgo } from "../../common/TimeAgo";
import { useAuth } from "../../../contexts/AuthContext";

import { CommentEditor } from "../../common/CommentEditor";

import { sendCandidatePostMessage } from "../../../features/matches/matchesSlice";

import { toast } from "react-toastify";

import { EditorState, convertToRaw } from "draft-js";

import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";
import { CardHTML } from "../../common/CardHTML";

export const MatchResponseList = ({ postResponses, recruiter }) => {
  const { userId } = useAuth();
  const dispatch = useDispatch();

  const [editorStates, setEditorStates] = useState({});
  const [editorsCurrentCharacters, setEditorsCurrentCharacters] = useState(
    postResponses
      .filter((post) => post.type === "Engage")
      .reduce((acc, response) => {
        acc[response.id] = 0;
        return acc;
      }, {})
  );
  const [dtos, setDtos] = useState({});

  const maxCharacters = 750;
  // Initialize editorStates and dtos when postResponses change
  useEffect(() => {
    initializeEditorStates();
  }, [postResponses]);

  const initializeEditorStates = () => {
    const editorStatesMap = {};
    const dtosMap = {};

    postResponses
      .filter((post) => post.type === "Engage")
      .forEach((response) => {
        const postId = response.postId;
        const postResponseId = response.id;

        editorStatesMap[postResponseId] = EditorState.createEmpty();
        dtosMap[postResponseId] = { comment: "" };
      });

    setEditorStates(editorStatesMap);
    setDtos(dtosMap);
  };

  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [errors, setErrors] = useState({});

  const handleCommentChange = (newEditorState, postResponseId) => {
    const currentContent = newEditorState.getCurrentContent();
    const currentText = currentContent.getPlainText("");

    // Check if the current text length exceeds the max characters
    if (currentText.length <= maxCharacters) {
      // Update the editor state for the specified post response
      setEditorStates((prevState) => ({
        ...prevState,
        [postResponseId]: newEditorState,
      }));

      setEditorsCurrentCharacters((prevState) => ({
        ...prevState,
        [postResponseId]: currentText.length,
      }));

      convertContentToHTML(postResponseId);
    } else {
      // If the text exceeds the character limit, restore the previous editor state
      setEditorStates((prevState) => ({
        ...prevState,
        [postResponseId]: EditorState.createWithContent(
          prevState[postResponseId].getCurrentContent()
        ),
      }));
    }
  };
  const convertContentToHTML = (postResponseId) => {
    const contentState = editorStates[postResponseId].getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlToSanitize = draftToHtml(rawContentState);
    const sanitizedHTML = sanitize(htmlToSanitize);
    setDtos((prevDtos) => ({
      ...prevDtos,
      [postResponseId]: { comment: sanitizedHTML },
    }));
  };

  const sanitize = (html) => {
    return DOMPurify.sanitize(html);
  };

  const canSave = (postResponseId) =>
    dtos[postResponseId].comment && addRequestStatus === "idle";

  const handleSendMessage = async (response, postResponseId) => {
    if (!canSave(postResponseId)) {
      return;
    }

    try {
      setAddRequestStatus("pending");

      const matchId = response.postId;

      // Use the dto for the specified post response
      const dto = dtos[postResponseId];

      await dispatch(sendCandidatePostMessage({ matchId, postResponseId, dto }))
        .unwrap()
        .then((response) => {
          toast.success("Message sent");
          setDtos((prevDtos) => ({
            ...prevDtos,
            [postResponseId]: { comment: "" },
          }));
          setEditorStates((prevEditorStates) => ({
            ...prevEditorStates,
            [postResponseId]: EditorState.createEmpty(),
          }));
        });
    } catch (error) {
      toast.error("Failed to send message");
      setErrors({ onSave: error.message });
    } finally {
      setAddRequestStatus("idle");
    }
  };

  return (
    <>
      {postResponses
        .filter((post) => post.type === "Engage")
        .map((response) => (
          <>
            {response.messageThread &&
              response.messageThread.messages &&
              response.messageThread.messages.length > 0 &&
              response.messageThread.messages.map((message) =>
                userId === message.senderId ? (
                  <div key={message.id} className="py-2">
                    <div className="d-flex justify-content-end">
                      <div className="card bg-info text-light p-3 w-75 border-round">
                        <CardHTML text={message.text} />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <span className="text-muted fs-sm">
                        <TimeAgo timestamp={message.created} />
                      </span>
                    </div>
                  </div>
                ) : (
                  <div key={message.id} className="py-2">
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <div className="d-flex align-items-center pe-2">
                        <>
                          {recruiter && recruiter.avatar && (
                            <img
                              className="rounded-circle me-1"
                              src={recruiter.avatar.location}
                              width="48"
                              alt="Avatar"
                            />
                          )}
                          <div className="ps-2">
                            <h6 className="fs-base mb-0">
                              {recruiter.firstName} {recruiter.lastName}
                            </h6>
                            <span className="text-muted fs-sm mb-0">
                              {recruiter.employer}
                              <br></br>
                            </span>
                          </div>
                        </>
                      </div>
                    </div>

                    <div className="card bg-secondary p-3 w-75 ps-4 mt-4">
                      <CardHTML text={message.text} />
                    </div>
                    <span className="text-muted fs-sm">
                      <TimeAgo timestamp={message.created} />
                    </span>
                  </div>
                )
              )}
            <div className="py-2">
              <div className="d-flex justify-content-end">
                <CommentEditor
                  editorState={editorStates[response.id]} // Use the editor state for the specified post response
                  handleEditorChange={(newEditorState) =>
                    handleCommentChange(newEditorState, response.id)
                  } // Pass the postResponseId
                  toolbarHidden
                />
                <button
                  className="btn btn-success"
                  onClick={() => handleSendMessage(response, response.id)} // Pass the postResponseId
                  style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  <i className="fi-send"></i>
                </button>
              </div>
              <p className="d-flex justify-content-end">
                <em>
                  {maxCharacters - editorsCurrentCharacters[response.id]} /{" "}
                  {maxCharacters} remaining
                </em>
              </p>
            </div>{" "}
          </>
        ))}
    </>
  );
};
