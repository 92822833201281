import React from "react";
import { IconCard } from "../../../common/IconCard";

export const GuidingPrinciples = () => {
  return (
    <>
      <div className="container pt-5">
        <div className="card border-0 bg-faded-success p-md-3 p-1">
          <div className="card-body">
            <h2 className="mb-4 pb-md-2 text-center text-primary">
              Guiding principles
            </h2>{" "}
            <div className="row row-cols-lg-4 mt-4 row-cols-md-3 row-cols-sm-1 row-cols-1 g-4 justify-content-center">
              <IconCard
                iconClass="bg-faded-accent"
                textClass="text-accent"
                icon="fi-lock"
                title="Privacy"
                description=" We will provide a private space for candidates to receive
                relevant feeds of jobs, hiring managers, recruiters and
                companies without revealing their profile."
                subTitle={"Privacy always by default."}
              />

              <IconCard
                iconClass="bg-faded-info"
                textClass="text-info"
                icon="fi-geo"
                title="Relevance"
                description="Our profiling system ensures only highly personalised
                content is received in candidate feeds."
                subTitle="Skills based job matching"
              />

              <IconCard
                iconClass="bg-faded-warning"
                textClass="text-warning"
                icon="fi-checkbox-checked-alt"
                title="Control"
                description="Control the content and messaging you recieve based on
                position / level, firm type, division, industry, skills,
                qualifications, work preference, location, contract type
                and more."
                subTitle="Permission based messaging"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
