import React, { useState } from "react";
import { Navbar, Container, Row } from "reactstrap";

import { NavFooterCandidateLinks } from "./NavFooterCandidateLinks";
import { NavFooterRecruiterLinks } from "./NavFooterRecruiterLinks";

import { useAuth } from "../../contexts/AuthContext";

import "./NavMenu.css";

export const NavFooter = () => {
  const { isLoggedIn, isCandidate, isEmployer } = useAuth();

  return (
    <>
      {isLoggedIn && (
        <Container fluid>
          <Navbar
            className="justify-content-between bottom-navbar mt-10"
            color="dark"
            fixed="bottom"
            light
          >
            <Row className="w-100">
              {isCandidate && <NavFooterCandidateLinks />}
              {isEmployer && <NavFooterRecruiterLinks />}
            </Row>
          </Navbar>
        </Container>
      )}
    </>
  );
};
