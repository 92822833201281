export const CandidateAllAnySkillsDescription = () => {
  return (
    <>
      <>
        <p className="mt-2">
          Messages / Matches are only required to have one of the tags selected
          in
          <strong> ANY</strong>
          <br></br>
          e.g.{" "}
          <span className="badge badge-lg bg-primary me-1 mb-1 mt-1">
            Software Engineer
          </span>
          OR{" "}
          <span className="badge badge-lg bg-primary me-1 mb-1 mt-1">
            Software Development
          </span>{" "}
          <br></br>
          <br></br>
          Using <strong>ALL</strong> will refine your results and only return
          messages / matches which contain all the selected tags. <br></br> e.g.{" "}
          <span className="badge badge-lg bg-primary me-1 mb-1 mt-1">
            Javascript
          </span>{" "}
          AND{" "}
          <span className="badge badge-lg bg-primary me-1 mb-1 mt-1">
            TypeScript
          </span>
          AND{" "}
          <span className="badge badge-lg bg-primary me-1 mb-1 mt-1">
            React
          </span>
        </p>
      </>
    </>
  );
};
