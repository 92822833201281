import React from "react";

export const DivisionBadgeList = ({ divisions }) => {
  const uniqueDivisionsSet = new Set();

  for (let i = 0; i < divisions.length; i++) {
    const division = divisions[i].name.trim();
    uniqueDivisionsSet.add(division);
  }

  const uniqueDivisionsArray = Array.from(uniqueDivisionsSet);

  if (uniqueDivisionsArray.length > 0) {
    return uniqueDivisionsArray.map((division) => (
      <span key={division} className="badge badge-lg bg-success me-1 mb-1">
        {division}
      </span>
    ));
  }
};
