export const RecruiterAllAnySkillsDescription = () => {
  return (
    <>
      <>
        <p className="mt-2">
          Candidates are only required to have one of the skills selected in
          <strong> ANY</strong>
          <br></br>
          e.g.{" "}
          <span className="badge badge-lg bg-primary me-1 mb-1 mt-1">
            Software Engineer
          </span>
          OR{" "}
          <span className="badge badge-lg bg-primary me-1 mb-1 mt-1">
            Software Development
          </span>{" "}
          <br></br>
          <br></br>
          Select <strong>ALL</strong> if to match with candidates with multiple
          required skills. <br></br> e.g.{" "}
          <span className="badge badge-lg bg-primary me-1 mb-1 mt-1">
            Javascript
          </span>{" "}
          AND{" "}
          <span className="badge badge-lg bg-primary me-1 mb-1 mt-1">
            TypeScript
          </span>
          AND{" "}
          <span className="badge badge-lg bg-primary me-1 mb-1 mt-1">
            React
          </span>
        </p>
      </>
    </>
  );
};
