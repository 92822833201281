import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { PrevNextBtnLgRound } from "./PrevNextButtonLgRound";
import { OnboardTagsForm } from "./OnboardTagsForm";

import { toast } from "react-toastify";

import {
  tagAdded,
  tagRemoved,
  saveProfileTags,
} from "../../../../features/user/userSlice";

// import {
//   fetchTags,
//   selectAllTags,
//   selectTagIds,
// } from "../../../../features/tags/tagsSlice";

export const OnboardTags = ({
  all,
  tagType,
  tagColor,
  tagTypeName,
  icon,
  nextHref,
  prevHref,
  nextTitle,
  prevTitle,
  showFilter,
  update,
}) => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  let history = useHistory();
  const [availableTags, setAvailableTags] = useState([]);
  const [profileId, setProfileId] = useState();
  const [touched, setTouched] = useState(false);
  const [errors, setErrors] = useState(false);

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (user && user.profile) {
      //setProfileId(user.profile.id);
      setAvailableTags([
        ...all.filter((a) => !user.profile.tags.some((t) => t.id === a.id)),
      ]);
    }
  }, [user, all]);

  const handleSelectTag = (tag) => {
    setTouched(true);
    dispatch(tagAdded({ tag }));
  };

  const handleRemoveTag = (tag) => {
    setTouched(true);
    dispatch(tagRemoved({ tag }));
    //removeProfileTag(tag);
  };

  const handleSaveTags = () => {
    if (!touched) {
      if (!update) {
        history.push(nextHref);
      } else {
        history.push("/matches");
      }
    } else {
      setSaving(true);
      if (!update) {
        history.push(nextHref);
      } else {
        history.push("/matches");
      }

      dispatch(saveProfileTags({ user, tagType }))
        .then(() => {
          toast.success(`${tagTypeName} tags saved`);
          setTouched(false);
        })
        .catch((error) => {
          setSaving(false);
          setErrors({ onSave: error.message });
          toast.error(`Saving tags failed: ${error.message}`);
        });
    }
  };

  return (
    <>
      {!user.profile ? (
        <></>
      ) : (
        <OnboardTagsForm
          tags={availableTags}
          tagColor={tagColor}
          title={tagTypeName}
          tagType={tagTypeName}
          icon={icon}
          selectedTags={user.profile.tags.filter((t) => t.type === tagType)}
          profileId={user.profile.id}
          errors={errors}
          selectTag={handleSelectTag}
          removeTag={handleRemoveTag}
          showFilter={showFilter}
        />
      )}

      <PrevNextBtnLgRound
        nextHref={update ? "Update" : nextHref}
        prevHref={update ? "" : prevHref}
        nextTitle={update ? "Update" : nextTitle}
        prevTitle={update ? "" : prevTitle}
        saving={saving}
        saveTags={handleSaveTags}
        update={update}
      />
    </>
  );
};
