import EmployerRoute from "../auth/EmployerRoute";

import { RecruiterPositionTagsPageWrapper } from "../components/pages/user/onboard/positions/RecruiterPositionTagsPageWrapper";
import { UpdateRecruiterPositionsPageWrapper } from "../components/pages/user/onboard/positions/UpdateRecruiterPositionsPageWrapper";
import { RecruiterFirmTypeTagsPageWrapper } from "../components/pages/user/onboard/firmTypes/RecruiterFirmTypeTagsPageWrapper";
import { UpdateRecruiterFirmTypesPageWrapper } from "../components/pages/user/onboard/firmTypes/UpdateRecruiterFirmTypesPageWrapper";
import { RecruiterDivisonTagsPageWrapper } from "../components/pages/user/onboard/divisions/RecruiterDivisionTagsPageWrapper";
import { UpdateRecruiterDivisonsPageWrapper } from "../components/pages/user/onboard/divisions/UpdateRecruiterDivisionsPageWrapper";
import { RecruiterIndustryTagsPageWrapper } from "../components/pages/user/onboard/industries/RecruiterIndustryTagsPageWrapper";
import { OnboardRecruiterSkillTagsPageWrapper } from "../components/pages/user/onboard/skills/OnboardRecruiterSkillTagsPageWrapper";
import { UpdateRecruiterSkillsPageWrapper } from "../components/pages/user/onboard/skills/UpdateRecruiterSkillsPageWrapper";
import { RecruiterQualficationTagsPageWrapper } from "../components/pages/user/onboard/qualifications/RecruiterQualificationTagsPageWrapper";
import { UpdateRecruiterQualficationsPageWrapper } from "../components/pages/user/onboard/qualifications/UpdateRecruiterQualificationsPageWrapper";
import { RecruiterOfficePreferenceTagsPageWrapper } from "../components/pages/user/onboard/office/RecruiterOfficePreferenceTagsPageWrapper";
import { RecruiterCountryTagsPageWrapper } from "../components/pages/user/onboard/countries/RecruiterCountryTagspageWrapper";
import { RecruiterCityTagsPageWrapper } from "../components/pages/user/onboard/cities/RecruiterCityTagsPageWrapper";
import { RecruiterLanguageTagsPageWrapper } from "../components/pages/user/onboard/languages/RecruiterLanguageTagsPageWrapper";
import { RecruiterEducationTagsPageWrapper } from "../components/pages/user/onboard/education/RecruiterEducationTagsPageWrapper";
import { RecruiterContractTagsPageWrapper } from "../components/pages/user/onboard/contracts/RecruiterContractTagsPageWrapper";
import { UpdateRecruiterIndustriesPageWrapper } from "../components/pages/user/onboard/industries/UpdateRecruiterIndutriesPageWrapper";
import { UpdateRecruiterOfficePreferencesPageWrapper } from "../components/pages/user/onboard/office/UpdateRecruiterOffectPreferencesPageWrapper";
import { UpdateRecruiterCountriesPageWrapper } from "../components/pages/user/onboard/countries/UpdateRecruiterCountriesPageWrapper";
import { UpdateRecruiterCitiesPageWrapper } from "../components/pages/user/onboard/cities/UpdateRecruiterCitiesPageWrapper";
import { UpdateRecruiterLanguagesPageWrapper } from "../components/pages/user/onboard/languages/UpdateRecruiterLanguagesPageWrapper";
import { UpdateRecruiterEducationsPageWrapper } from "../components/pages/user/onboard/education/UpdateRecruiterEducationsPageWrapper";
import { UpdateRecruiterContractsPageWrapper } from "../components/pages/user/onboard/contracts/UpdateRecruiterContractsPageWrapper";
import { OnboardRecruiterSalaryPageWrapper } from "../components/pages/user/onboard/salary/OnboardRecruiterSalaryPageWrapper";
import { UpdateRecruiterSalaryPageWrapper } from "../components/pages/user/onboard/salary/UpdateRecruiterSalaryPageWrapper";
import { OnboardRecruiterAvatarPageWrapper } from "../components/pages/user/onboard/recruiter/OnboardAvatarPageWrapper";
import { UpdateRecruiterAvatarPageWrapper } from "../components/pages/user/onboard/recruiter/UpdateRecruiterAvatarPageWrapper";
import { OnboardRecruiterBasicInfoPageWrapper } from "../components/pages/user/onboard/recruiter/OnboardBasicInfoPageWrapper";
import { UpdateRecruiterBasicInfoPageWrapper } from "../components/pages/user/onboard/recruiter/UpdateRecruiterBasicInfoPageWrapper";

export const OnboardRecruiterRoutes = () => {
  return (
    <>
      <EmployerRoute
        path="/onboard/recruiter/basic-info"
        component={OnboardRecruiterBasicInfoPageWrapper}
      />
      <EmployerRoute
        path="/update/recruiter/basic-info"
        component={UpdateRecruiterBasicInfoPageWrapper}
      />

      <EmployerRoute
        path="/onboard/recruiter/positions"
        component={RecruiterPositionTagsPageWrapper}
      />
      <EmployerRoute
        path="/update/recruiter/positions"
        component={UpdateRecruiterPositionsPageWrapper}
      />

      <EmployerRoute
        path="/onboard/recruiter/firmTypes"
        component={RecruiterFirmTypeTagsPageWrapper}
      />
      <EmployerRoute
        path="/update/recruiter/firmTypes"
        component={UpdateRecruiterFirmTypesPageWrapper}
      />

      <EmployerRoute
        path="/onboard/recruiter/divisions"
        component={RecruiterDivisonTagsPageWrapper}
      />
      <EmployerRoute
        path="/update/recruiter/divisions"
        component={UpdateRecruiterDivisonsPageWrapper}
      />

      <EmployerRoute
        path="/onboard/recruiter/industries"
        component={RecruiterIndustryTagsPageWrapper}
      />
      <EmployerRoute
        path="/update/recruiter/industries"
        component={UpdateRecruiterIndustriesPageWrapper}
      />

      <EmployerRoute
        path="/onboard/recruiter/skills"
        component={OnboardRecruiterSkillTagsPageWrapper}
      />
      <EmployerRoute
        path="/update/recruiter/skills"
        component={UpdateRecruiterSkillsPageWrapper}
      />

      <EmployerRoute
        path="/onboard/recruiter/qualifications"
        component={RecruiterQualficationTagsPageWrapper}
      />
      <EmployerRoute
        path="/update/recruiter/qualifications"
        component={UpdateRecruiterQualficationsPageWrapper}
      />

      <EmployerRoute
        path="/onboard/recruiter/office"
        component={RecruiterOfficePreferenceTagsPageWrapper}
      />
      <EmployerRoute
        path="/update/recruiter/office"
        component={UpdateRecruiterOfficePreferencesPageWrapper}
      />

      <EmployerRoute
        path="/onboard/recruiter/countries"
        component={RecruiterCountryTagsPageWrapper}
      />
      <EmployerRoute
        path="/update/recruiter/countries"
        component={UpdateRecruiterCountriesPageWrapper}
      />

      <EmployerRoute
        path="/onboard/recruiter/cities"
        component={RecruiterCityTagsPageWrapper}
      />
      <EmployerRoute
        path="/update/recruiter/cities"
        component={UpdateRecruiterCitiesPageWrapper}
      />

      <EmployerRoute
        path="/onboard/recruiter/languages"
        component={RecruiterLanguageTagsPageWrapper}
      />
      <EmployerRoute
        path="/update/recruiter/languages"
        component={UpdateRecruiterLanguagesPageWrapper}
      />

      <EmployerRoute
        path="/onboard/recruiter/educations"
        component={RecruiterEducationTagsPageWrapper}
      />
      <EmployerRoute
        path="/update/recruiter/educations"
        component={UpdateRecruiterEducationsPageWrapper}
      />

      <EmployerRoute
        path="/onboard/recruiter/contracts"
        component={RecruiterContractTagsPageWrapper}
      />
      <EmployerRoute
        path="/update/recruiter/contracts"
        component={UpdateRecruiterContractsPageWrapper}
      />

      <EmployerRoute
        path="/onboard/recruiter/salary"
        component={OnboardRecruiterSalaryPageWrapper}
      />

      <EmployerRoute
        path="/update/recruiter/salary"
        component={UpdateRecruiterSalaryPageWrapper}
      />

      <EmployerRoute
        path="/onboard/recruiter/avatar"
        component={OnboardRecruiterAvatarPageWrapper}
      />

      <EmployerRoute
        path="/update/recruiter/avatar"
        component={UpdateRecruiterAvatarPageWrapper}
      />
    </>
  );
};
