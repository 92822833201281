import { StaticTagsPage } from "../recruiter/OnboardStaticTagsPage";

export const UpdateRecruiterPositionsPageWrapper = (props) => {
  return (
    <StaticTagsPage
      tagType={"Position"}
      profileType={"recruiter"}
      lead={<>What type of positions do you hire for? </>}
      tagColor={"primary"}
      {...props}
      update={true}
    />
  );
};
