import React, { useState } from "react";
import { Link } from "react-router-dom";

import { NavLink, Tooltip } from "reactstrap";

export const NavMenuRecruiterLinks = ({}) => {
  const [dashboardTooltipOpen, setDashboardTooltipOpen] = useState(false);
  const [profileTooltipOpen, setProfileTooltipOpen] = useState(false);
  const [searchTooltipOpen, setSearchTooltipOpen] = useState(false);
  const [settingsTooltipOpen, setSettingsTooltipOpen] = useState(false);
  const [savedTooltipOpen, setSavedTooltipOpen] = useState(false);
  const [messageTooltipOpen, setMessageTooltipOpen] = useState(false);

  return (
    <>
      {/* <NavLink
        tag={Link}
        className="text-light"
        id="settingsButton"
        to="/settings"
      >
        <i className="fi-settings" />
        <Tooltip
          isOpen={settingsTooltipOpen}
          placement="bottom"
          target="settingsButton"
          toggle={() => {
            setSettingsTooltipOpen(!settingsTooltipOpen);
          }}
        >
          Account Settings
        </Tooltip>
      </NavLink> */}

      <NavLink
        tag={Link}
        className="text-light"
        id="dashboardButton"
        to="/recruiter/dashboard"
      >
        <i className="fi-grid" />
        <Tooltip
          isOpen={dashboardTooltipOpen}
          placement="bottom"
          target="dashboardButton"
          toggle={() => {
            setDashboardTooltipOpen(!dashboardTooltipOpen);
          }}
        >
          Dashboard
        </Tooltip>
      </NavLink>

      <NavLink
        tag={Link}
        className="text-light"
        id="profileButton"
        to="/settings"
      >
        <i className="fi-user" />
        <Tooltip
          isOpen={profileTooltipOpen}
          placement="bottom"
          target="profileButton"
          toggle={() => {
            setProfileTooltipOpen(!profileTooltipOpen);
          }}
        >
          Profile
        </Tooltip>
      </NavLink>

      {/* <NavLink
        tag={Link}
        className="text-light"
        id="messagesButton"
        to="/recruiter/messages"
      >
        <i className="fi-mail" />
        <Tooltip
          isOpen={messageTooltipOpen}
          placement="bottom"
          target="messagesButton"
          toggle={() => {
            setMessageTooltipOpen(!messageTooltipOpen);
          }}
        >
          Messages
        </Tooltip>
      </NavLink>

      <NavLink
        tag={Link}
        className="text-light"
        id="messagesButton"
        to="/slack"
      >
        <i className="fi-slack" />
      </NavLink> */}
    </>
  );
};
