import React, { useState } from "react";
import { Link } from "react-router-dom";
import heroImage1 from "../../../public/images/home/layer01.svg";
import heroImage2 from "../../../public/images/home/layer02.svg";
import heroImage3 from "../../../public/images/home/layer03.svg";
import heroImage4 from "../../../public/images/home/layer04.svg";
import heroImage5 from "../../../public/images/home/layer05.svg";
import heroImage6 from "../../../public/images/home/layer06.svg";
import heroImage7 from "../../../public/images/home/layer07.svg";
import heroImage8 from "../../../public/images/home/layer08.svg";

export const CandidateHomePage = () => {
  const [employerPage, setEmployerPage] = useState(false);

  const toggleEmployer = () => {
    setEmployerPage(!employerPage);
  };
  return (
    <>
      <section className="bg-dark pb-4 pt-5" id="home">
        <div className="container py-5">
          <div className="row align-items-center mt-2 mt-md-0 pt-md-4 pt-lg-5 pb-5 mx-1">
            <div className="col-md-5 order-md-2 mb-5 mb-md-0">
              <div className="parallax mx-auto">
                <div
                  className="parallax-layer position-relative"
                  data-depth="0.1"
                >
                  <img src={heroImage1} alt="Layer" />
                </div>
                <div className="parallax-layer" data-depth="0.16">
                  <img src={heroImage2} alt="Layer" />
                </div>
                <div className="parallax-layer" data-depth="0.38">
                  <img src={heroImage3} alt="Layer" />
                </div>
                <div className="parallax-layer" data-depth="0.16">
                  <img src={heroImage4} alt="Layer" />
                </div>
                <div className="parallax-layer" data-depth="0.16">
                  <img src={heroImage5} alt="Layer" />
                </div>
                {/* <div className="parallax-layer" data-depth="0.45">
                  <img src={heroImage6} alt="Layer" />
                </div> */}
                {/* <div className="parallax-layer" data-depth="0.3">
                  <img src={heroImage7} alt="Layer" />
                </div> */}
                <div className="parallax-layer" data-depth="0.2">
                  <img src={heroImage8} alt="Layer" />
                </div>
              </div>
            </div>

            <div className="col-md-7 order-md-1">
              {employerPage ? (
                <>
                  <h1 className="display-4 text-light pb-2 mb-4 mb-lg-5">
                    Permission Based
                    <span className="text-info-light">
                      {" "}
                      Outreach Automation
                    </span>
                  </h1>
                  <div className="row gy-4 border-bottom border-light mb-5 text-light lead">
                    <p className="mb-0">
                      Your candidate outreach on autopilot. Our profiling system
                      distributes your vacancies to pre-screened professionals
                      based on their level, division, skills, industry, location
                      etc.
                    </p>
                    <p className="">
                      Vacancies are distributed with professionals permission
                      based on their skills and aspirations, improving response
                      rates and quality of inbound applications.
                    </p>
                  </div>
                </>
              ) : (
                <>
                  {/* <h1 className="display-4 text-light pb-2 mb-4 mb-lg-5">
                    Job Messaging...
                    <span className="text-info-light"> On Your Terms.</span>
                  </h1> */}
                  <h1 className="display-4 text-light pb-2 mb-4 mb-lg-5">
                    Skills Based...
                    <span className="text-info-light"> Opportunity Feed.</span>
                  </h1>

                  <div className="row gy-4 border-bottom border-light mb-5">
                    <p className="text-light lead">
                      Create a private profile based on your skills and
                      aspirations to receive highly relevant career
                      opportunities from the best companies, hiring managers and
                      recruiters on the planet.
                    </p>
                  </div>
                </>
              )}
              {/* <h2 className="h5 text-light mb-4">Popular requests:</h2> */}
              <div className="d-flex flex-wrap">
                <h6>
                  {" "}
                  <span className="badge badge-xl bg-primary me-2 mb-2 p-3">
                    <i className="fi-search me-2"></i>
                    <strong>Engineering</strong>
                  </span>
                  <span className="badge badge-xl bg-danger me-2 mb-2 p-3">
                    <i className="fi-search me-2"></i>
                    <strong>Product</strong>
                  </span>
                  <span className="badge badge-xl bg-success me-2 mb-2 p-3">
                    <i className="fi-search me-2"></i>
                    <strong>Design</strong>
                  </span>
                  <span className="badge badge-xl bg-info me-2 mb-2 p-3">
                    <i className="fi-search me-2"></i>
                    <strong>Customer Success</strong>
                  </span>
                  <span className="badge badge-xl bg-translucent-light me-2 mb-2 p-3">
                    <i className="fi-search me-2"></i>
                    <strong>People</strong>
                  </span>
                  <span className="badge badge-xl bg-primary me-2 mb-2 p-3">
                    <i className="fi-search me-2"></i>
                    <strong>Finance</strong>
                  </span>
                  <span className="badge badge-xl bg-danger me-2 mb-2 p-3">
                    <i className="fi-search me-2"></i>
                    <strong>Marketing</strong>
                  </span>
                  <span className="badge badge-xl bg-success me-2 mb-2 p-3">
                    <i className="fi-search me-2"></i>
                    <strong>Sales</strong>
                  </span>
                  <span className="badge badge-xl bg-info me-2 mb-2 p-3">
                    <i className="fi-search me-2"></i>
                    <strong>Talent</strong>
                  </span>
                </h6>
              </div>
              <div className="row gy-4 border-top border-light mt-5 mx-1">
                {employerPage ? (
                  <button
                    className="btn btn-lg btn-rounded btn-success"
                    onClick={() => toggleEmployer()}
                  >
                    I'm a Job Seeker
                  </button>
                ) : (
                  <button
                    className="btn btn-lg btn-rounded btn-success"
                    onClick={() => toggleEmployer()}
                  >
                    I'm an Employer
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
