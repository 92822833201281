import { OnboardRecruiterMultiTagsPage } from "../recruiter/OnboardRecruiterMultiTagsPage";

export const RecruiterCityTagsPageWrapper = (props) => {
  return (
    <OnboardRecruiterMultiTagsPage
      tagType={"City"}
      text={"What cities must candidates be in for office / hybrid roles?"}
      {...props}
    />
  );
};
