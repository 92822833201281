import React from "react";
import { useDispatch } from "react-redux";

import { removeSavedItem } from "../../features/saved/savedFeedItemsSlice";

export const RemoveSavedItemButton = ({ feedItem }) => {
  const dispatch = useDispatch();
  const itemId = feedItem.id;

  return (
    <>
      <button
        className="btn btn-transparent p-0"
        type="button"
        onClick={() => {
          dispatch(removeSavedItem(itemId));
        }}
      >
        <i className="fi-x text-danger"></i>
      </button>
    </>
  );
};
