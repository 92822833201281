import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { NotAuthorized } from "../components/NotAuthorized";
import { Route } from "react-router-dom";

export const CandidateRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn, isCandidate } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn && isCandidate ? <Component {...props} /> : <NotAuthorized />
      }
    />
  );
};
