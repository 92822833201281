import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewPost,
  updatePost,
  fetchPosts,
  selectPostById,
} from "../../../../features/posts/postsSlice";
import { CenteredSingleColumnLayout } from "../../../common/CenteredSingleColumnLayout";
import { DraftEditor } from "../../../common/DraftEditor";
import { toast } from "react-toastify";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";

import { useAuth } from "../../../../contexts/AuthContext";
import { NotAuthorized } from "../../../../components/NotAuthorized";
import { CheckOwnerComponent } from "../../../../auth/CheckOwnerComponent";
import { InfoAlert } from "../../../common/InfoAlert";

export const RecruiterPostPage = ({ match, history }) => {
  const { postId } = match.params;

  const [isOwner, setIsOwner] = useState(false);
  const [loading, setLoading] = useState(true);

  const { user } = useAuth();
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [pitch, setPitch] = useState("");

  const [saving, setSaving] = useState(false);

  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [errors, setErrors] = useState({});

  const [initialFetchCompleted, setInitialFetchCompleted] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const post = useSelector((state) => selectPostById(state, postId));
  const postStatus = useSelector((state) => state.posts.status);

  const [requiredOwnerId, setRequiredOwnerId] = useState();

  const maxCharacters = 750;
  const [currentTextLength, setCurrentTextLength] = useState(0);

  useEffect(() => {
    if (postId && post) {
      setTitle(post.title);
      setPitch(post.pitch);
      setRequiredOwnerId(post.ownerId);
      setLoading(false);
    }
  }, [postId, post]);

  useEffect(() => {
    if (postStatus === "idle") {
      dispatch(fetchPosts());
    }
  }, [postStatus, dispatch]);

  useEffect(() => {
    if (post && post.pitch) {
      if (!initialFetchCompleted) {
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(post.pitch))
          )
        );
        setCurrentTextLength(post.pitch.length);
        setPitch(post.pitch);
        setInitialFetchCompleted(true);
      }
    }
  }, [post, initialFetchCompleted]);

  const [prevContentState, setPrevContentState] = useState(
    editorState.getCurrentContent()
  );

  const handlePitchChange = (newEditorState) => {
    const currentContent = newEditorState.getCurrentContent();
    const currentText = currentContent.getPlainText("");

    if (currentText.length <= maxCharacters) {
      setPrevContentState(currentContent); // Save the current content state
      setEditorState(newEditorState);
      setCurrentTextLength(currentText.length);
      convertContentToHTML();
    } else {
      // If the text exceeds the character limit, restore the previous content state
      setEditorState(EditorState.createWithContent(prevContentState));
    }
  };

  const handleTitleChange = (e) => setTitle(e.target.value);

  const convertContentToHTML = () => {
    const extractedHTML = extractHTML();
    setPitch(extractedHTML);
  };

  // Get HTML from editor for saving to db
  const extractHTML = () => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlToSanitize = draftToHtml(rawContentState);
    return sanitize(htmlToSanitize);
  };

  // Sanitize any bad input from the editor
  const sanitize = (html) => {
    return DOMPurify.sanitize(html);
  };

  const canSave = title && pitch && addRequestStatus === "idle";

  const handleSave = async (event) => {
    event.preventDefault();

    if (canSave) {
      try {
        setAddRequestStatus("pending");
        setSaving(true);

        if (post && post.id) {
          const postToUpdateId = post.id;
          await dispatch(updatePost({ postToUpdateId, title, pitch }))
            .unwrap()
            .then((response) => {
              toast.success("Post Updated");
              setTitle("");
              setPitch("");
              history.push(
                `/recruiter/posts/${response.id}/profile/${response.profileId}/positions`
              );
            });
        } else {
          await dispatch(addNewPost({ title, pitch }))
            .unwrap()
            .then((response) => {
              toast.success("Post Created");
              setTitle("");
              setPitch("");
              history.push(
                `/recruiter/posts/${response.id}/profile/${response.profile.id}/positions`
              );
            });
        }
      } catch (error) {
        toast.error("Failed to add post. Please try again");
        setErrors({ onSave: error.message });
        console.error("Failed to add post.");
      } finally {
        setAddRequestStatus("idle");
        setSaving(false);
      }
    }
  };

  return (
    <>
      {!loading && postId && !post ? (
        <NotAuthorized />
      ) : (
        <CheckOwnerComponent requiredOwnerId={requiredOwnerId}>
          <CenteredSingleColumnLayout>
            <InfoAlert
              lead="Craft and engaging message."
              text={
                <>
                  Write a post and attach a skills profile. The post will then
                  be distributed to all candidates who have a skills profile
                  that matches your requirements. Candidates will then engage
                  with you directly by responding to your post. You will receive
                  an email update when candidates engage with you.
                </>
              }
            />
            <form onSubmit={handleSave}>
              <input
                className="form-control form-control-lg mb-2"
                type="text"
                name="postTitle"
                value={title}
                placeholder="Enter a title for your post"
                onChange={handleTitleChange}
              />

              <div className="d-flex align-items-center mb-3">
                <DraftEditor
                  editorState={editorState}
                  handleEditorChange={handlePitchChange}
                  currentTextLength={currentTextLength}
                  maxLength={maxCharacters}
                  placeholder={<>Format your post here.</>}
                />
              </div>

              <p className="d-flex justify-content-end">
                <em>
                  {maxCharacters - currentTextLength} / {maxCharacters}{" "}
                  remaining
                </em>
              </p>

              <input
                type="submit"
                className="btn btn-success btn-lg ms-sm-auto w-100"
                value={saving ? "Posting" : "Post"}
                disabled={saving}
              />
            </form>
          </CenteredSingleColumnLayout>
        </CheckOwnerComponent>
      )}
    </>
  );
};
