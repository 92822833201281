import { OnboardMultiTagsPage } from "../OnboardMultiTagsPage";

export const UpdateCitiesPageWrapper = (props) => {
  return (
    <OnboardMultiTagsPage
      tagType={"City"}
      lead={
        "What cities can you base yourself for office / hybrid opportunties?"
      }
      text={"Add cities that you currently live or are happy to relocate to."}
      {...props}
      update={true}
    />
  );
};
