import { OnboardStaticTagsPage } from "../OnboardStaticTagsPage";

export const UpdateIndustriesPageWrapper = (props) => {
  return (
    <OnboardStaticTagsPage
      tagType={"Industry"}
      lead={"Are you only interested in particular industries? "}
      tagColor={"info"}
      text={
        <>
          Selecting industries will reduce the number of opportunities you
          receive. If you work in an industry that your experience in that
          industry is likely to be mandatory you should ensure you select it.
        </>
      }
      {...props}
      update={true}
    />
  );
};
