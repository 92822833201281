import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";

import * as remoteClient from "../../dataServices/remoteClient";

const feedAdapter = createEntityAdapter({
  selectId: (entity) => entity.document.id,
});

const initialState = feedAdapter.getInitialState({
  status: "idle",
  saveStatus: "idle",
  updateStatus: "idle",
  firstFetchComplete: false,
  currentPage: 1,
  error: null,
  totalCount: 0,
  facets: [],
  selectedCheckboxes: [],
  searchDto: {
    currentPage: 1,
    since: 0,
    industries: [],
    firmTypes: [],
    locations: [],
    sectors: [],
  },
});

export const fetchFeed = createAsyncThunk(
  "feed/fetchFeed",
  async (searchDto) => {
    return await remoteClient.post(`/api/feed/`, searchDto);
  }
);

export const updateFeed = createAsyncThunk(
  "feed/updateFeed",
  async (searchDto) => {
    return await remoteClient.post(`/api/feed/`, searchDto);
  }
);

export const toggleSavedItem = createAsyncThunk(
  "feed/toggleSavedItem",
  async (itemId) => {
    return await remoteClient.post(`/api/savedfeeditems/${itemId}`, null);
  }
);

const updateArrayInState = (state, action, arrayName) => {
  const updatedArray = [...state.searchDto[arrayName]];
  const index = updatedArray.indexOf(action.payload);

  if (index !== -1) {
    // Remove the item if it exists
    updatedArray.splice(index, 1);
  } else {
    // Add the item if it doesn't exist
    updatedArray.push(action.payload);
  }

  return {
    ...state,
    searchDto: {
      ...state.searchDto,
      [arrayName]: updatedArray,
    },
  };
};

const feedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {
    updateIndustries: (state, action) =>
      updateArrayInState(state, action, "industries"),
    updateLocations: (state, action) =>
      updateArrayInState(state, action, "locations"),
    updateSectors: (state, action) =>
      updateArrayInState(state, action, "sectors"),
    updateFirmTypes: (state, action) =>
      updateArrayInState(state, action, "firmTypes"),
    setSelectedCheckboxes: (state, action) => {
      const { value, filterType } = action.payload;
      const selectedCheckbox = `${filterType}_${value}`;
      if (state.selectedCheckboxes.includes(selectedCheckbox)) {
        // If already selected, remove it
        state.selectedCheckboxes = state.selectedCheckboxes.filter(
          (item) => item !== selectedCheckbox
        );
      } else {
        // If not selected, add it
        state.selectedCheckboxes.push(selectedCheckbox);
      }
    },
    setSince: (state, action) => {
      state.searchDto.since = action.payload;
    },
    setSearchDtoSince: (state, action) => {
      state.searchDto.since = action.payload;
    },
    clearFilters: (state) => {
      state.selectedCheckboxes = [];
      state.searchDto = initialState.searchDto;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFeed.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFeed.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.updateStatus = "succeeded";
        state.currentPage = action.payload.currentPage;
        state.totalCount = action.payload.count;
        state.facets = action.payload.facets;
        feedAdapter.setAll(state, action.payload.results);
        state.firstFetchComplete = true;
      })
      .addCase(toggleSavedItem.pending, (state, action) => {
        state.saveStatus = "loading";
        const itemId = action.meta.arg; // Get the itemId passed to the thunk
        const existingItem = state.entities[itemId];
        if (existingItem) {
          existingItem.document.saved = !existingItem.document.saved;
        }
      })
      .addCase(toggleSavedItem.fulfilled, (state, action) => {
        state.saveStatus = "succeeded";
      })
      .addCase(updateFeed.pending, (state) => {
        state.updateStatus = "loading";
      })
      .addCase(updateFeed.fulfilled, (state, action) => {
        state.updateStatus = "succeeded";
        state.currentPage = action.payload.currentPage;
        state.totalCount = action.payload.count;
        state.facets = action.payload.facets;
        feedAdapter.setAll(state, action.payload.results);
      });
  },
});

export const {
  updateIndustries,
  updateSectors,
  updateFirmTypes,
  updateLocations,
  setSelectedCheckboxes,
  setSearchDtoSince,
  clearFilters,
} = feedSlice.actions;

export default feedSlice.reducer;

export const {
  selectAll: selectAllFeedItems,
  selectById: selectFeedItemById,
  selectIds: selectFeedItemIds,
} = feedAdapter.getSelectors((state) => state.feed);
