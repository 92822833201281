export const FullPageExternalJobModal = ({ title, extUrl }) => {
  return (
    <div
      className="modal fade"
      id="modalFullScreen"
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-fullscreen" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
            <button
              className="btn-close"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <iframe src={extUrl} className="h-100" />
          </div>
        </div>
      </div>
    </div>
  );
};
