import { handleResponse, handleError } from "./dataUtils";

export async function subscribeToNewsletter(signupDto, subscriberType) {
  return fetch(`/api/newslettersubscriber/${subscriberType}`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json",
    },
    method: "POST",

    body: JSON.stringify(signupDto),
  })
    .then(handleResponse)
    .catch(handleError);
}
