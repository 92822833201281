import React from "react";

export const CookiesTab = () => (
  <>
    <div className="mb-md-4 mb-3 fs-sm">
      <h3 className="mt-4">Cookie Policy</h3>

      <p className="mt-4">
        We’ve prepared this Cookie Policy to help you better understand and to
        feel more confident about how we use cookies on our site located at:
        https://sourcers.co. We have tried to keep this document as short as we
        legally can. However, the full text wording is important and legally
        binding.
      </p>

      <p className="mt-4">
        Please read the following carefully to understand our views and
        practices regarding cookies and how we use them when you visit our site.
      </p>

      <h4 className="mt-4">What Is A Cookie</h4>

      <p className="mt-4">
        We use various technologies on our site to collect information that
        helps us improve your online experience. We refer to these technologies
        which include cookies, collectively as “cookies”. This Cookie Policy
        explains the different types of cookies used on our site and how you can
        control them.
      </p>

      <p className="mt-4">
        A cookie is a small file of letters and numbers that we store on your
        browser or the hard drive of your device. Cookies are widely used
        throughout the Internet in order to make sites or other online services
        work or to be better or more efficient. They can do this because sites
        and other online services can read and write to the cookies stored on
        your device, enabling them to recognise you and remember important
        information that will make your use of them more convenience, for
        example, by remembering your preferences, username, or showing you pages
        that you seemed to have a particular interest in.
      </p>

      <p className="mt-4">
        If you consent to us storing cookies on your computer or other
        electronic device then you agree that we can store and access cookies as
        described in this policy.
      </p>

      <h4 className="mt-4">How You Consent To Us Placing Cookies</h4>
      <p className="mt-4">
        When you visit our site you should see a overlay at the bottom of your
        screen directing you to this Cookie Policy and our Privacy Policy.
      </p>

      <p className="mt-4">
        By clicking or selecting the “Accept” button that appears in the overlay
        then you are demonstrating to us that you are freely giving us informed
        and specific consent for us to place cookies on your device for the
        purposes specified in this Cookie Policy and you are accepting and
        consenting to the practices described.
      </p>

      <p className="mt-4">
        If you do not click or select the “I Agree” button that appears in the
        [pop-up OR overlay] then we will not place cookies on your device.
      </p>

      <p className="mt-4">
        The duration of each cookie that we may place on your computer can be
        found in our Retention Policy [HERE] .
      </p>

      <p className="mt-4">
        If you do not see a overlay at the bottom of your screen then you may
        have already accepted our Cookie Policy and our Privacy Policy. You may
        also be using a pop-up blocker or similar tools that may prevent this
        policy from being brought to your attention. Please ensure that any
        pop-up blockers are disabled when you use our site. If you’re unsure
        then scroll down to the bottom of this page where we explain how you can
        manage your cookies.
      </p>

      <h4 className="mt-4">What Do You Use Cookies For?</h4>
      <p className="mt-4">
        Our site uses cookies to distinguish you from other users of our site
        and to save and retain certain parameters about you and your usage of
        our site. This helps us to provide you with a good experience when you
        browse our site and also allows us to improve our site.
      </p>

      <p className="mt-4">
        There are three different categories of cookies that we may use on our
        site:
      </p>

      <ol className="mt-4">
        <li>
          <strong>Essential Cookie</strong>
          <br />
          These are cookies that are essential for the operation of our site.
          They include, for example, cookies that enable you to log into secure
          areas of our site or make use of e-billing services. We also use
          cookies to prevent fraudulent use of your login credentials. These
          cookies are essential for using our site and, therefore, if disabled
          can severely affect your use of our site
        </li>

        <li>
          <strong>Analytical / Performace Cookies</strong>
          Performance cookies allow us to recognise and count the number of
          visitors and to see how visitors move around our site when they are
          using it. This helps us to improve the way our site works, for
          example, by ensuring that users are finding what they are looking for
          easily. These cookies also allow us to see overall patterns of usage
          on our site and help us record any difficulties you may have with our
          site
        </li>
        <li>
          <strong>Functionality Cookies</strong>
          In some circumstances we may use functionality cookies. These are used
          to recognise you when you return to our site or provide enhanced and
          more personalised features, for example, to personalise our content
          for you, greet you by name and remember your preferences (for example,
          your choice of language or region)
        </li>
      </ol>

      <p className="mt-4">
        If you would like more information about cookies and how you can manage
        the settings on your computer, you can visit
        http://www.allaboutcookies.org/manage-cookies/.
      </p>

      <h4 className="mt-4">Cookies We Use</h4>

      <p className="mt-4">
        You can find more information about the individual cookies we use on our
        site and the purposes for which we use them in the table below:
      </p>

      <table className="table mt-4">
        <thead>
          <tr>
            <th>Cookie Name</th>
            <th>Type</th>
            <th>Purpose</th>
            <th>Content</th>
            <th>Provider</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>AspNetCore.Antiforgery</td>
            <td>Essential (Security)</td>
            <td>
              To prevent cross site scripting attacks. To ensure that only you
              can post content to the account associated with you.
            </td>
            <td>Randomly Generated letters and numbers</td>
            <td>sourcers.co</td>
          </tr>
          <tr>
            <td>ARRAInfinity</td>
            <td>Functional (Load Balancing / Performance)</td>
            <td>To improve the performace of the site by load balancing</td>
            <td>Randomly Generated letters and numbers</td>
            <td>sourcers.co</td>
          </tr>
          <tr>
            <td>AspNetCore.Identity</td>
            <td>Essential (Authorisation)</td>
            <td>
              To identify you as a user and ensure only you can access your
              account. To provide tailored content when you are logged in.
            </td>
            <td>Randomly Generated letters and numbers</td>
            <td>sourcers.co</td>
          </tr>
          <tr>
            <td>AspNetCore.Consent</td>
            <td>Essential (Cookie Consent)</td>
            <td>
              To confirm that you have given consent for cookies to be used on
              the site.
            </td>
            <td>yes or no</td>
            <td>sourcers.co</td>
          </tr>
        </tbody>
      </table>

      <p className="mt-4">
        The cookies used by Google Analytics are used to collect information
        about how you use our site. We use this information to compile reports
        and to help us improve our site. The cookie collects information in an
        anonymous form, including the number of visitors to our site, where
        visitors have come to the site from and the pages that they visited.
      </p>

      <p className="mt-4">
        You can read more about Google’s overview of privacy and safeguarding
        your data at https://support.google.com/analytics/answer/6004245.
      </p>

      <h5 className="mt-4">Third Parties</h5>

      <p className="mt-4">
        Please note that third parties (including, for example, web traffic
        analysis services such as Google Analytics) may also use cookies, over
        which we have no control.
      </p>

      <h4 className="mt-4">How Do I Control Cookies</h4>

      <p className="mt-4">
        You can block cookies by activating the setting on your browser that
        allows you to refuse the setting of all or some cookies. However, if you
        use your browser settings to block all cookies (including essential
        cookies) you may not be able to access all or parts of our site. You may
        also delete any cookies stored on your computer at any time.
      </p>

      <p className="mt-4">
        You can find out more about changing cookie settings on your computer by
        visiting http://www.allaboutcookies.org/manage-cookies/.
      </p>

      <h4 className="mt-4">How Long Is Cookie Information Stored</h4>

      <p className="mt-4">
        Different cookies may be stored for different periods of time. In many
        cases these cookies are updated automatically each time you visit our
        site or may expire and be deleted by your computer automatically. It is
        important to understand that when a cookie is placed on your computer it
        will reside on your hard drive until it expires and is deleted or it may
        reside on your hard drive until you manually delete it – this entirely
        depends on your individual browser settings and we do not have control
        over this.
      </p>

      <p className="mt-4">
        The duration of each cookie that we may place on your computer can be
        found in our Retention Policy .
      </p>

      <p className="mt-4">
        Please remember that you can delete or change the way in which you store
        cookies on your computer at any time.
      </p>

      <h4 className="mt-4">Changes To Our Cookies Policy</h4>

      <p className="mt-4">
        We may need to change this Cookie Policy if it’s necessary for legal
        reasons or to reflect changes to our services or the purpose for which
        we use cookies. In any case, the provisions of this Cookie Policy may be
        changed without prejudice to your rights. When we change our Cookie
        Policy we will make the updated Cookie Policy available on our site and
        we will also update the “Last Updated” date at the top of this page.
      </p>

      <p className="mt-4">
        Any changes to our Cookie Policy will come into effect 30 days after we
        post it on our site. During that period you’re welcome to contact us if
        you have questions about the changes. If you have an account for our
        site then we may reset your login and seek new consent if there is a
        change in the purpose for which we may use cookies.
      </p>

      <h4 className="mt-4">
        Please also check out our Privacy Policy which gives more information
        about how we protect your privacy.
      </h4>
    </div>
  </>
);
