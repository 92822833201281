export const UniqueCitiesBadges = ({ cities }) => {
  const allCitiesSet = new Set(); // Use a Set to store unique cities across all items

  // Check if cities is a valid array
  if (!cities || !Array.isArray(cities)) {
    // Handle the case when cities is not a valid array
    return null;
  }

  // Filter out null values from the array
  const validCities = cities.filter(
    (city) => city !== null && city !== undefined
  );

  // Loop through each city name and accumulate them in allCitiesSet
  validCities.forEach((city) => {
    // Check if city.name is defined
    if (city && city.name !== null && city.name !== undefined) {
      const splitCities = city.name.split(/[;,-/•]/);

      for (let i = 0; i < splitCities.length; i++) {
        const trimmedCity = splitCities[i].trim().replace(/\s*\([^)]*\)/, "");
        if (trimmedCity) {
          allCitiesSet.add(trimmedCity); // Add city to the Set, duplicates will be automatically ignored
        }
      }
    }
  });

  const uniqueCitiesArray = Array.from(allCitiesSet); // Convert Set back to an array

  if (uniqueCitiesArray.length > 0) {
    return uniqueCitiesArray.map((city) => (
      <span key={city} className="badge badge-lg bg-primary me-1 mb-1">
        {city}
      </span>
    ));
  } else {
    // Handle the case when there are no valid cities
    return null;
  }
};
