import { useState } from "react";

import { PostResponseCandidateMessageCard } from "./posts/PostResponseCandidateMessageCard";
import { PostResponseComponent } from "./posts/PostResponseComponent";
import { CommentEditor } from "./CommentEditor";

export const PostCardPostResponse = ({
  response,
  editorState,
  handleCommentChange,
  handleSendMessage,
}) => {
  const [show, setShow] = useState(false);
  return (
    <>
      {response && (
        <PostResponseCandidateMessageCard candidate={response.candidate} />
      )}
    </>
  );
};
