import { OnboardStaticTagsPage } from "../OnboardStaticTagsPage";

export const UpdateFirmTypesPageWrapper = (props) => {
  return (
    <OnboardStaticTagsPage
      tagType={"FirmType"}
      lead={`What type of companies do you want to receive messages from?`}
      tagColor={"danger"}
      {...props} // Pass any additional props
      update={true}
    />
  );
};
