import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  fetchRecruiter,
  updateRecruiterAvatar,
} from "../../../../../features/recruiter/recruiterSlice";

import { toast } from "react-toastify";

import { PrevNextBtnLgRound } from "../PrevNextButtonLgRound";
import { InfoAlert } from "../../../../common/InfoAlert";
import { CenteredSingleColumnLayout } from "../../../../common/CenteredSingleColumnLayout";

export const UpdateRecruiterAvatar = ({ update }) => {
  const recruiter = useSelector((state) => state.recruiter.recruiter);
  const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const [avatarToUpdate, setAvatarToUpdate] = useState(null);

  const [selectedFile, setSelectedFile] = useState();
  const [fileName, setFileName] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const [preview, setPreview] = useState();

  useEffect(() => {
    if (recruiter && recruiter.avatar) {
      setAvatarToUpdate(recruiter.avatar);
    }
  }, [recruiter]);

  useEffect(() => {
    if (recruiter === null) {
      dispatch(fetchRecruiter());
    }
  }, [recruiter, dispatch]);

  const handleBlobChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
    setIsFilePicked(true);
  };

  useEffect(() => {
    // Create the preview
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile); // Use selectedFile here
      setPreview(objectUrl);

      // Free memory when the component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  const handleSubmit = () => {
    setAddRequestStatus("pending");

    var formData = new FormData();

    formData.append("blob", selectedFile);
    formData.append("fileName", fileName);

    const recruiterId = recruiter.id;

    if (selectedFile) {
      dispatch(updateRecruiterAvatar({ recruiterId, formData }))
        .then(() => {
          toast.success("Avatar Updated");
        })
        .catch((error) => {
          setAddRequestStatus("idle");
          setErrors({ onSave: error.message });
          toast.error("Saving business failed:- ", error.message);
        })
        .finally(() => {
          setAddRequestStatus("idle");
          history.push("/recruiter/dashboard");
        });
    } else {
      history.push("/recruiter/dashboard");
    }
  };

  return (
    <>
      <CenteredSingleColumnLayout>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-8 col-md-6">
              <InfoAlert text="Upload an image of you or logo of the organization you represent. If you have uploaded a business logo separately and use the same one, it will duplicate on posts." />
              <div className="card gallery">
                {preview && (
                  <img
                    src={preview}
                    alt="Gallery thumbnail"
                    className="square-preview"
                  />
                )}
                {!selectedFile && recruiter && recruiter.avatar && (
                  <img
                    src={recruiter.avatar.location}
                    alt="Gallery thumbnail"
                    className="square-preview"
                  />
                )}
                <div className="card-body">
                  <input
                    type="file"
                    className="form-control mb-2"
                    name="file"
                    onChange={handleBlobChange}
                  />
                </div>
              </div>
              {update && (
                <PrevNextBtnLgRound
                  nextTitle={"Save"}
                  saving={saving}
                  saveTags={() => handleSubmit()}
                  update={update}
                />
              )}
            </div>
          </div>
        </form>
        {!update && (
          <PrevNextBtnLgRound
            nextTitle={"Dashboard"}
            prevTitle={"Salary"}
            prevHref={"salary"}
            saving={saving}
            saveTags={() => handleSubmit()}
          />
        )}
      </CenteredSingleColumnLayout>
    </>
  );
};
