import React from "react";

export const FlashLogoCenter = () => {
  return (
    <>
      <div className="bg-dark d-flex justify-content-center align-items-center h-100">
        <img
          src="https://busoca.blob.core.windows.net/images/sourcers.png"
          alt="Logo"
          width="184"
        />
      </div>
    </>
  );
};
