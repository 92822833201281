import React from "react";
import { MatchesPage } from "../components/pages/matches/MatchesPage";
import { CandidateRoute } from "../auth/CandidateRoute";
import { MatchEngagementPage } from "../components/pages/matches/MatchEngagementPage";

export const CandidateRoutes = () => {
  return (
    <>
      <CandidateRoute path="/matches" component={MatchesPage} exact />

      <CandidateRoute
        path="/matches/:postId/engage"
        component={MatchEngagementPage}
      />
    </>
  );
};
