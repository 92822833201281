import React from "react";

import { SourcersTextInput } from "../../common/SourcersTextInput";

const NewsLetterSignUpForm = ({
  signupDto,
  onSave,
  onChange,
  saving = false,
  type = 0,
  errors = {},
}) => {
  return (
    <form onSubmit={onSave}>
      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}

      {/* <input type="hidden" name="test" value={type} /> */}

      <SourcersTextInput
        name="email"
        placeholder="Enter your email"
        value={signupDto.email}
        onChange={onChange}
        error={errors.email}
      />

      <SourcersTextInput
        name="firstName"
        placeholder="Enter your first name"
        value={signupDto.firstName}
        onChange={onChange}
        error={errors.firstName}
      />

      <SourcersTextInput
        name="lastName"
        placeholder="Enter your last name"
        value={signupDto.lastName}
        onChange={onChange}
        error={errors.lastName}
      />

      <SourcersTextInput
        name="company"
        placeholder="Enter your copmany name"
        value={signupDto.company}
        onChange={onChange}
        error={errors.company}
      />

      <SourcersTextInput
        name="position"
        placeholder="Enter your job title"
        value={signupDto.position}
        onChange={onChange}
        error={errors.position}
      />

      <button
        type="submit"
        disabled={saving}
        className="btn btn-primary btn-lg d-block w-100 mt-5"
      >
        {saving ? "Saving..." : "Save"}
      </button>
    </form>
  );
};

export default NewsLetterSignUpForm;
