import React, { useState, useEffect, useContext } from "react";
import * as userService from "../dataServices/userService";
import { FlashLogoCenter } from "../components/common/FlashLogoCenter";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider(props) {
  const [authUser, setAuthUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [logoutUrl, setLogoutUrl] = useState("/bff/logout");
  const [name, setName] = useState("");
  const [isPayingUser, setIsPayingUser] = useState(false);
  const [isEmployer, setIsEmployer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isCandidate, setIsCandidate] = useState(false);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    async function fetchUserFromBff() {
      try {
        const response = await userService.getUserSession();

        if (response.ok && response.status === 200) {
          const data = await response.json();

          const logoutUrl =
            data.find((claim) => claim.type === "bff:logout_url")?.value ??
            "/bff/logout";

          const userName =
            data.find((claim) => claim.type === "name")?.value ?? "";

          const userId =
            data.find((claim) => claim.type === "sub")?.value ?? "";

          const payingUser =
            data.find(
              (claim) => claim.type === "role" && claim.value === "PayingUser"
            )?.value ?? isPayingUser;

          const employer =
            data.find(
              (claim) => claim.type === "role" && claim.value === "Employer"
            )?.value ?? isEmployer;

          const candidate =
            data.find(
              (claim) => claim.type === "role" && claim.value === "Candidate"
            )?.value ?? isCandidate;

          const givenNameClaim = data.find(
            (claim) => claim.type === "given_name"
          );

          const firstName = givenNameClaim ? givenNameClaim.value : userName;

          const familyNameClaim = data.find(
            (claim) => claim.type === "family_name"
          );

          const lastName = familyNameClaim ? familyNameClaim.value : "";

          setIsLoggedIn(true);
          setLogoutUrl(logoutUrl);
          setUser(data);
          setName(userName);
          setIsPayingUser(payingUser);
          setFirstName(firstName);
          setLastName(lastName);
          setIsCandidate(candidate);
          setIsEmployer(employer);
          setUserId(userId);
        }
      } catch (e) {
        console.error(e);
        setIsLoggedIn(false);
        setIsPayingUser(false);
        setName("");
        setLogoutUrl("/bff/logout");
        setUser(null);
        setFirstName("");
        setLastName("");
        setIsCandidate(false);
        setIsEmployer(false);
        setUserId("");
      } finally {
        setIsLoading(false);
      }
    }

    fetchUserFromBff();
  }, [isCandidate, isPayingUser, isEmployer, userId]);

  const value = {
    authUser,
    setAuthUser,
    isLoggedIn,
    setIsLoggedIn,
    user,
    setUser,
    logoutUrl,
    setLogoutUrl,
    name,
    setName,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    isPayingUser,
    setIsPayingUser,
    isCandidate,
    setIsCandidate,
    isEmployer,
    setIsEmployer,
    userId,
    setUserId,
  };

  return isLoading ? (
    <FlashLogoCenter />
  ) : (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}
