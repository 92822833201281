import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import * as remoteClient from "../../dataServices/remoteClient";

const tagsAdapter = createEntityAdapter();

const initialState = tagsAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchTags = createAsyncThunk("tag/fetchTags", async () => {
  return await remoteClient.get("/api/tags/static");
});

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTags.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTags.fulfilled, (state, action) => {
        state.status = "succeeded";
        //console.log("Succeeded: ", JSON.stringify(action.payload));
        tagsAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchTags.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default tagsSlice.reducer;

export const {
  selectAll: selectAllTags,
  selectById: selectTagById,
  selectIds: selectTagIds,
} = tagsAdapter.getSelectors((state) => state.tags);

export const selectTagsByType = createSelector(
  [selectAllTags, (state, tagType) => tagType],
  (tags, tagType) => tags.filter((tag) => tag.type === tagType)
);
