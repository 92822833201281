import React, { useEffect } from "react";
import { DraftEditor } from "../../../../common/DraftEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const BasicInfoForm = ({
  user,
  onSave,
  onChange,
  editorState,
  onPitchChange,
  saving = false,
  errors = {},
  maxCharacters,
  currentTextLength,
  update,
}) => {
  return (
    <form onSubmit={onSave}>
      <div className="bg-light rounded-3 mb-3">
        <h2 className="h4 mb-4">
          <i className="fi-user text-primary fs-5 mt-n1 me-2"></i>
          {user.id ? "Update" : "Create"} Profile
        </h2>

        {user.id && <input type="hidden" name="id" value={user.id} />}

        <div className="row">
          <div className="col-sm-6 mb-4">
            <label className="form-label" htmlFor="firstName">
              First name <span className="text-danger">*</span>
            </label>
            <input
              className="form-control form-control-lg"
              type="text"
              name="firstName"
              id="firstName"
              value={user.firstName}
              placeholder="Enter your first name"
              onChange={onChange}
            />
            {errors.firstName && (
              <p className="text-danger">{errors.firstName}</p>
            )}
          </div>
          <div className="col-sm-6 mb-4">
            <label className="form-label" htmlFor="lastName">
              Second name <span className="text-danger">*</span>
            </label>
            <input
              className="form-control form-control-lg"
              type="text"
              name="lastName"
              id="lastName"
              value={user.lastName}
              placeholder="Enter your second name"
              onChange={onChange}
            />
            {errors.lastName && (
              <p className="text-danger">{errors.lastName}</p>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 mb-4">
            <label className="form-label" htmlFor="title">
              Job Title <span className="text-danger">*</span>
            </label>
            <input
              className="form-control form-control-lg"
              type="text"
              name="title"
              id="title"
              value={user.title}
              placeholder="Enter your job title"
              onChange={onChange}
            />
          </div>
          <div className="col-sm-6 mb-4">
            <label className="form-label" htmlFor="employer">
              Employer <span className="text-danger">*</span>
            </label>
            <input
              className="form-control form-control-lg"
              type="text"
              name="employer"
              id="employer"
              value={user.employer}
              placeholder="Enter your current employer"
              onChange={onChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 mb-4">
            <label className="form-label" htmlFor="telephone">
              Telephone <span className="text-danger"></span>
            </label>
            <input
              className="form-control form-control-lg"
              type="text"
              name="telephone"
              id="telephone"
              value={user.telephone}
              placeholder="Enter your phone number"
              onChange={onChange}
            />
          </div>
        </div>

        <div className="pb-4 mb-2">
          <label className="form-label fw-bold mb-3">Pitch / Summary</label>
          <div className="d-flex align-items-center mb-3" id="pitch">
            <DraftEditor
              editorState={editorState}
              handleEditorChange={onPitchChange}
              placeholder={
                <>
                  This is your default pitch when you engage with reruiters /
                  hiring managers. It's the only thing people will see when you
                  engage with them. We recommend adding key achivements, what
                  sets you apart.
                  <br></br>
                  <br></br> You can edit / update / personalise at the point of
                  engagement.
                  <br></br>
                  <br></br> Get in touch if you would like any assistance.
                </>
              }
            />
          </div>
          <p className="d-flex justify-content-end">
            <em>
              {maxCharacters - currentTextLength} / {maxCharacters} remaining
            </em>
          </p>
        </div>

        <div className="pb-4 mb-2">
          <div className="form-label fw-bold mb-3">Socials</div>

          <div className="d-flex align-items-center mb-3">
            <div className="btn btn-icon btn-light btn-xs shadow-sm rounded-circle pe-none flex-shrink-0 me-3">
              <i className="fi-linkedin text-body"></i>
            </div>
            <label htmlFor="linkedIn" />
            <input
              className="form-control"
              type="text"
              placeholder="Your LinkedIn account"
              name="linkedIn"
              id="linkedIn"
              value={user.linkedIn}
              onChange={onChange}
            />
          </div>
          <div className="d-flex align-items-center mb-3">
            <div className="btn btn-icon btn-light btn-xs shadow-sm rounded-circle pe-none flex-shrink-0 me-3">
              <FontAwesomeIcon icon="fa-brands fa-x-twitter text-body" />
            </div>
            <label htmlFor="twitter" />

            <input
              className="form-control"
              type="text"
              placeholder="Your Twitter account"
              name="twitter"
              id="twitter"
              value={user.twitter}
              onChange={onChange}
            />
          </div>
          <div className="d-flex align-items-center mb-3">
            <div className="btn btn-icon btn-light btn-xs shadow-sm rounded-circle pe-none flex-shrink-0 me-3">
              <i className="fi-globe text-body"></i>
            </div>
            <label htmlFor="webSiteUrl" />

            <input
              className="form-control"
              type="text"
              placeholder="Your Personal Website"
              name="webUrl"
              id="webSiteUrl"
              value={user.webUrl}
              onChange={onChange}
            />
          </div>
          <div className="d-flex align-items-center mb-3">
            <div className="btn btn-icon btn-light btn-xs shadow-sm rounded-circle pe-none flex-shrink-0 me-3">
              <i className="fi-github text-body"></i>
            </div>
            <label htmlFor="github" />

            <input
              className="form-control"
              type="text"
              placeholder="Your Github account"
              name="gitHub"
              id="github"
              value={user.gitHub}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-column flex-sm-row bg-light rounded-3">
        {update ? (
          <input
            type="submit"
            className="btn btn-primary btn-lg rounded-pill ms-sm-auto"
            value={saving ? "Updating" : "Update"}
            disabled={saving}
          />
        ) : (
          <input
            type="submit"
            className="btn btn-success btn-lg rounded-pill ms-sm-auto"
            value={saving ? "Saving" : "Save"}
            disabled={saving}
          />
        )}
      </div>
    </form>
  );
};
