import { OnboardStaticTagsPage } from "../OnboardStaticTagsPage";

export const FirmTypesTagsPageWrapper = (props) => {
  return (
    <OnboardStaticTagsPage
      tagType={"FirmType"}
      lead={`What type of companies do you want to receive messages from?`}
      tagColor={"danger"}
      {...props} // Pass any additional props
    />
  );
};
