import "bootstrap";

import React from "react";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
//import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

//Redux
import store from "./app/store";
import { Provider } from "react-redux";

//Mock API
//import { worker } from "./api/server";

//Fetch Users here since we only need them once
import { fetchUser } from "./features/user/userSlice";
import { fetchRecruiter } from "./features/recruiter/recruiterSlice";
import { createRoot } from "react-dom/client";

import { useAuth } from "./contexts/AuthContext";

import "./css/theme.css";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

async function start() {
  //start mock API server
  //await worker.start({ onUnhandledRequest: "bypass" });

  //store.dispatch(fetchUsers());
  createRoot(rootElement).render(
    // <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={baseUrl}>
        <App />
      </BrowserRouter>
    </Provider>
    // </React.StrictMode>
  );
}

start();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
