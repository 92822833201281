import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";

import * as remoteClient from "../../dataServices/remoteClient";

const matchesAdapter = createEntityAdapter({});

const initialState = matchesAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchMatches = createAsyncThunk(
  "matches/fetchMatches",
  async () => {
    return await remoteClient.get(`api/posts/matches`);
  }
);

export const rejectMatch = createAsyncThunk(
  "matches/rejectMatch",
  async ({ matchId }) => {
    return await remoteClient.post(`api/postresponses/${matchId}/reject`, null);
  }
);

export const considerMatch = createAsyncThunk(
  "matches/considerMatch",
  async ({ matchId }) => {
    return await remoteClient.post(
      `api/postresponses/${matchId}/consider`,
      null
    );
  }
);

export const engageMatch = createAsyncThunk(
  "matches/matchResponse",
  async ({ matchId, subject, text }) => {
    return await remoteClient.post(`api/postresponses/${matchId}/engagement`, {
      subject,
      text,
    });
  }
);

export const sendCandidatePostMessage = createAsyncThunk(
  "matches/sendCandidatePostMessage",
  async ({ matchId, postResponseId, dto }) => {
    return await remoteClient.post(
      `api/posts/${matchId}/postresponses/${postResponseId}/candidatemessages`,
      dto
    );
  }
);

const matchesSlice = createSlice({
  name: "matches",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMatches.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMatches.fulfilled, (state, action) => {
        state.status = "succeeded";
        matchesAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchMatches.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(rejectMatch.fulfilled, (state, action) => {
        const { matchId } = action.payload;
        matchesAdapter.removeOne(state, matchId);
      })
      .addCase(rejectMatch.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(engageMatch.fulfilled, (state, action) => {
        const { postId, id, messageThread } = action.payload;

        const post = state.entities[postId];

        if (post) {
          post.postResponses.push(action.payload);
        }
        //matchesAdapter.removeOne(state, matchId);
      })
      .addCase(engageMatch.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(sendCandidatePostMessage.fulfilled, (state, action) => {
        const { postId, postResponseId, message } = action.payload;
        const post = state.entities[postId];
        if (post) {
          const postResponse = post.postResponses.find(
            (response) => response.id === postResponseId
          );
          if (postResponse) {
            postResponse.messageThread.messages.push(message);
          }
        }
      })
      .addCase(sendCandidatePostMessage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default matchesSlice.reducer;

export const {
  selectAll: selectAllMatches,
  selectById: selectMatchById,
  selectIds: selectMatchIds,
} = matchesAdapter.getSelectors((state) => state.matches);
