import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { Route } from "react-router-dom";
import { NotAuthorized } from "../components/NotAuthorized";

const AuthorizeRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <NotAuthorized />
      }
    />
  );
};

export default AuthorizeRoute;
