import { Link } from "react-router-dom";

export const InfoAlert = ({ lead, text, link, href }) => {
  return (
    <>
      <div className="alert alert-light d-flex border mb-5" role="alert">
        <i className="fi-info-square me-2 me-sm-3 lead"></i>
        <div>
          {lead && (
            <>
              <strong>{lead}</strong>
              {"  "}
            </>
          )}
          {text}
          {link && href && (
            <>
              {"  "}
              <Link to={href} className="alert-link">
                {link}
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
};
