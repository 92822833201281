import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

import baseIdentityUrlConfig from "../../../config/baseIdentityUrlConfig";

import { useAuth } from "../../../contexts/AuthContext";
import { CenteredSingleColumnLayout } from "../../common/CenteredSingleColumnLayout";

export const Settings = () => {
  const { isCandidate, isEmployer } = useAuth();
  const [avatar, setAvatar] = useState();

  const candidate = useSelector((state) => state.user.user);
  const recruiter = useSelector((state) => state.recruiter.recruiter);
  const [name, setName] = useState();
  const [telephone, setTelephone] = useState();
  const [email, setEmail] = useState();

  useEffect(() => {
    if (isCandidate) {
      const avatar = candidate?.avatar?.location;
      setAvatar(avatar);
      setName(`${candidate.firstName} ${candidate.lastName}`);
      setTelephone(`${candidate.telephone}`);
      setEmail(`${candidate.email}`);
    }
    if (isEmployer) {
      const avatar = recruiter?.avatar?.location;
      setAvatar(avatar);
      setName(`${recruiter.firstName} ${recruiter.lastName}`);
      setTelephone(`${recruiter.telephone}`);
      setEmail(`${recruiter.email}`);
    }
  });

  return (
    <>
      <CenteredSingleColumnLayout>
        <div className="card card-body border-0   pb-1 mt-5">
          <div className="d-flex d-md-block d-lg-flex align-items-start mb-4">
            <div className="avatar-container">
              {avatar ? (
                <img
                  className="rounded-circle"
                  src={avatar}
                  width="48"
                  alt="Avatar"
                />
              ) : (
                <Link className="btn btn-link" to="/onboard/avatar">
                  <i className="fi fi-plus"></i> Avatar
                </Link>
              )}
            </div>

            <div className="pt-md-2 pt-lg-0 ps-3 ps-md-0 ps-lg-3">
              <h2 className="fs-lg mb-0">{name}</h2>
              <ul className="list-unstyled fs-sm mt-3 mb-0">
                {/* {telephone && telephone !== undefined && (
                  <li>
                    <a className="nav-link fw-normal p-0" href="tel:3025550107">
                      <i className="fi-phone opacity-60 me-2"></i>
                      {telephone}
                    </a>
                  </li>
                )} */}

                {/* <li>
                  <a
                    className="nav-link fw-normal p-0"
                    href={`mailto:${email}`}
                  >
                    <i className="fi-mail opacity-60 me-2"></i>
                    {email}
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="d-md-block mt-3" id="card-nav">
            <h6 className="text-primary">Profile / Tags</h6>

            <div className="card-nav">
              <Link
                className="card-nav-link"
                to={isCandidate ? `/update/profile` : `/update/profile`}
              >
                <i className="fi-bookmark opacity-60 me-2"></i>
                Edit Profile Skills Taxonomy
              </Link>

              <Link
                className="card-nav-link"
                to={
                  isCandidate
                    ? "/update/basic-info"
                    : "/update/recruiter/basic-info"
                }
              >
                <i className="fi-user opacity-60 me-2"></i>
                Personal Info / Default Profile
              </Link>
              <Link
                className="card-nav-link"
                to={isCandidate ? `/update/avatar` : `/update/recruiter/avatar`}
              >
                <i className="fi-image opacity-60 me-2"></i>
                Edit Avatar / Profile Image
              </Link>
            </div>
          </div>

          <div className="d-md-block mt-3" id="card-nav">
            <h6 className="text-primary">User Settings</h6>
            <div className="card-nav">
              <a
                className="card-nav-link"
                href={`${baseIdentityUrlConfig.baseUrl}identity/account/manage/changepassword`}
              >
                <i className="fi-lock opacity-60 me-2"></i>
                Change Password
              </a>
              <a
                className="card-nav-link"
                href={`${baseIdentityUrlConfig.baseUrl}identity/account/manage/email`}
              >
                <i className="fi-mail opacity-60 me-2"></i>
                Change Email Address
              </a>
              <a
                className="card-nav-link"
                href={`${baseIdentityUrlConfig.baseUrl}identity/account/resendemailconfirmation`}
              >
                <i className="fi-mail opacity-60 me-2"></i>
                Resend Email Confirmation
              </a>
            </div>
          </div>
        </div>
      </CenteredSingleColumnLayout>
    </>
  );
};
