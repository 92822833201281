export const tagConstants = [
  {
    enum: 15,
    type: "BasicInfo",
    name: "Basic Info",
    namePlural: "Basic Info",
    href: "basic-info",
    const: "BASIC_INFO",
    showFilter: false,
    multi: false,
    order: 0,
  },
  {
    enum: 1,
    type: "FirmType",
    name: "Firm Type",
    namePlural: "Firm Types",
    href: "firmtypes",
    const: "FIRM_TYPE",
    showFilter: false,
    multi: false,
    order: 2,
    color: "danger",
    fiIcon: "fi-grid",
  },
  {
    enum: 0,
    type: "Education",
    name: "Education",
    namePlural: "Education",
    href: "educations",
    const: "EDUCATION",
    multi: false,
    showFilter: false,
    order: 11,
    color: "warning",
    faIcon: "faUserGraduate",
  },
  {
    enum: 2,
    type: "Industry",
    name: "Industry",
    namePlural: "Industries",
    href: "industries",
    const: "INDUSTRY",
    showFilter: true,
    multi: false,
    order: 4,
    color: "info",
    fiIcon: "fi-apartment",
  },
  {
    enum: 3,
    type: "Language",
    name: "Language",
    namePlural: "Languages",
    href: "languages",
    const: "LANGUAGE",
    multi: false,
    showFilter: true,
    order: 10,
    color: "accent",
    fiIcon: "fi-chat-right",
  },
  {
    enum: 4,
    type: "Position",
    name: "Position",
    namePlural: "Positions",
    href: "positions",
    const: "POSITION",
    showFilter: false,
    multi: false,
    order: 1,
    color: "primary",
    fiIcon: "fi-arrows-sort",
  },
  {
    enum: 5,
    type: "Sector",
    name: "Division",
    namePlural: "Divisions",
    href: "divisions",
    const: "SECTOR",
    showFilter: false,
    multi: false,
    order: 3,
    color: "success",
    fiIcon: "fi-briefcase",
  },
  {
    enum: 6,
    type: "Skill",
    name: "Skill",
    namePlural: "Skills",
    href: "skills",
    const: "SKILL",
    showFilter: false,
    multi: true,
    order: 5,
    color: "success",
    colorHex: "#0b6e4f",
    faIcon: "faBrain",
  },
  {
    enum: 7,
    type: "Qualification",
    name: "Qualification",
    namePlural: "Qualifications",
    href: "qualifications",
    const: "QUALIFICATION",
    showFilter: false,
    multi: true,
    order: 6,
    color: "danger",
    colorHex: "#db504a",
    fiIcon: "fi-award",
  },
  {
    enum: 9,
    type: "OfficePreference",
    name: "Office Preference",
    namePlural: "Office Preferences",
    href: "office",
    const: "OFFICE_PREFERENCE",
    showFilter: false,
    multi: false,
    order: 7,
    color: "secondary",
    fiIcon: "fi-building",
  },
  {
    enum: 10,
    type: "Country",
    name: "Country",
    namePlural: "Countries",
    href: "countries",
    const: "COUNTRIES",
    showfilter: false,
    multi: true,
    order: 8,
    color: "info",
    colorHex: "#457b9d",
    fiIcon: "fi-globe",
  },
  {
    enum: 11,
    type: "City",
    name: "City",
    namePlural: "Cities",
    href: "cities",
    const: "CITIES",
    showfilter: false,
    multi: true,
    order: 9,
    color: "primary",
    colorHex: "#2b4162",
    fiIcon: "fi-map-pin",
  },
  {
    enum: 8,
    type: "Type",
    name: "Contract Type",
    namePlural: "Contract Types",
    href: "contracts",
    const: "CONTRACT_TYPE",
    showFilter: false,
    multi: false,
    order: 12,
    color: "dark",
    fiIcon: "fi-calendar",
  },
  {
    enum: 13,
    type: "Salary",
    name: "Salary",
    namePlural: "Salaries",
    href: "salary",
    const: "SALARY",
    showFilter: false,
    multi: false,
    order: 13,
    fiIcon: "fi-cash",
  },
];
