import React from "react";

export const Spinner = ({ text = "", size = "5em" }) => {
  const header = text ? (
    <p>
      <strong>
        <em>{text}</em>
      </strong>
    </p>
  ) : null;
  return (
    <div className="spinner">
      {header}
      <div className="loader" style={{ height: size, width: size }} />
    </div>
  );
};
