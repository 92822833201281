import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

import { PrevNextBtnLgRound } from "../PrevNextButtonLgRound";
import { MultiTagSelect } from "../../../../common/MultiTagSelect";
import { InfoAlert } from "../../../../common/InfoAlert";

import * as remoteClient from "../../../../../dataServices/remoteClient";

import {
  fetchRecruiter,
  saveProfileMultiTags,
  saveProfileCountries,
  saveProfileCities,
} from "../../../../../features/recruiter/recruiterSlice";

import { tagConstants } from "../../../../../app/tagConstants";

import { toast } from "react-toastify";
import { CenteredSingleColumnLayout } from "../../../../common/CenteredSingleColumnLayout";

export const OnboardRecruiterMultiTagsPage = ({
  tagType,
  text,
  lead,
  creatable = false,
  update,
}) => {
  const recruiter = useSelector((state) => state.recruiter.recruiter);

  const dispatch = useDispatch();

  const [selectedOptions, setSelectedOptions] = useState({
    anyTags: [],
    anyTouched: false,
  });

  //const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);

  const [currentTag, setCurrentTag] = useState();
  const [nextTag, setNextTag] = useState();
  const [prevTag, setPrevTag] = useState();
  const [defaultValue, setDefaultValue] = useState();
  const [icon, setIcon] = useState();

  const currentTagFromTagConstants = tagConstants.find(
    (t) => t.type === tagType
  );

  let history = useHistory();

  const getTags = async (input) => {
    if (input !== null && input !== undefined && input !== "") {
      return await remoteClient.get(
        `/api/tags/select/${currentTag.href}?searchTerm=${input}`
      );
    }
  };

  useEffect(() => {
    if (recruiter === null) {
      dispatch(fetchRecruiter());
    }
  }, [dispatch, recruiter]);

  useEffect(() => {
    if (recruiter && recruiter.profile) {
      if (tagType === "Country") {
        setDefaultValue(recruiter.profile.countries);
      } else if (tagType === "City") {
        setDefaultValue(recruiter.profile.cities);
      } else {
        setDefaultValue(
          recruiter.profile.tagsForSelect.filter(
            (t) => t.type === tagType && t.profileTagType === "Any"
          )
        );
      }
    }
  }, [recruiter, tagType]);

  useEffect(() => {
    setCurrentTag(tagConstants.find((t) => t.type === tagType));

    setNextTag(
      tagConstants.find((t) => t.order === currentTagFromTagConstants.order + 1)
    );

    setPrevTag(
      tagConstants.find((t) => t.order === currentTagFromTagConstants.order - 1)
    );
  }, [currentTagFromTagConstants, tagType]);

  useEffect(() => {
    if (currentTag && currentTag.fiIcon) {
      setIcon(currentTag.fiIcon);
    }
  }, [currentTag]);

  const handleInput = (newValue) => {
    setSelectedOptions({
      ...selectedOptions,
      anyTags: newValue,
      anyTouched: true,
    });
  };

  const redirectAfterSave = () => {
    history.push(update ? `/recruiter/dashboard` : `${nextTag.href}`);
  };

  const handleSave = () => {
    if (!selectedOptions.anyTouched && nextTag.href) {
      redirectAfterSave();
    } else {
      setSaving(true);

      if (tagType === "Country") {
        dispatch(saveProfileCountries({ recruiter, selectedOptions }))
          .then(() => {
            toast.success(`${currentTag.name} tags saved`);
            redirectAfterSave();
          })
          .catch((error) => {
            setSaving(false);
            //setErrors({ onSave: error.message });
            toast.error(
              `Saving ${currentTag.name} tags failed: ${error.message}`
            );
          });
      } else if (tagType === "City") {
        dispatch(saveProfileCities({ recruiter, selectedOptions }))
          .then(() => {
            toast.success(`${currentTag.name} tags saved`);
            redirectAfterSave();
          })
          .catch((error) => {
            setSaving(false);
            //setErrors({ onSave: error.message });
            toast.error(
              `Saving ${currentTag.name} tags failed: ${error.message}`
            );
          });
      } else {
        dispatch(saveProfileMultiTags({ recruiter, tagType, selectedOptions }))
          .then(() => {
            toast.success(`${currentTag.name} tags saved`);
            redirectAfterSave();
          })
          .catch((error) => {
            setSaving(false);
            //setErrors({ onSave: error.message });
            toast.error(
              `Saving ${currentTag.name} tags failed: ${error.message}`
            );
          });
      }
    }
  };

  return (
    <>
      <CenteredSingleColumnLayout>
        {nextTag && (
          <InfoAlert
            lead={lead}
            text={text}
            link={"Skip"}
            href={nextTag.href}
          />
        )}

        <div className="py-4 mb-md-2">
          <h2 className="h4 mb-4">
            <i className={`${icon} text-primary fs-5 mt-n1 me-2 pe-1`}></i>
            Select {currentTag && currentTag.name} Tags
          </h2>

          <>
            {defaultValue && (
              <>
                {currentTag && (
                  <MultiTagSelect
                    loadOptions={getTags}
                    placeholder={`Start typing ${currentTag.name}`}
                    onChange={handleInput}
                    defaultValue={defaultValue}
                    creatable={creatable}
                    color={currentTag.colorHex}
                  />
                )}
              </>
            )}
          </>
        </div>
        {nextTag && prevTag && (
          <PrevNextBtnLgRound
            nextTitle={update ? "Update" : nextTag.name}
            prevTitle={update ? "" : prevTag.name}
            prevHref={update ? "" : prevTag.href}
            saving={saving}
            saveTags={handleSave}
            update={update}
          />
        )}
      </CenteredSingleColumnLayout>{" "}
    </>
  );
};
