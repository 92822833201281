import { useAuth } from "../../../contexts/AuthContext";

import { CardHTML } from "../CardHTML";
import { TimeAgo } from "../TimeAgo";

export const PostResponseComponent = ({ postResponse }) => {
  const { userId } = useAuth();

  return (
    <>
      {postResponse &&
        postResponse.messageThread &&
        postResponse.messageThread.messages &&
        postResponse.messageThread.messages.length > 0 &&
        postResponse.messageThread.messages.map((message) =>
          userId === message.senderId ? (
            <>
              <div className="d-flex justify-content-end" key={message.id}>
                <div className="card bg-info text-light p-3 w-75 border-round">
                  <CardHTML text={message.text} />
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <span className="text-muted fs-sm">
                  <TimeAgo timestamp={message.created} />
                </span>
              </div>
            </>
          ) : (
            <>
              <div
                className="d-flex justify-content-between align-items-center mt-2"
                key={message.id}
              >
                <div className="d-flex align-items-center pe-2">
                  <>
                    {postResponse.candidate &&
                      postResponse.candidate.avatar && (
                        <img
                          className="rounded-circle me-1"
                          src={postResponse.candidate.avatar.location}
                          width="48"
                          alt="Avatar"
                        />
                      )}
                    <div className="ps-2">
                      <h6 className="fs-base mb-0">
                        {postResponse.candidate.firstName}{" "}
                        {postResponse.candidate.lastName}
                      </h6>
                      <span className="text-muted fs-sm mb-0">
                        {postResponse.candidate.employer}
                        <br></br>
                      </span>
                    </div>
                  </>
                </div>
              </div>

              <div className="card bg-secondary p-3 w-75 ps-4 mt-4">
                <CardHTML text={message.text} />
              </div>
              <span className="text-muted fs-sm">
                <TimeAgo timestamp={message.created} />
              </span>
            </>
          )
        )}
    </>
  );
};
