import React, { useState, useEffect } from "react";
import { CenteredSingleColumnLayout } from "../../common/CenteredSingleColumnLayout";

import {
  engageMatch,
  fetchMatches,
  selectMatchById,
} from "../../../features/matches/matchesSlice";

import { fetchUser } from "../../../features/user/userSlice";

import { DraftEditor } from "../../common/DraftEditor";

import { InfoAlert } from "../../common/InfoAlert";

import { toast } from "react-toastify";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";

import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";

import { useAuth } from "../../../contexts/AuthContext";
import { NotAuthorized } from "../../NotAuthorized";
import { CheckOwnerComponent } from "../../../auth/CheckOwnerComponent";
import { useDispatch, useSelector } from "react-redux";

export const MatchEngagementPage = ({
  postResponseId,
  match,
  history,
  ...props
}) => {
  const { postId } = match.params;
  const dispatch = useDispatch();

  const post = useSelector((state) => selectMatchById(state, postId));
  const matchStatus = useSelector((state) => state.matches.status);

  const candidate = useSelector((state) => state.user.user);

  const [loading, setLoading] = useState(true);

  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [errors, setErrors] = useState({});

  const { user } = useAuth();
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");

  const [saving, setSaving] = useState(false);

  const [initialFetchCompleted, setInitialFetchCompleted] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const maxCharacters = 750;
  const [currentTextLength, setCurrentTextLength] = useState(0);

  useEffect(() => {
    if (matchStatus === "idle") {
      dispatch(fetchMatches());
    }
  }, [matchStatus, dispatch]);

  useEffect(() => {
    if (candidate === null) {
      dispatch(fetchUser());
    }
  }, []);

  useEffect(() => {
    if (candidate && candidate.pitch) {
      if (!initialFetchCompleted) {
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(candidate.pitch))
          )
        );
        setCurrentTextLength(candidate.pitch.length);
        setText(candidate.pitch);
        setInitialFetchCompleted(true);
      }
    }
  }, [candidate, initialFetchCompleted]);

  const [prevContentState, setPrevContentState] = useState(
    editorState.getCurrentContent()
  );

  const handleTextChange = (newEditorState) => {
    const currentContent = newEditorState.getCurrentContent();
    const currentText = currentContent.getPlainText("");

    if (currentText.length <= maxCharacters) {
      setPrevContentState(currentContent); // Save the current content state
      setEditorState(newEditorState);
      setCurrentTextLength(currentText.length);
      convertContentToHTML();
    } else {
      // If the text exceeds the character limit, restore the previous content state
      setEditorState(EditorState.createWithContent(prevContentState));
    }
  };

  const handleSubjectChange = (e) => setSubject(e.target.value);

  const convertContentToHTML = () => {
    const extractedHTML = extractHTML();
    setText(extractedHTML);
  };

  // Get HTML from editor for saving to db
  const extractHTML = () => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlToSanitize = draftToHtml(rawContentState);
    return sanitize(htmlToSanitize);
  };

  // Sanitize any bad input from the editor
  const sanitize = (html) => {
    return DOMPurify.sanitize(html);
  };

  //const canSave = subject && text && addRequestStatus === "idle";

  const handleSave = async (event) => {
    event.preventDefault();

    // if (canSave) {
    try {
      setAddRequestStatus("pending");
      const matchId = postId;

      await dispatch(engageMatch({ matchId, subject, text }))
        .unwrap()
        .then((response) => {
          toast.success("Post Response Sent");
          setSubject("");
          setText("");
          history.push(`/matches`);
        });
    } catch (error) {
      toast.error(`Failed to send post response. ${error.message}`);
      setErrors({ onSave: error.message });
      console.error("Failed to send post response.");
    } finally {
      setAddRequestStatus("idle");
    }
    //}
  };

  return (
    <>
      <CenteredSingleColumnLayout>
        <InfoAlert
          lead="Engaging with this post will share the details below with the poster."
          text={
            <>
              Use the message below to highlight your key skills / experience /
              achievements and outline why they should engage with you. This is
              all the poster will see of you at this stage. Your personal
              details will be shared with them if they agree to formally engage
              with you. The pre-populated text is the default pitch from your
              profile.
            </>
          }
        />
        <form onSubmit={handleSave}>
          <input
            className="form-control form-control-lg mb-2"
            type="text"
            name="subject"
            value={subject}
            placeholder="Enter a subject for your response"
            onChange={handleSubjectChange}
          />

          <div className="d-flex align-items-center mb-3">
            <DraftEditor
              editorState={editorState}
              handleEditorChange={handleTextChange}
              placeholder={<>Format your pitch / response here.</>}
            />
          </div>

          <p className="d-flex justify-content-end">
            <em>
              {maxCharacters - currentTextLength} / {maxCharacters} remaining
            </em>
          </p>

          <input
            type="submit"
            className="btn btn-success btn-lg w-100 ms-sm-auto"
            value={saving ? "Sending..." : "Engage"}
            disabled={saving}
          />
        </form>
      </CenteredSingleColumnLayout>
    </>
  );
};
