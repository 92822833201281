import { useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

const CookieBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      disableButtonStyles={true}
      buttonText="Good with me!!"
      buttonClasses="btn btn-lg btn-rounded btn-success p-2 me-5"
    >
      <>
        This website uses cookies to enhance the user experience. <br></br>
        <Link className="btn-link" to="/privacy">
          You can review or cookie policy here
        </Link>
      </>
    </CookieConsent>
  );
};

export default CookieBanner;
