import { OnboardMultiTagsPage } from "../OnboardMultiTagsPage";

export const UpdateQualificationsPageWrapper = (props) => {
  return (
    <OnboardMultiTagsPage
      tagType={"Qualification"}
      lead={"Add tags for any qualifications or certifications."}
      tagColor={"outline-danger"}
      text={
        <>
          Includes only qualifications / certifications which you possess and
          are likely to be required for the roles you want to receive.
        </>
      }
      {...props}
      update={true}
    />
  );
};
