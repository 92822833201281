import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { ProfileSalaryForm } from "../../../pages/user/onboard/salary/ProfileSalaryForm";
import { InfoAlert } from "../../../common/InfoAlert";
import { useHistory } from "react-router-dom";

import {
  fetchPosts,
  updateProfileSalary,
  selectPostById,
} from "../../../../features/posts/postsSlice";

import { CenteredSingleColumnLayout } from "../../../common/CenteredSingleColumnLayout";
import { CheckOwnerComponent } from "../../../../auth/CheckOwnerComponent";

export const PostOnboardSalaryPage = ({ match }) => {
  const { postId, profileId } = match.params;

  const [saving, setSaving] = useState(false);

  const post = useSelector((state) => selectPostById(state, postId));
  const postStatus = useSelector((state) => state.posts.status);

  const [errors, setErrors] = useState([]);
  const [profileToUpdate, setProfileToUpdate] = useState(null);
  const [profileSalaryDto, setProfileSalaryDto] = useState();

  const [requiredOwnerId, setRequiredOwnerId] = useState();

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (postStatus === "idle") {
      dispatch(fetchPosts());
    }
  }, [postStatus, dispatch]);

  useEffect(() => {
    if (post && post.profile) {
      setProfileToUpdate(post.profile);
      setProfileSalaryDto({
        id: post.profile.id,
        minSalary: post.profile.minSalary,
        maxSalary: post.profile.maxSalary,
        minDailyRate: post.profile.minDailyRate,
        maxDailyRate: post.profile.maxDailyRate,
        minHourlyRate: post.profile.minHourlyRate,
        maxHourlyRate: post.profile.maxHourlyRate,
      });
      setRequiredOwnerId(post.ownerId);
    }
  }, [post]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProfileToUpdate((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));

    setProfileSalaryDto((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleSave = async (event) => {
    event.preventDefault();

    try {
      setSaving(true);

      await dispatch(updateProfileSalary({ post, profileSalaryDto }))
        .unwrap()
        .then(() => {
          toast.success("Salary Updated");
          history.push("/recruiter/dashboard");
        });
    } catch (error) {
      console.error("Failed to update salary. " + error.message);
      setErrors({ onSave: error.message });
      setSaving(false);
      toast.error("Updating Salary failed. ");
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <CenteredSingleColumnLayout>
        <CheckOwnerComponent requiredOwnerId={requiredOwnerId}>
          <InfoAlert
            lead=" Provide a salary banding for the candidates you hope to engage
                with."
            text={
              <>
                <p>
                  The band provided is not displayed to candidates. Your post
                  will only be visible to candidates <br></br>
                  who have selected a salary band which corresponds with the
                  numbers you provide.
                </p>
              </>
            }
          />
          {profileToUpdate && (
            <ProfileSalaryForm
              profile={profileToUpdate}
              onChange={handleChange}
              errors={errors}
              saving={saving}
              onSave={handleSave}
            />
          )}
        </CheckOwnerComponent>
      </CenteredSingleColumnLayout>
    </>
  );
};
