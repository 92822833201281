import { PostStaticTagsPage } from "./PostStaticTagsPage";

export const PostOfficePreferenceTagsPageWrapper = ({ match, ...props }) => {
  const { postId, profileId } = match.params;

  return (
    <PostStaticTagsPage
      tagType={"OfficePreference"}
      postId={postId}
      profileId={profileId}
      lead={<>What is the office / remote policy?</>}
      {...props}
    />
  );
};
