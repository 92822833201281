import React from "react";
import { useSelector } from "react-redux";

import { selectFeedItemById } from "../../../features/feeds/candidateFeedSlice";
import { JobCard } from "../../common/JobCard";

import { TimeAgo } from "../../common/TimeAgo";
import { CityBadge } from "../../common/CityBadge";

export const JobFeedCard = ({ feedId, handleSelectJob }) => {
  const job = useSelector((state) =>
    selectFeedItemById(state, feedId)
  ).document;
  return (
    <>
      <JobCard key={feedId} job={job} handleSelectJob={handleSelectJob} />
    </>
  );
};
