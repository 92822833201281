import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { ProfileSalaryForm } from "./ProfileSalaryForm";

import { useHistory } from "react-router-dom";

import {
  fetchUser,
  updateProfileSalary,
} from "../../../../../features/user/userSlice";
import { CenteredSingleColumnLayout } from "../../../../common/CenteredSingleColumnLayout";
import { InfoAlert } from "../../../../common/InfoAlert";

export const OnboardSalaryPage = ({ update }) => {
  const user = useSelector((state) => state.user.user);

  const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);
  const [profileToUpdate, setProfileToUpdate] = useState(null);
  const [profileSalaryDto, setProfileSalaryDto] = useState();

  const [, setAddRequestStatus] = useState("idle");

  useEffect(() => {
    if (user && user.profile) {
      setProfileToUpdate(user.profile);

      setProfileSalaryDto({
        id: user.profile.id,
        minSalary: user.profile.minSalary,
        maxSalary: user.profile.maxSalary,
        minDailyRate: user.profile.minDailyRate,
        maxDailyRate: user.profile.maxDailyRate,
        minHourlyRate: user.profile.minHourlyRate,
        maxHourlyRate: user.profile.maxHourlyRate,
      });
    }
  }, [user]);

  useEffect(() => {
    if (user === null) {
      dispatch(fetchUser());
    }
  }, [user]);

  const dispatch = useDispatch();
  const history = useHistory();

  function handleChange(event) {
    const { name, value } = event.target;
    setProfileToUpdate((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));

    setProfileSalaryDto((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  }

  const handleSave = async (event) => {
    event.preventDefault();
    setSaving(true);

    try {
      setAddRequestStatus("pending");

      await dispatch(updateProfileSalary({ user, profileSalaryDto }))
        .unwrap()
        .then(() => {
          toast.success("User Updated");
          if (!update) {
            history.push("/onboard/avatar");
          } else {
            history.push("/matches");
          }
        });
    } catch (error) {
      console.error("Failed to update salary. " + error.message);
      setErrors({ onSave: error.message });
      setSaving(false);
      toast.error("Updating Salary failed. ", JSON.stringify(error.message));
    } finally {
      setAddRequestStatus("idle");
      setSaving(false);
    }
  };

  return (
    <>
      <CenteredSingleColumnLayout>
        <InfoAlert
          lead={"Add bands for your salary expectations."}
          text={
            <>
              These details will never be shared with businesses / employers /
              recruiters. You will only receive messages / posts which have an
              equivalent band. Roles you are approached with should therefore
              always be in your desired range.
            </>
          }
        />

        {profileToUpdate && (
          <ProfileSalaryForm
            profile={profileToUpdate}
            onChange={handleChange}
            errors={errors}
            saving={saving}
            onSave={handleSave}
            update={update}
          />
        )}
      </CenteredSingleColumnLayout>{" "}
    </>
  );
};
