import { StaticTagsPage } from "../recruiter/OnboardStaticTagsPage";

export const UpdateRecruiterIndustriesPageWrapper = (props) => {
  return (
    <StaticTagsPage
      tagType={"Industry"}
      profileType={"recruiter"}
      tagColor={"info"}
      lead={`Do candidates need experience in a particular industry?`}
      text={
        <>
          Choose industries that best reflect the organisation(s) you recruit
          for.
        </>
      }
      {...props}
      update={true}
    />
  );
};
