import React, { useState } from "react";

import { Link } from "react-router-dom";
import { Col, NavLink, Tooltip } from "reactstrap";

export const NavFooterCandidateLinks = () => {
  const [navFooterFeedTooltipOpen, setNavFooterFeedTooltipOpen] =
    useState(false);
  const [navFooterProfileTooltipOpen, setNavFooterProfileTooltipOpen] =
    useState(false);
  const [navFooterSavedTooltipOpen, setNavFooterSavedTooltipOpen] =
    useState(false);
  const [navFooterSearchTooltipOpen, setNavFooterSearchTooltipOpen] =
    useState(false);
  const [navFooterMatchesTooltipOpen, setNavFooterMatchesTooltipOpen] =
    useState(false);

  return (
    <>
      <Col>
        <NavLink
          tag={Link}
          className="text-light text-center"
          to="/search/jobs"
          id="navFooterSearchButton"
        >
          <i className="fi-search" />
          <Tooltip
            isOpen={navFooterSearchTooltipOpen}
            placement="bottom"
            target="navFooterSearchButton"
            toggle={() => {
              setNavFooterSearchTooltipOpen(!navFooterSearchTooltipOpen);
            }}
          >
            Search
          </Tooltip>
        </NavLink>
      </Col>
      <Col>
        <NavLink
          tag={Link}
          className="text-light text-center"
          to="/saved"
          id="navFooterSavedButton"
        >
          <i className="fi-heart" />
          <Tooltip
            isOpen={navFooterSavedTooltipOpen}
            placement="bottom"
            target="navFooterSavedButton"
            toggle={() => {
              setNavFooterSavedTooltipOpen(!navFooterSavedTooltipOpen);
            }}
          >
            Saved
          </Tooltip>
        </NavLink>
      </Col>
      <Col>
        <NavLink
          tag={Link}
          className="text-light text-center"
          id="navFooterFeedButton"
          to="/feed"
        >
          <i className="fi-list" />
          <Tooltip
            isOpen={navFooterFeedTooltipOpen}
            placement="bottom"
            target="navFooterFeedButton"
            toggle={() => {
              setNavFooterFeedTooltipOpen(!navFooterFeedTooltipOpen);
            }}
          >
            Feed
          </Tooltip>
        </NavLink>
      </Col>
      <Col>
        <NavLink
          tag={Link}
          className="text-light text-center"
          id="navFooterMatchesButton"
          to="/matches"
        >
          <i className="fi-users" />
          <Tooltip
            isOpen={navFooterMatchesTooltipOpen}
            placement="bottom"
            target="navFooterMatchesButton"
            toggle={() => {
              setNavFooterMatchesTooltipOpen(!navFooterMatchesTooltipOpen);
            }}
          >
            Matches
          </Tooltip>
        </NavLink>
      </Col>

      <Col>
        <NavLink
          tag={Link}
          className="text-light text-center"
          to="/onboard/basic-info"
          id="navFooterProfileButton"
        >
          <i className="fi-user" />
          <Tooltip
            isOpen={navFooterProfileTooltipOpen}
            placement="bottom"
            target="navFooterProfileButton"
            toggle={() => {
              setNavFooterProfileTooltipOpen(!navFooterProfileTooltipOpen);
            }}
          >
            Profile
          </Tooltip>
        </NavLink>
      </Col>
    </>
  );
};
