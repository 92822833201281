import { OnboardStaticTagsPage } from "../OnboardStaticTagsPage";

export const PositionTagsPageWrapper = (props) => {
  return (
    <OnboardStaticTagsPage
      tagType={"Position"}
      lead={"Select positions which are of interest to you."}
      tagColor={"primary"}
      {...props}
      update={false}
    />
  );
};
