import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const DraftEditor = ({
  editorState,
  handleEditorChange,
  placeholder,
  toolbarHidden,
  id,
}) => {
  return (
    <>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbar-class"
        wrapperClassName="form-control"
        editorClassName="editor-class"
        onEditorStateChange={handleEditorChange}
        placeholder={placeholder}
        toolbar={{
          options: ["list"],
        }}
        toolbarHidden={toolbarHidden}
        wrapperId={id}
        stripPastedStyles
      />
    </>
  );
};
