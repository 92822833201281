import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { PrevNextBtnLgRound } from "../../user/onboard/PrevNextButtonLgRound";
import { MultiTagSelect } from "../../../common/MultiTagSelect";

import * as remoteClient from "../../../../dataServices/remoteClient";

import { toast } from "react-toastify";

import { InfoAlert } from "../../../common/InfoAlert";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/free-solid-svg-icons";

import {
  fetchPosts,
  selectPostById,
  saveProfileMultiTags,
} from "../../../../features/posts/postsSlice";

import { CenteredSingleColumnLayout } from "../../../common/CenteredSingleColumnLayout";
import { CheckOwnerComponent } from "../../../../auth/CheckOwnerComponent";

export const OnboardPostSkillsPage = ({ match, ...props }) => {
  const { postId, profileId } = match.params;

  const [defaultValue, setDefaultValue] = useState();
  const [allDefaultValue, setAllDefaultValue] = useState();

  const post = useSelector((state) => selectPostById(state, postId));
  const postStatus = useSelector((state) => state.posts.status);
  const [requiredOwnerId, setRequiredOwnerId] = useState("");

  useEffect(() => {
    if (post && post.profile && post.profile.tagsForSelect) {
      setDefaultValue(
        post.profile.tagsForSelect.filter(
          (t) => t.type === "Skill" && t.profileTagType === "Any"
        )
      );
      setAllDefaultValue(
        post.profile.tagsForSelect.filter(
          (t) => t.type === "Skill" && t.profileTagType === "All"
        )
      );
    }
  }, [post]);

  useEffect(() => {
    if (post) {
      setRequiredOwnerId(post.ownerid);
    }
  }, [post]);

  const dispatch = useDispatch();

  const [selectedOptions, setSelectedOptions] = useState({
    allTags: [],
    anyTags: [],
    allTouched: false,
    anyTouched: false,
  });

  //const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);

  let history = useHistory();

  const getSkills = async (input) => {
    return await remoteClient.get(
      `/api/tags/select/skills?searchTerm=${input}`
    );
  };

  useEffect(() => {
    if (postStatus === "idle") {
      dispatch(fetchPosts());
    }
  }, [postStatus, dispatch]);

  const handleAnySkillsInput = (newValue) => {
    setSelectedOptions({
      ...selectedOptions,
      anyTags: newValue,
      anyTouched: true,
    });
  };

  const handleAllSkillsInput = (newValue) => {
    setSelectedOptions({
      ...selectedOptions,
      allTags: newValue,
      allTouched: true,
    });
  };

  const handleSaveSkills = () => {
    if (!selectedOptions.allTouched && !selectedOptions.anyTouched) {
      history.push("qualifications");
    } else {
      setSaving(true);
      let tagType = "skill";
      dispatch(saveProfileMultiTags({ post, tagType, selectedOptions }))
        .then(() => {
          toast.success(`Skills saved`);
          history.push(`qualifications`);
        })
        .catch((error) => {
          setSaving(false);
          // setErrors({ onSave: error.message });
          toast.error(`Saving tags failed: ${error.message}`);
        });
    }
  };

  return (
    <>
      <CenteredSingleColumnLayout>
        {post && (
          <CheckOwnerComponent requiredOwnerId={requiredOwnerId}>
            <InfoAlert lead="What skills do you want candidates to have to engage with you?" />
            <div className="border-top py-4 mb-md-2">
              <h2 className="h4 mb-4">
                <FontAwesomeIcon
                  icon={faBrain}
                  className="text-primary fs-5 mt-n1 me-2 pe-1"
                />
                ANY Skill Tags
              </h2>

              {defaultValue ? (
                <MultiTagSelect
                  loadOptions={getSkills}
                  placeholder="Start typing skills"
                  onChange={handleAnySkillsInput}
                  defaultValue={defaultValue}
                  creatable={true}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="border-top py-4 mb-md-2">
              <h2 className="h4 mb-4">
                <FontAwesomeIcon
                  icon={faBrain}
                  className="text-primary fs-5 mt-n1 me-2 pe-1"
                />
                ALL Skill Tags
              </h2>

              {allDefaultValue ? (
                <MultiTagSelect
                  loadOptions={getSkills}
                  placeholder="Start typing skills"
                  onChange={handleAllSkillsInput}
                  defaultValue={allDefaultValue}
                  creatable={true}
                />
              ) : (
                <></>
              )}
            </div>
            <PrevNextBtnLgRound
              nextTitle={"Qualifications"}
              prevTitle={"Industries"}
              prevHref={"industries"}
              saving={saving}
              saveTags={handleSaveSkills}
            />
          </CheckOwnerComponent>
        )}
      </CenteredSingleColumnLayout>
    </>
  );
};
