import React from "react";

export const PostResponsesOverview = ({ postResponses }) => {
  return (
    <>
      <div class="row g-2">
        <div class="col me-sm-1">
          <div class="rounded text-center w-100 h-100 p-2 text-danger">
            <span class="fs-xs text-danger">
              <strong>Rejected</strong>
            </span>

            <p class="d-block mb-0 mx-center">
              <strong>
                {postResponses.filter((p) => p.type === "Reject").length}
              </strong>
            </p>
          </div>
        </div>
        <div class="col me-sm-1">
          <div class="rounded text-center w-100 h-100 p-2 text-primary">
            <span class="fs-xs">
              <strong>Considering</strong>
            </span>

            <p class="d-block  mb-0 mx-center">
              <strong>
                {postResponses.filter((p) => p.type === "Consider").length}
              </strong>
            </p>
          </div>
        </div>
        <div class="col">
          <div class="rounded text-center w-100 h-100 p-2 text-success">
            <span class="fs-xs">
              <strong>Engaged</strong>
            </span>

            <p class="d-block mb-0 mx-center">
              <strong>
                {postResponses.filter((p) => p.type === "Engage").length}
              </strong>
            </p>
          </div>
        </div>
        {/* <div class="col">
          <div class="rounded text-center w-100 h-100 p-2">
            <span class="fs-xs">
              <strong>Av. Idle time</strong>
            </span>

            <p class="d-block mb-0 mx-center">
              <strong></strong>
            </p>
          </div>
        </div> */}
      </div>
    </>
  );
};
