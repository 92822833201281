const devBaseIdentityUrl = {
  baseUrl: "https://localhost:5001/",
};

const prodBaseIdentityUrl = {
  baseUrl: "https://idp.sourcers.co/",
};

const baseIdentityUrlConfig =
  process.env.NODE_ENV === "production"
    ? prodBaseIdentityUrl
    : devBaseIdentityUrl;

export default baseIdentityUrlConfig;
