import { OnboardPostMultiTagsPage } from "./OnboardPostMultiTagsPage";

export const POstQualificationTagsPageWrapper = ({ match, ...props }) => {
  const { postId, profileId } = match.params;

  return (
    <OnboardPostMultiTagsPage
      tagType={"Qualification"}
      text={"Do you require candidates to have a particular qualification?"}
      postId={postId}
      profileId={profileId}
      {...props}
    />
  );
};
