import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import {
  selectMatchById,
  rejectMatch,
  considerMatch,
} from "../../../features/matches/matchesSlice";

import { useAuth } from "../../../contexts/AuthContext";

import { IdleTime } from "../../common/IdleTime";

import { MatchResponseList } from "./MatchResponseList";

import { toast } from "react-toastify";
import { CardHTML } from "../../common/CardHTML";
import { CardTagsComponent } from "../../common/CardTagsComponent";
import { MatchCardHeader } from "./MatchCardHeader";

export const MatchCard = ({ matchId }) => {
  const match = useSelector((state) => selectMatchById(state, matchId));
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);

  const [lastRecruiterMessage, setLastRecruiterMessage] = useState();

  const { userId } = useAuth();

  const handleRejectMatch = async () => {
    setSaving(true);

    await dispatch(rejectMatch({ matchId }))
      .unwrap()
      .then((response) => {
        toast.success("Post rejected");
      })
      .catch((error) => {
        toast.error(`Failed to reject post, ${error.message}`);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  useEffect(() => {
    if (match.postResponses) {
      const engageResponse = match.postResponses.find(
        (response) => response.type === "Engage"
      );

      if (engageResponse) {
        const mostRecentMessage = engageResponse.messageThread.messages
          .filter((message) => message.senderId !== userId) // Filter out messages with your userId
          .reduce((mostRecent, message) => {
            // Find the most recent message by comparing created dates
            return mostRecent.created > message.created ? mostRecent : message;
          }, {}); // Provide an empty object as the initial value

        // Now 'mostRecentMessage' contains the most recent message you want
        setLastRecruiterMessage(mostRecentMessage);
      }
    }
  }, [match, userId]);

  const handleConsiderMatch = async () => {
    setSaving(true);

    await dispatch(considerMatch({ matchId }))
      .unwrap()
      .then((response) => {
        toast.success("Post set to considering");
      })
      .catch((error) => {
        toast.error(`Failed to consider post, ${error.message}`);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <>
      {match && (
        <div className="card card-bordered mb-2">
          <div className="card-body p-3">
            <MatchCardHeader match={match} />
            <div className="mt-2">
              <p className="card-title text-lead">
                <strong>
                  <a
                    className="text-primary"
                    href={match.extUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {match.title}
                  </a>
                </strong>
              </p>

              <hr className="mb-3" />
              <CardHTML text={match.pitch} />
              <hr className="mb-3 mt-3" />

              {match.profile && <CardTagsComponent profile={match.profile} />}

              <hr className="mb-2"></hr>

              <div className="d-flex justify-content-end">
                {match.postResponses &&
                  !match.postResponses.some(
                    (response) => response.type === "Reject"
                  ) && (
                    <button
                      className="btn btn-sm btn-outline-danger"
                      onClick={() => handleRejectMatch()}
                    >
                      {match.postResponses.some(
                        (response) => response.type === "Engage"
                      )
                        ? "Retract"
                        : "Reject"}{" "}
                      <i className="fi-user-minus"></i>
                    </button>
                  )}
                {match.postResponses &&
                  !match.postResponses.some(
                    (response) =>
                      response.type === "Consider" || response.type === "Engage"
                  ) && (
                    <button
                      className="btn btn-sm btn-outline-primary mx-1"
                      onClick={() => handleConsiderMatch()}
                    >
                      Consider <i className="fi-user"></i>
                    </button>
                  )}{" "}
                {match.postResponses &&
                  !match.postResponses.some(
                    (response) => response.type === "Engage"
                  ) && (
                    <Link
                      className="btn btn-sm btn-outline-success"
                      to={`/matches/${matchId}/engage`}
                    >
                      Engage <i className="fi-user-plus"></i>
                    </Link>
                  )}{" "}
              </div>

              <hr className="mb-2 mt-2" />

              {lastRecruiterMessage && lastRecruiterMessage && (
                <p className="small">
                  <IdleTime timestamp={lastRecruiterMessage.created} />
                </p>
              )}

              {match.postResponses && match.postResponses.length > 0 && (
                <>
                  <MatchResponseList
                    postResponses={match.postResponses}
                    recruiter={match.recruiter}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
