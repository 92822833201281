import React, { useState, useEffect, useContext } from "react";
import * as userService from "../dataServices/userService";
import { FlashLogoCenter } from "../components/common/FlashLogoCenter";

const ClaimsContext = React.createContext();

export function useClaims() {
  return useContext(ClaimsContext);
}

export function ClaimsProvider(props) {
  const [candidateId, setCandidateId] = useState(null);
  const [profileComplete, setProfileComplete] = useState(false);
  const [recruiterProfileComplete, setRecruiterProfileComplete] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [givenName, setGivenName] = useState("");
  const [familyName, setFamilyName] = useState("");

  useEffect(() => {
    async function fetchClaimsFromClient() {
      try {
        const response = await userService.getClaims();

        if (response.ok && response.status === 200) {
          const data = await response.json();

          const candidateIdResponse = data.claims.find(
            (claim) => claim.type === "CandidateId"
          );

          const givenNameResponse = data.claims.find(
            (claim) => claim.type === "given_name"
          );

          const familyNameResponse = data.claims.find(
            (claim) => claim.type === "family_name"
          );

          const candidateIdClaim = candidateIdResponse
            ? candidateIdResponse.value
            : null;

          const givenNameClaim = givenNameResponse
            ? givenNameResponse.value
            : null;

          const familyNameClaim = familyNameResponse
            ? familyNameResponse.value
            : null;

          setCandidateId(candidateIdClaim);
          setGivenName(givenNameClaim);
          setFamilyName(familyNameClaim);

          const profileCompleteResponse = data.claims.find(
            (claim) => claim.type === "ProfileComplete"
          );

          const profileCompleteClaim = profileCompleteResponse
            ? profileCompleteResponse.value
            : false;

          setProfileComplete(profileCompleteClaim);

          const recruiterProfileCompleteResponse = data.claims.find(
            (claim) => claim.type === "RecruiterProfileComplete"
          );

          const recrutierProfileCompleteClaim = recruiterProfileCompleteResponse
            ? recruiterProfileCompleteResponse.value
            : false;

          setRecruiterProfileComplete(recrutierProfileCompleteClaim);
        }
      } catch (e) {
        console.error(e);
        setCandidateId(null);
      } finally {
        setIsLoading(false);
      }
    }

    fetchClaimsFromClient();
  }, []);

  const value = {
    candidateId,
    setCandidateId,
    givenName,
    setGivenName,
    familyName,
    setFamilyName,
    profileComplete,
    setProfileComplete,
    recruiterProfileComplete,
    setRecruiterProfileComplete,
  };

  return isLoading ? (
    <FlashLogoCenter />
  ) : (
    <ClaimsContext.Provider value={value}>
      {props.children}
    </ClaimsContext.Provider>
  );
}
