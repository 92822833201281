import React, { useState } from "react";

export const OnboardTagsForm = ({
  title,
  tags,
  tagColor,
  selectedTags,
  tagType,
  icon,
  profileId,
  selectTag,
  removeTag,
  showFilter,
  ...props
}) => {
  const [filter, setFilter] = useState("");

  const displayColor = tagColor ? tagColor : "success";

  // Sort the tags alphabetically
  let sortedTags = [...tags].sort((a, b) =>
    a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
  );

  if (tagType === "Position") {
    sortedTags = sortedTags.sort((a, b) => a.weight - b.weight);
  }

  return (
    <>
      <div className="bg-light rounded-3 mb-3">
        <div className="d-flex flex-wrap mb-4">
          {selectedTags.map((tag) => (
            <button
              key={"selected_" + tag.id}
              className={`btn btn-xs btn-${displayColor} fs-sm opacity-100 rounded-pill me-2 mb-2 ms-1 mt-1`}
              onClick={() => removeTag(tag)}
            >
              {tag.name}
              <i className="fi-x fs-xs ms-2"></i>
            </button>
          ))}
        </div>
        <div className="border-top py-4 mb-md-2">
          <h2 className="h4 mb-4">
            <i className={`${icon} text-primary fs-5 mt-n1 me-2 pe-1`}></i>
            Select {tagType} Tags
          </h2>
          {showFilter && (
            <div className="form mb-4">
              <input
                className="form-control form-control-lg"
                type="text"
                name="filter"
                value={filter}
                placeholder="Type to filter"
                onChange={(event) => setFilter(event.target.value)}
              />
            </div>
          )}

          <div className="d-flex flex-wrap">
            {sortedTags
              .filter(
                (tag) =>
                  tag.name.toLowerCase().includes(filter.toLowerCase()) ||
                  filter === ""
              )
              .map((tag) => (
                <button
                  key={"unselected_" + tag.id}
                  className="btn btn-xs btn-outline-secondary fs-sm opacity-100 rounded-pill me-2 mb-2 ms-1 mt-1"
                  onClick={() => selectTag(tag)}
                >
                  {tag.name}
                  <i className="fi-plus fs-xs ms-2"></i>
                </button>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};
