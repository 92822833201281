import { BadgeList } from "./BadgeList";
import { DivisionBadgeList } from "./DivisionBadgeList";

export const CardTagsComponent = ({ profile }) => {
  return (
    <>
      <div className="d-flex flex-wrap">
        {profile.tags && (
          <BadgeList
            tags={profile.tags.filter((tag) => tag.type === "Position")}
            badgeClass="primary"
          />
        )}
      </div>

      <div className="d-flex flex-wrap">
        {profile.tags && (
          <BadgeList
            tags={profile.tags.filter((t) => t.type === "FirmType")}
            badgeClass="danger"
          />
        )}
      </div>

      <div className="d-flex flex-wrap">
        {profile.tags && (
          <DivisionBadgeList
            divisions={profile.tags.filter((tag) => tag.type === "Sector")}
          />
        )}
      </div>

      <div className="d-flex flex-wrap">
        {profile.tags && (
          <BadgeList
            tags={profile.tags.filter((t) => t.type === "Industry")}
            badgeClass="info"
          />
        )}
      </div>

      <div className="d-flex flex-wrap">
        {profile.tags && (
          <BadgeList
            tags={profile.tags.filter((t) => t.type === "Skill")}
            badgeClass="success"
          />
        )}
      </div>

      <div className="d-flex flex-wrap">
        {profile.tags &&
          profile.tags.some((t) => t.type === "Qualification") && (
            <BadgeList
              tags={profile.tags.filter((t) => t.type === "Qualification")}
              badgeClass="danger"
            />
          )}
      </div>

      <div className="d-flex flex-wrap">
        {profile.tags && (
          <BadgeList
            tags={profile.tags.filter((t) => t.type === "OfficePreference")}
            badgeClass="secondary"
          />
        )}
      </div>

      <div className="d-flex flex-wrap">
        {profile.tags && profile.tags.some((t) => t.type === "Country") && (
          <BadgeList
            tags={profile.tags.filter((t) => t.type === "Country")}
            badgeClass="info"
          />
        )}
      </div>

      <div className="d-flex flex-wrap">
        {profile.tags && profile.tags.some((t) => t.type === "City") && (
          <BadgeList
            tags={profile.tags.filter((t) => t.type === "City")}
            badgeClass="primary"
          />
        )}
      </div>

      <div className="d-flex flex-wrap">
        {profile.tags && profile.tags.some((t) => t.type === "Language") && (
          <BadgeList
            tags={profile.tags.filter((t) => t.type === "Language")}
            badgeClass="accent"
          />
        )}
      </div>

      <div className="d-flex flex-wrap">
        {profile.tags && profile.tags.some((t) => t.type === "Education") && (
          <BadgeList
            tags={profile.tags.filter((t) => t.type === "Education")}
            badgeClass="warning"
          />
        )}
      </div>

      <div className="d-flex flex-wrap">
        {profile.tags && (
          <BadgeList
            tags={profile.tags.filter((t) => t.type === "Type")}
            badgeClass="dark"
          />
        )}
      </div>
    </>
  );
};
