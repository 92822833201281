import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  fetchUser,
  updateUserAvatar,
} from "../../../../../features/user/userSlice";

import { toast } from "react-toastify";
import { PrevNextBtnLgRound } from "../PrevNextButtonLgRound";

import { InfoAlert } from "../../../../common/InfoAlert";
import { CenteredSingleColumnLayout } from "../../../../common/CenteredSingleColumnLayout";

export const UpdateAvatarPage = ({ update }) => {
  const user = useSelector((state) => state.user.user);

  const [errors, setErrors] = useState();
  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const [avatarToUpdate, setAvatarToUpdate] = useState(null);

  const [selectedFile, setSelectedFile] = useState();
  const [fileName, setFileName] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const [preview, setPreview] = useState();

  const [redirectToLink, setRedirectToLink] = useState("/settings");

  useEffect(() => {
    if (user && user.avatar) {
      setAvatarToUpdate(user.avatar);
    }
  }, [user]);

  useEffect(() => {
    if (window.location.pathname === "/onboard/avatar") {
      setRedirectToLink("/matches");
    }
  }, []);

  useEffect(() => {
    if (user === null) {
      dispatch(fetchUser());
    }
  }, [user, dispatch]);

  const handleBlobChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
    setIsFilePicked(true);
  };

  useEffect(() => {
    // Create the preview
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile); // Use selectedFile here
      setPreview(objectUrl);

      // Free memory when the component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  const handleSubmit = () => {
    setAddRequestStatus("pending");
    setSaving(true);
    var formData = new FormData();

    formData.append("blob", selectedFile);
    formData.append("fileName", fileName);

    const candidateId = user.id;

    if (selectedFile) {
      dispatch(updateUserAvatar({ candidateId, formData }))
        .then(() => {
          toast.success("Avatar Updated");
        })
        .catch((error) => {
          setAddRequestStatus("idle");
          setErrors({ onSave: error.message });
          toast.error("Saving business failed:- ", error.message);
          setSaving(false);
        })
        .finally(() => {
          setAddRequestStatus("idle");
          setSaving(false);

          history.push(redirectToLink);
        });
    } else {
      history.push(redirectToLink);
    }
  };

  return (
    <>
      <CenteredSingleColumnLayout>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-8 col-md-6">
              <InfoAlert text="Upload an image of you or logo of the organization you represent. If you have uploaded a business logo separately and use the same one, it will duplicate on posts." />
              <div className="card gallery">
                {preview && (
                  <img
                    src={preview}
                    alt="Gallery thumbnail"
                    className="square-preview"
                  />
                )}
                {!selectedFile && user && user.avatar && (
                  <img
                    src={user.avatar.location}
                    alt="Gallery thumbnail"
                    className="square-preview"
                  />
                )}
                <div className="card-body">
                  <input
                    type="file"
                    className="form-control mb-2"
                    name="file"
                    onChange={handleBlobChange}
                  />
                </div>
              </div>
              {update && (
                <PrevNextBtnLgRound
                  nextTitle={"Save"}
                  prevTitle={"Salary"}
                  prevHref={"salary"}
                  saving={saving}
                  saveTags={() => handleSubmit()}
                  update={update}
                />
              )}
            </div>
          </div>
        </form>
        {!update && (
          <PrevNextBtnLgRound
            nextTitle={"Save"}
            prevTitle={"Salary"}
            prevHref={"salary"}
            saving={saving}
            saveTags={() => handleSubmit()}
          />
        )}
      </CenteredSingleColumnLayout>
    </>
  );
};
