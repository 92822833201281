export async function getUserSession() {
  return fetch("bff/user", {
    headers: {
      "X-CSRF": "1",
    },
  });
}

export async function getClaims() {
  return fetch("api/claims", {
    headers: {
      "X-CSRF": "1",
    },
  });
}
