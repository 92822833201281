import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { PrevNextBtnLgRound } from "../PrevNextButtonLgRound";
import { OnboardTagsForm } from "../OnboardTagsForm";

import { toast } from "react-toastify";

import {
  fetchRecruiter,
  recruiterTagAdded,
  recruiterTagRemoved,
  saveRecruiterProfileTags,
} from "../../../../../features/recruiter/recruiterSlice";

export const OnboardRecruiterTags = ({
  all,
  tagType,
  tagTypeName,
  nextHref,
  icon,
  prevHref,
  nextTitle,
  prevTitle,
  showFilter,
  tagColor,
  update,
}) => {
  const recruiter = useSelector((state) => state.recruiter.recruiter);
  const dispatch = useDispatch();

  let history = useHistory();
  const [availableTags, setAvailableTags] = useState([]);
  const [profileId, setProfileId] = useState();
  const [touched, setTouched] = useState(false);
  const [errors, setErrors] = useState(false);

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (recruiter && recruiter.profile) {
      setAvailableTags([
        ...all.filter(
          (a) => !recruiter.profile.tags.some((t) => t.id === a.id)
        ),
      ]);
    }
  }, [recruiter, all]);

  const handleSelectTag = (tag) => {
    setTouched(true);
    dispatch(recruiterTagAdded({ tag }));
  };

  const handleRemoveTag = (tag) => {
    setTouched(true);
    dispatch(recruiterTagRemoved({ tag }));
  };

  const redirectAfterSave = () => {
    if (!update) {
      history.push(nextHref);
    } else {
      history.push("/recruiter/dashboard");
    }
  };

  const handleSaveTags = () => {
    if (!touched) {
      redirectAfterSave();
    } else {
      setSaving(true);
      redirectAfterSave();

      dispatch(saveRecruiterProfileTags({ recruiter, tagType }))
        .then(() => {
          toast.success(`${tagTypeName} tags saved`);
          setTouched(false);
        })
        .catch((error) => {
          setSaving(false);
          setErrors({ onSave: error.message });
          toast.error(`Saving tags failed: ${error.message}`);
        });
    }
  };

  return (
    <>
      {!recruiter.profile ? (
        <></>
      ) : (
        <OnboardTagsForm
          tags={availableTags}
          tagColor={tagColor}
          title={tagTypeName}
          tagType={tagTypeName}
          icon={icon}
          selectedTags={recruiter.profile.tags.filter(
            (t) => t.type === tagType
          )}
          profileId={recruiter.profile.id}
          errors={errors}
          selectTag={handleSelectTag}
          removeTag={handleRemoveTag}
          showFilter={showFilter}
        />
      )}

      <PrevNextBtnLgRound
        nextHref={update ? "" : nextHref}
        prevHref={update ? "" : prevHref}
        nextTitle={update ? "Update" : nextTitle}
        prevTitle={update ? "" : prevTitle}
        saving={saving}
        saveTags={handleSaveTags}
        update={update}
      />
    </>
  );
};
