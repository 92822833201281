import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { sendRecruiterPostMessage } from "../../features/posts/postsSlice";
import { toast } from "react-toastify";

import { PostCardPostResponse } from "./PostCardPostResponse";

import { EditorState, convertToRaw } from "draft-js";
import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";

export const PostCardPostResponses = ({ post, handleSelectResponse }) => {
  const filteredResponses =
    post.postResponses &&
    post.postResponses.filter((post) => post.type === "Engage");

  const dispatch = useDispatch();

  const [editorStates, setEditorStates] = useState({});
  const [dtos, setDtos] = useState({});

  useEffect(() => {
    initializeEditorStates();
  }, [post.postResponses]);

  const initializeEditorStates = () => {
    const editorStatesMap = {};
    const dtosMap = {};

    post.postResponses &&
      post.postResponses
        .filter((post) => post.type === "Engage")
        .forEach((response) => {
          const postId = response.postId;
          const postResponseId = response.id;

          editorStatesMap[postResponseId] = EditorState.createEmpty();
          dtosMap[postResponseId] = { comment: "" };
        });

    setEditorStates(editorStatesMap);
    setDtos(dtosMap);
  };

  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [errors, setErrors] = useState({});

  const handleCommentChange = (newEditorState, postResponseId) => {
    // Update the editor state for the specified post response
    setEditorStates((prevState) => ({
      ...prevState,
      [postResponseId]: newEditorState,
    }));
    convertContentToHTML(postResponseId);
  };

  const convertContentToHTML = (postResponseId) => {
    const contentState = editorStates[postResponseId].getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlToSanitize = draftToHtml(rawContentState);
    const sanitizedHTML = sanitize(htmlToSanitize);
    setDtos((prevDtos) => ({
      ...prevDtos,
      [postResponseId]: { comment: sanitizedHTML },
    }));
  };

  // Sanitize any bad input from the editor
  const sanitize = (html) => {
    return DOMPurify.sanitize(html);
  };

  const canSave = (postResponseId) =>
    dtos[postResponseId].comment && addRequestStatus === "idle";

  const handleSendMessage = async (response, postResponseId) => {
    if (!canSave(postResponseId)) {
      return;
    }

    try {
      setAddRequestStatus("pending");

      const postId = response.postId;

      // Use the dto for the specified post response
      const dto = dtos[postResponseId];

      await dispatch(sendRecruiterPostMessage({ postId, postResponseId, dto }))
        .unwrap()
        .then((response) => {
          toast.success("Message sent");
          setDtos((prevDtos) => ({
            ...prevDtos,
            [postResponseId]: { comment: "" },
          }));
          setEditorStates((prevEditorStates) => ({
            ...prevEditorStates,
            [postResponseId]: EditorState.createEmpty(),
          }));
        });
    } catch (error) {
      toast.error("Failed to send message");
      setErrors({ onSave: error.message });
    } finally {
      setAddRequestStatus("idle");
    }
  };

  return (
    <>
      <div id="accordionCards">
        {post.postResponses &&
          post.postResponses
            .filter((post) => post.type === "Engage")
            .map((response) => (
              <div onClick={() => handleSelectResponse({ response })}>
                <PostCardPostResponse
                  response={response}
                  handleSelectResponse={handleSelectResponse}
                />
              </div>
            ))}
      </div>
    </>
  );
};
