import React from "react";
import { GuidingPrinciplesEmployers } from "./GuidingPrinciplesEmployers";
import { ProfilingSystemEmployers } from "./ProfilingSystemEmployers";
import { ProcessEmployers } from "./ProcessEmployers";
import { HeaderText } from "../../../common/HeaderText";

export const FillYourPipeline = () => {
  return (
    <>
      <HeaderText
        headerText="Source top talent"
        headerSpan="respecting their privacy"
        leadText="We attract the most passive of candidates by providing a private
            portal to explore highly relevant networking and career
            opportunities and the ability to easily engage with leading
            employers without compromising their data privacy concerns."
      />

      <div class="container pt-5">
        <ProcessEmployers />
      </div>
      {/* <div class="container pt-5">
        <GuidingPrinciplesEmployers />
      </div> */}

      <ProfilingSystemEmployers />
    </>
  );
};
