import React from "react";
import { facetIcons } from "./FacetIcons";

export const FacetHeader = ({ facetType }) => {
  //   alert(JSON.stringify(facetIcons));
  const facet = facetIcons.find((i) => i.facet === facetType);

  if (facetType === "Sectors") {
    facetType = "Divisions";
  }

  return (
    <>
      {facet && (
        <div className="d-flex flex-sm-row flex-column align-items-sm-center align-items-stretch my-2">
          <div className="d-flex align-items-center flex-shrink-0">
            <label className="fs-sm me-2 pe-1 text-nowrap" htmlFor="sortby">
              <strong>{facetType}</strong>
            </label>
          </div>
          <hr className="d-none d-sm-block w-100 mx-2" />
          <div className="d-none d-sm-flex align-items-center flex-shrink-0 text-muted">
            <i className={`fi-${facet.icon} me-2`}></i>
          </div>
        </div>
      )}
    </>
  );
};
