import React from "react";

export const RetentionTab = () => (
  <>
    <div className="mb-md-4 mb-3 fs-sm">
      <h3 className="mt-4">Retention Policy</h3>
      <p className="mt-4">
        The purpose of the Retention Policy is to explain what personal
        information we may collect about you when you visit our site or interact
        with our online services, the purpose of that collection, and how long
        we store that personal information for.
      </p>

      <p className="mt-4">
        Our Retention Policy is broadly divided into two sections:
      </p>

      <ul className="mt-4">
        <li>Our website</li>
        <li>Cookies</li>
      </ul>

      <p className="mt-4">
        Depending on how you interact with our site and our services we may
        collect different types of personal information about you. The duration
        that we retain that personal information will vary depending on how you
        interact with us. For example, we may retain information about you for a
        longer period of time if you have asked us to keep you updated about
        developments in the company.
      </p>

      <p className="mt-4">
        The types of personal information that we collect, its purpose, and
        retention period is detailed in the tables below.
      </p>

      <h4 className="mt-4">Our Website</h4>

      <p className="mt-4">
        This table details the personal information and we may collect about you
        on our site located at https://sourcers.co.
      </p>

      <table className="table">
        <thead>
          <tr>
            <th>Type of Personal Information</th>
            <th>Purpose</th>
            <th>Retention Period</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>First Name / Last Name</td>
            <td>To identify you if you contact us</td>
            <td>12 months</td>
          </tr>
          <tr>
            <td>Email Address</td>
            <td>
              To Communicate with you if you make an enquiry or apply to a job
            </td>
            <td>12 months</td>
          </tr>
          <tr>
            <td>Phone Number</td>
            <td>
              To Communicate with you if you make an enquiry or apply to a job
            </td>
            <td>12 months</td>
          </tr>
          <tr>
            <td>CV / Resume</td>
            <td>To consider your job applications</td>
            <td>12 months</td>
          </tr>
        </tbody>
      </table>

      <h4 className="mt-4">Cookies</h4>

      <p className="mt-4">
        You can find more information about the types of cookies that we use and
        their purpose by visiting our Cookie Policy{" "}
        <a href="~/home/privacy">here</a>.
      </p>

      <table className="table">
        <thead>
          <tr>
            <th>Cookie Name</th>
            <th>Purpose</th>
            <th>Expiry</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>AspNet.Consent</td>
            <td>Cookie Consent</td>
            <td>12 months</td>
          </tr>
          <tr>
            <td>AspNetCore.AntiForgery</td>
            <td>
              To ensure you are posting to our site from our site and prevent
              Cross Site Scripting Attacks
            </td>
            <td>Until You Log Out (Session Duration)</td>
          </tr>
          <tr>
            <td>AspNetCore.Identity</td>
            <td>To identify your user account</td>
            <td>Until You Log Out (Session Duration)</td>
          </tr>
        </tbody>
      </table>

      <p className="mt-4">
        You can block cookies by activating the setting on your browser that
        allows you to refuse the setting of all or some cookies. However, if you
        use your browser settings to block all cookies (including essential
        cookies) you may not be able to access all or parts of our site. You may
        also delete any cookies stored on your computer at any time.
      </p>

      <p className="mt-4">
        You can find out more about changing cookie settings on your computer by
        visiting http://www.allaboutcookies.org/manage-cookies/.
      </p>

      <p className="mt-4">
        Different cookies may be stored for different periods of time. In many
        cases these cookies are updated automatically each time you visit our
        site or may expire and be deleted by your device automatically. Each
        time you visit our site you start a new “session”. When you leave our
        site the session ends. The expiry period of a cookie will usually run
        from the date of the most recent session. This means that, each time you
        visit our site, the expiry period for those cookies may reset.
      </p>

      <p className="mt-4">
        It is important to understand that when a cookie is placed on your
        device it will reside on your hard drive until it expires and is deleted
        or it may reside on your hard drive until you manually delete it – this
        entirely depends on your individual browser settings and we do not have
        control over this.
      </p>

      <h4 className="mt-4">Marketing And Other Communication</h4>

      <p4 className="mt-4">
        We do not use any of the personal information collected from our site or
        that you may pass to us by filling in forms on our site for any
        marketing purposes. If you have requested that we get in touch with you
        then we will only use your personal information to assist us in
        answering your query.
      </p4>

      <h4 className="mt-4">Changes To Our Retention Policy</h4>

      <p className="mt-4">
        We may need to change this Retention Policy if it’s necessary for legal
        reasons or to reflect changes to our site and services or the purposes
        for which we may wish to collect and process your personal information.
        In any case, the provisions of this Retention Policy may be changed
        without prejudice to your rights. When we change our Retention Policy we
        will make the updated Retention Policy available on our site and we will
        also update the “Last Updated” date.
      </p>

      <p className="mt-4">
        Once we change our Retention Policy, we will use our reasonable efforts
        to inform you . This may include asking you to agree to the updated
        Retention Policy before we process further information about you or it
        may require us to suspend access to parts of our site until you agree to
        the updated Retention Policy – we will only restrict access to parts of
        our site where we deem it is necessary for us to collect or process
        personal information about you. If you have an account on our site then
        we may reset your login and seek new consent if there is a change in the
        purpose or duration that we retain your personal information.
      </p>

      <p className="mt-4">
        You’re welcome to contact us if you have questions about the changes.
      </p>
    </div>
  </>
);
