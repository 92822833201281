import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as remoteClient from "../../dataServices/remoteClient";

const initialState = {
  user: {},
  isLoading: false,
  error: null,
  profileTouched: false,
};

export const fetchUser = createAsyncThunk("users/fetchUser", async () => {
  return await remoteClient.get("/api/users");
});

export const addNewUser = createAsyncThunk(
  "users/addNewUser",
  async (initialUser) => {
    return await remoteClient.post("/api/users", initialUser.user);
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (initialUser) => {
    return await remoteClient.put("/api/users", initialUser.user);
  }
);

export const updateTag = createAsyncThunk(
  "users/updateTag",
  async (initialUser) => {
    return;
  }
);

export const saveProfileTags = createAsyncThunk(
  "users/saveProfileTags",
  async ({ user, tagType }) => {
    return await remoteClient.post(
      `/api/candidates/${user.id}/profiles/${user.profile.id}/tagsCollection/${tagType}`,
      user.profile.tags.filter((t) => t.type === tagType)
    );
  }
);

export const saveProfileMultiTags = createAsyncThunk(
  "users/saveProfileMultiTags",
  async ({ user, tagType, selectedOptions }) => {
    return await remoteClient.post(
      `/api/candidates/${user.id}/profiles/${user.profile.id}/skillsCollection/${tagType}`,
      selectedOptions
    );
  }
);

export const saveProfileCountries = createAsyncThunk(
  "users/saveProfileCountries",
  async ({ user, selectedOptions }) => {
    return await remoteClient.post(
      `/api/candidates/${user.id}/profiles/${user.profile.id}/countriescollection`,
      selectedOptions.anyTags
    );
  }
);

export const saveProfileCities = createAsyncThunk(
  "users/saveProfileCities",
  async ({ user, selectedOptions }) => {
    return await remoteClient.post(
      `/api/candidates/${user.id}/profiles/${user.profile.id}/citiescollection`,
      selectedOptions.anyTags
    );
  }
);

export const updateProfileSalary = createAsyncThunk(
  "users/updateProfileSalary",
  async ({ user, profileSalaryDto }) => {
    return await remoteClient.post(
      `/api/candidates/${user.id}/profiles/${user.profile.id}/`,
      profileSalaryDto
    );
  }
);

export const updateUserAvatar = createAsyncThunk(
  "users/updateUserAvatar",
  async ({ candidateId, formData }) => {
    return await remoteClient.postFormData(
      `/api/users/${candidateId}/avatar`,
      formData
    );
  }
);

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    userAdded(state, action) {
      state.user = action.payload;
    },
    userUpdated(state, action) {
      state.user = action.payload;
    },
    tagAdded(state, action) {
      const { tag } = action.payload;
      const user = state.user;
      if (user) {
        user.profile.tags.push(tag);
      }
    },
    tagRemoved(state, action) {
      const { tag } = action.payload;
      const user = state.user;
      if (user) {
        user.profile.tags = user.profile.tags.filter((t) => t.id !== tag.id);
      }
    },
    resetProfileTouched(state) {
      state.profileTouched = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(addNewUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(addNewUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        //state.profileTouched = true;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(saveProfileTags.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user.profile.tags = action.payload;
        state.profileTouched = true;
      })
      .addCase(saveProfileTags.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(saveProfileMultiTags.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user.profile.tags = action.payload.tags;
        state.user.profile.tagsForSelect = action.payload.tagsForSelect;
        state.profileTouched = true;
      })
      .addCase(saveProfileMultiTags.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(saveProfileCountries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user.profile.countries = action.payload;
        state.profileTouched = true;
      })
      .addCase(saveProfileCountries.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(saveProfileCities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user.profile.cities = action.payload;
        state.profileTouched = true;
      })
      .addCase(saveProfileCities.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateProfileSalary.fulfilled, (state, action) => {
        state.user = action.payload;
        state.profileTouched = true;
      })
      .addCase(updateProfileSalary.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateUserAvatar.fulfilled, (state, action) => {
        state.user.avatar = action.payload;
      })
      .addCase(updateUserAvatar.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.payload;
      });
  },
});

export const {
  userAdded,
  userUpdated,
  tagAdded,
  tagRemoved,
  resetProfileTouched,
} = userSlice.actions;

export default userSlice.reducer;
