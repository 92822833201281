import React from "react";
import { useSelector } from "react-redux";
import { selectSavedFeedItemById } from "../../../features/saved/savedFeedItemsSlice";

import { JobCard } from "../../common/JobCard";

export const SavedItemCard = ({ itemId }) => {
  const item = useSelector((state) =>
    selectSavedFeedItemById(state, itemId)
  ).document;

  return (
    <>
      <JobCard key={item.id} job={item} saved />
    </>
  );
};
