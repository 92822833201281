import React from "react";
import { IconCard } from "../../../common/IconCard";

export const ProcessEmployers = () => {
  return (
    <>
      <div className="card border-0 bg-faded-info p-md-3 p-1">
        <div className="card-body">
          <div className="row row-cols-lg-3 row-cols-md-3 row-cols-sm-1 row-cols-1 g-4 justify-content-center">
            <IconCard
              iconClass="bg-faded-warning"
              textClass="text-warning"
              icon="fi-geo"
              title="Attract"
              description="Approaching professionals with a wide range of opportunities maximises engagement.  
              Candidates are assured their privacy will be respected and will only receive highly relevant content."
              subTitle={
                "We proactively approach professionals and enable engagement with an active talent community."
              }
            />

            <IconCard
              iconClass="bg-faded-info"
              textClass="text-info"
              icon="fi-checkbox-checked-alt"
              title="Approve"
              description="We screen professionals who join our community to further improve the quality of 
              inbound applications.  We assist professionals to create profiles consistent with their 
              experience and ambition."
              subTitle="Enhanced inbound application quality."
            />

            <IconCard
              iconClass="bg-faded-success"
              textClass="text-success"
              icon="fi-share"
              title="Automate"
              description="Create profiles for your organisation, recruiters, hiring managers and vacancies.  
              Our profiling system then automates skills based outreach respecting professionals 
              permissions criteria."
              subTitle="Permission based outreach on autopilot"
            />
          </div>
        </div>
      </div>
    </>
  );
};
