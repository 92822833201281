import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavMenu } from "./nav/NavMenu";
import { NavFooter } from "./nav/NavFooter";
import { Footer } from "./common/Footer";
import { useAuth } from "../contexts/AuthContext";
//import store from "../app/store";

import { fetchUser } from "../features/user/userSlice";
import { fetchRecruiter } from "../features/recruiter/recruiterSlice";
import CookieConsent from "react-cookie-consent";
import CookieBanner from "./pages/home/CookieConsentBanner";

const Layout = (props) => {
  const { isLoggedIn, isEmployer } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmployer) {
      dispatch(fetchRecruiter());
    } else {
      dispatch(fetchUser());
    }
  }, [isEmployer, dispatch]);

  return (
    <>
      <div id="app" className="d-flex flex-column h-100">
        <NavMenu />
        <div className="body-content mb-10">{props.children}</div>
        <div className="d-sm-none">
          <NavFooter />
        </div>
        <CookieBanner />
        {!isLoggedIn && <Footer />}
      </div>
    </>
  );
};

Layout.displayName = "Layout";

export default Layout;
