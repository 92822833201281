import { configureStore } from "@reduxjs/toolkit";

import postsReducer from "../features/posts/postsSlice";
import usersReducer from "../features/users/usersSlice";
import notificationsReducer from "../features/notifications/notificationsSlice";
import jobsReducer from "../features/jobs/jobsSlice";
import tagsReducer from "../features/tags/tagsSlice";
import userReducer from "../features/user/userSlice";
import recruiterReducer from "../features/recruiter/recruiterSlice";
import feedReducer from "../features/feeds/candidateFeedSlice";
import jobSearchReducer from "../features/search/jobSearchSlice";
import savedFeedItemsReducer from "../features/saved/savedFeedItemsSlice";
import matchesReducer from "../features/matches/matchesSlice";

export default configureStore({
  reducer: {
    posts: postsReducer,
    users: usersReducer,
    notifications: notificationsReducer,
    jobs: jobsReducer,
    tags: tagsReducer,
    user: userReducer,
    recruiter: recruiterReducer,
    feed: feedReducer,
    jobSearchResults: jobSearchReducer,
    savedFeedItems: savedFeedItemsReducer,
    matches: matchesReducer,
  },
});
