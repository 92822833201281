import React, { useState, useEffect } from "react";

import { useAuth } from "../contexts/AuthContext";

import { NotAuthorized } from "../components/NotAuthorized";

export const CheckOwnerComponent = ({ requiredOwnerId, ...props }) => {
  const [isOwner, setIsOwner] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(true);

  const { user } = useAuth();

  useEffect(() => {
    if (user.id === requiredOwnerId) {
      setIsOwner(true);
      setLoadingAuth(false);
    }
  }, [user, requiredOwnerId]);

  return (
    <>
      {requiredOwnerId ? (
        <>
          {!loadingAuth && !isOwner ? <NotAuthorized /> : <>{props.children}</>}
        </>
      ) : (
        <>{props.children}</>
      )}{" "}
    </>
  );
};
