import React from "react";
import { useDispatch } from "react-redux";
import { options } from "./SinceOptions";

import { setSearchDtoSince } from "../../features/feeds/candidateFeedSlice";

export const FetchSinceOptions = ({ handleSetSince, selectedValue }) => {
  return (
    <select
      className="form-select form-select-sm"
      id="sortby"
      value={selectedValue}
      onChange={(e) => handleSetSince(parseInt(e.target.value))}
    >
      {options &&
        options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
    </select>
  );
};
