import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  fetchSavedFeedItems,
  selectSavedFeedItemIds,
} from "../../../features/saved/savedFeedItemsSlice";

import { Spinner } from "../../common/Spinner";
import { SavedItemCard } from "./SavedItemCard";
import { CenteredSingleColumnLayout } from "../../common/CenteredSingleColumnLayout";

export const SavedPage = () => {
  const dispatch = useDispatch();

  const orderedSavedItemIds = useSelector(selectSavedFeedItemIds);

  const savedItemsStatus = useSelector((state) => state.savedFeedItems.status);
  const error = useSelector((state) => state.savedFeedItems.error);

  const initialFetch = useSelector(
    (state) => state.savedFeedItems.initialFetch
  );
  const touched = useSelector((state) => state.savedFeedItems.savedTouched);

  //   const totalCount = useSelector((state) => state.feed.totalCount);

  useEffect(() => {
    if (!initialFetch || touched) {
      dispatch(fetchSavedFeedItems());
    }
  }, [initialFetch, touched, dispatch]);

  return (
    <>
      <CenteredSingleColumnLayout>
        {savedItemsStatus === "loading" ? (
          <Spinner text="Loading your saved items..." />
        ) : savedItemsStatus === "succeeded" ? (
          <>
            {orderedSavedItemIds.map((itemId) => {
              return <SavedItemCard key={itemId} itemId={itemId} />;
            })}
          </>
        ) : (
          <div>{error}</div>
        )}
      </CenteredSingleColumnLayout>{" "}
    </>
  );
};
