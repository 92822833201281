import { Link } from "react-router-dom";

export const PostCardRecruiterButtonLinks = ({ post }) => {
  return (
    <>
      <div className="dropdown content-overlay">
        <button
          type="button"
          className="btn btn-icon btn-light btn-xs rounded-circle shadow-sm"
          id="contextMenu"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="fi-dots-vertical"></i>
        </button>
        <ul className="dropdown-menu my-1" aria-labelledby="contextMenu">
          <li>
            <Link
              to={`/recruiter/posts/${post.id}/edit`}
              className="dropdown-item"
            >
              <i className="fi-pencil opacity-60 me-2"></i>
              Edit
            </Link>
          </li>
          {/* <li>
            <button type="button" className="dropdown-item">
              <i className="fi-x-circle opacity-60 me-2"></i>
              Not interested
            </button>
          </li> */}
        </ul>
      </div>{" "}
    </>
  );
};
