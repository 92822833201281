import { OnboardMultiTagsPage } from "../OnboardMultiTagsPage";

export const UpdateCountriesPageWrapper = (props) => {
  return (
    <OnboardMultiTagsPage
      tagType={"Country"}
      lead={"What countries are you authorized to work from?"}
      text={
        <>Add countries that you can base yourself for remote opporutnities.</>
      }
      {...props}
      update={true}
    />
  );
};
