import { OnboardStaticTagsPage } from "../OnboardStaticTagsPage";

export const UpdateLanguagesPageWrapper = (props) => {
  return (
    <OnboardStaticTagsPage
      tagType={"Language"}
      lead={"What languages do you speak?"}
      tagColor="outline-primary"
      text={
        <>
          Add languages that you are proficient to the standard required for
          efficient business communication.
        </>
      }
      {...props}
      update={true}
    />
  );
};
