export const PostRecruiterMessageCard = ({ post }) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center m-2 border-bottom">
        <div className="d-flex align-items-center pe-2 m-2">
          <strong>{post.title}</strong>
        </div>
      </div>
    </>
  );
};
