import { OnboardRecruiterMultiTagsPage } from "../recruiter/OnboardRecruiterMultiTagsPage";

export const RecruiterQualficationTagsPageWrapper = (props) => {
  return (
    <OnboardRecruiterMultiTagsPage
      tagType={"Qualification"}
      text={"Do you require candidates to have a particular qualification?"}
      {...props}
    />
  );
};
